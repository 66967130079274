import React, { Component } from "react";

import "component/animation/Loading/styles.css";

class Slot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height
    )
      this.setState({
        width: nextProps.width,
        height: nextProps.height,
      });
  }

  render() {
    return (
      <div className={this.props.className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          width={`${this.state.width}px`}
          height={`${this.state.height}px`}
          viewBox="0 0 850 850"
          style={{
            enableBackground: "new 0 0 850 850",
          }}
        >
          <defs>
            <mask id="slotsmask" x="0" y="0">
              <path
                d="M818.7,102Q801.39,83.38,777,83.38q-24.54,0-41.92,18.58-17.22,18.84-17.22,45.48,0,26.44,17.22,45.21L694.67,342.47a123.74,123.74,0,0,0-5,39v79.71H659.23V594.87H779V461.16H752.15V393.72a163.07,163.07,0,0,1,7.24-55.92L793.58,209q14-4.2,25.12-16.38Q836,173.89,836,147.44q0-26.62-17.3-45.48m-363-22.79a749.74,749.74,0,0,0-82.61-4.67,663,663,0,0,0-79.27,4.67Q118.36,97.74,116,268.52V688.86q-1.57,89,79.28,86.58H554.17q80.85,2.37,79.27-86.58V268.52Q631.21,97.75,455.68,79.17m74,161v222.2h-310V240.15h310m-250,334.13H469.82L524.89,692H224.67l55.07-117.69M413.25,260.92H336.31V441.4h76.94V260.92m-99.49,0H237.65V441.4h76.11V260.92M512,441.4V260.92H435.88V441.4Z"
                fill="white"
              />
            </mask>
          </defs>
          <rect
            x="0"
            y="0"
            width="850"
            height="850"
            mask="url('#slotsmask')"
            fill="url('#categoryNormal')"
          />
        </svg>
        {this.props.label && (
          <div
            style={{
              color: this.state.color,
            }}
            className="label"
          >
            {this.props.label}
          </div>
        )}
      </div>
    );
  }
}

export default Slot;
