import React, { Component } from 'react';
import 'component/listItem/CategoryType1/styles.scss';
import iconDefault from 'assets/images/iconDefault.png';

const ICON_DEFAULT = "ICON_DEFAULT";

  class CategoryType1 extends Component {
    constructor(props) {
        super(props)
        this.onMouseOverHandler = this._onMouseOverHandler.bind( this );
        this.state = {
            hovered: false,
        }
    }

    _onMouseOverHandler( bol ) {
        this.setState( {
          hovered: bol
        } )
      }

    render() {
        let {
            game,
        } = this.props

        return <div
            className={`${this.props.className} category-type-1`} onMouseOver={() => {
                    this.onMouseOverHandler(true)
                }}
                onMouseOut={() => {
                    this.onMouseOverHandler(false)
                }}
                onClick={(event) => {
                  this.props.onClick(this.props.linkDetails.get('rel'));
                  event.stopPropagation();
                }}
                >
            <div>
                <img src={`${(game.getIn(['images', 'url']).replace("width=90", "width=200"))}&theme=dark`} className="icon d-none" alt={game.get('name')}
                  onLoad={({currentTarget}) => {
                      currentTarget.classList.remove("d-none");
                      this.refs[ICON_DEFAULT].classList.add("d-none");
                  }}
                ></img>
                <img ref={ICON_DEFAULT} src={iconDefault} className="icon" alt={game.get('name')}></img>
                <div className="details">
                    <div className="name">{game.get('name')}</div>
                    <div className="provider">{game.get('provider')}</div>
                </div>
            </div>

        </div>
    }
  }

  export default CategoryType1;