import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    isEmpty
} from 'lodash';
import {
    CategoryTypes
} from '../actions/types';

export const defaultValue = fromJS({})

export const filters = createReducer(defaultValue,{
    [CategoryTypes.FETCH_FILTERS](state, { payload }) {
        let newState = state;
        return newState;
    },
    [CategoryTypes.SET_FILTERS](state, { payload }) {

        let imPayload = fromJS(payload);
        let mergePayload = fromJS(payload);
        
        imPayload.getIn(['data', 'filters']).forEach((filter, filterKey) => {
            let enabledCount = 0;
            let toggledCount = 0;

            filter.get('values').forEach((value, key)  => {
                let newValue = state.getIn([payload.route.get('rel'), 'data', 'enabledFilters', filterKey, value.get('id')]) ? 
                                state.getIn([payload.route.get('rel'), 'data', 'enabledFilters', filterKey, value.get('id')]) : value
                
                mergePayload = mergePayload.setIn(['data', 'filters', filterKey, 'values', key], newValue);
                
                if(newValue.get('enabled')) {
                    enabledCount++;
                }
                if(newValue.get('toggled')) {
                    toggledCount++;
                }
            })
            mergePayload = mergePayload.setIn(['data', 'filters', filterKey, 'visible'], (enabledCount > 1)) 
            mergePayload = mergePayload.setIn(['data', 'filters', filterKey, 'enabledCount'], enabledCount) 
            mergePayload = mergePayload.setIn(['data', 'filters', filterKey, 'toggledCount'], toggledCount) 
        })  
        let newState
        if (isEmpty(state.getIn([payload.route.get('rel'), 'data', 'filters']))) {
            newState = state.mergeDeepIn([payload.route.get('rel')], fromJS(payload));
            let defaultSort;
            try {
                defaultSort = imPayload.getIn(['data', 'sorts']).find(sort=>{ return sort.get('isDefault') === true}).get('id');
            } catch(err) {}
            newState = newState.setIn([payload.route.get('rel'), 'data', 'sortBy'], defaultSort ? defaultSort : imPayload.getIn(['data', 'sorts', 0, 'id']));
        } else {
            newState = state.setIn([payload.route.get('rel'), 'data', 'filters'], mergePayload.getIn(['data', 'filters']));
        }
        newState = newState.setIn([payload.route.get('rel'), "isLoading"], false);
        return newState
    },
    [CategoryTypes.TOGGLE_FILTER](state, { payload }) {
        let filters = state.getIn([payload.category, 'data', 'filters']);
        let subCategoryIndex = filters.findIndex(filter => {return filter.get('id') === payload.subCategory});
        let isMultiSelectable = filters.getIn([subCategoryIndex, 'isMultiSelectable']);
        let filterIndex = filters.getIn([subCategoryIndex, 'values']).findIndex(value => { return value.get('id') === payload.filter.get('id')});
        let filter = state.getIn([payload.category, 'data', 'filters', subCategoryIndex, 'values', filterIndex]);
        let toggled = filter.get('toggled') ? false : true
        let newState = state;

        if(!isMultiSelectable) {
            newState = newState.removeIn([payload.category, 'data', 'enabledFilters', subCategoryIndex])
            filters.getIn([subCategoryIndex, 'values']).forEach((value, key) => {
                newState = newState.setIn([payload.category, 'data', 'filters', subCategoryIndex, 'values', key, 'toggled'], (filterIndex === key) ? toggled : false)
            })
        } else {
            newState = state.setIn([payload.category, 'data', 'filters', subCategoryIndex, 'values', filterIndex, 'toggled'], toggled)
        }
        
        
        let enableClearAll = false;
        if (toggled) {
            let enabledFilter = payload.filter.set('toggled', true);
            newState = newState.setIn([payload.category, 'data', 'enabledFilters', subCategoryIndex, payload.filter.get('id')], enabledFilter)
        } else {
            newState = newState.removeIn([payload.category, 'data', 'enabledFilters', subCategoryIndex, payload.filter.get('id')]);
            
            if(newState.getIn(['categories-filters.editorschoice','data', 'enabledFilters', 0]) && newState.getIn(['categories-filters.editorschoice','data', 'enabledFilters', 0]).size === 0) {
                newState = newState.removeIn([payload.category, 'data', 'enabledFilters', subCategoryIndex])
            }
        }

        newState.getIn([payload.category, 'data', 'filters']).forEach((filter, i) => {
            filter.get('values').forEach((value, j) => {
                let toggled = value.get('toggled') ? true : false;
                if (toggled) {
                    enableClearAll = true
                    return;
                }
            })
        })

        newState = newState.setIn([payload.category, 'enableClearAll'], enableClearAll);
        newState = newState.setIn([payload.route.get('rel'), "isLoading"], true);
        return newState
    },
    [CategoryTypes.SET_CATEGORY_SORTING](state, {payload}) {
        let newState = state;
        newState = newState.setIn([payload.category, 'data', 'sortBy'], payload.sorting);
        return newState
    },
    [CategoryTypes.CLEAR_FILTER_BY_CATEGORY](state, { payload }) {
        let filters = state.getIn([payload.category, 'data', 'filters']);
        let subCategoryIndex = filters.findIndex(filter => {return filter.get('id') === payload.subCategory});
        let newState = state;
        state.getIn([payload.category, 'data', 'filters', subCategoryIndex, 'values']).forEach((value, index) => {
            newState = newState.setIn([payload.category, 'data', 'filters', subCategoryIndex, 'values', index, 'toggled'], false)
        })

        let enableClearAll = false
        newState.getIn([payload.category, 'data', 'filters']).forEach((filter, i) => {
            filter.get('values').forEach((value, j) => {
                let toggled = value.get('toggled') ? true : false;
                if (toggled) {
                    enableClearAll = true
                    return;
                }
            })
        })
        newState = newState.removeIn([payload.category, 'data', 'enabledFilters', subCategoryIndex]);
        newState = newState.setIn([payload.category, 'enableClearAll'], enableClearAll);
        newState = newState.setIn([payload.category, "isLoading"], true);
        return newState;
    },
    [CategoryTypes.CLEAR_ALL_FILTERS](state, { payload }) {
        let newState = state;
        let filters = newState.getIn([payload.category, 'data', 'filters'])
        filters.forEach((filter, i) => {
            filter.get('values').forEach((value, j) => {
                newState = newState.setIn([payload.category, 'data', 'filters', i, 'values', j, 'toggled'], false)
            })
        })

        newState = newState.removeIn([payload.category, 'data', 'enabledFilters']);
        newState = newState.setIn([payload.category, 'enableClearAll'], false);
        newState = newState.setIn([payload.category, "isLoading"], true);
        return newState;
    }
})