import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import ReactDOM from 'react-dom';
import './style.scss';
import Application from './containers/Application';
import * as serviceWorker from './serviceWorker';
import Root from 'Root';
import {
  PageNotFound
} from 'component/page';

ReactDOM.render(
  <Root>
        <Switch>
            <Route path='/:locale/:sessionId' component={Application}/>
            <Route path='*' component={PageNotFound}/>
        </Switch>
    </Root>, document.getElementById( 'root' ) );

serviceWorker.unregister();
/* serviceWorker.register({
  onUpdate: (registration, initialUpdate) => {
    // dispatch and event whne there is a  pending update
    const updateEvent = new CustomEvent('newContentAvailable', {
      detail: {
        registration: registration,
        initialUpdate: initialUpdate
      }
    });
    window.dispatchEvent(updateEvent);
  },
  onActivated: () => {
    const updateEvent = new CustomEvent('serviceWorkerActivated');
    window.dispatchEvent(updateEvent);
  }
}) */