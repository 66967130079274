import {
  BetHistoryTypes
} from 'actions/types';
import axios from 'axios';
import {
  fromJS
} from 'immutable';
import {
	serverError
} from 'actions/error'

export function showBetHistory() {
  return ( dispatch, getState) => {
    dispatch({
      type: BetHistoryTypes.SHOW_BET_HISTORY,
      payload: true
    })
    return true;
  }
}

export function hideBetHistory() {
  return ( dispatch, getState) => {
    dispatch({
      type: BetHistoryTypes.HIDE_BET_HISTORY,
      payload: false
    })
    return false;
  }
}

export function fetchBetHistoryUrl() {
  return ( dispatch, getState ) => {
    let route = getState().profile.get('links').find( link => {
      return link.get( 'rel' ) === 'service.url';
    });
    try {
      var host = getState().config.get( 'host' );
      var headers = getState().config.get( 'headers' ).toJSON();
      var href = route.get( 'href' );
      var url = `${host}${href}`
      var method = route.get( 'method' )
    } catch ( error ) {
      throw error
    }
    dispatch({
      type: BetHistoryTypes.FETCH_BET_HISTORY_URL,
      payload: {
        route: route,
        method: method,
        headers: headers,
        url: url
      }
    })
    return axios( {
      method,
      headers,
      url
    } )
    .then(({data}) => {
      dispatch({
        type: BetHistoryTypes.SET_BET_HISTORY_URL,
        payload: {
          url: data.url
        }
      })
      return data
    })
    .catch( error => {
      dispatch(serverError(error));
      throw error
    })
  }
}

export function fetchGameRoundHistory( route, params ) {
  return ( dispatch, getState ) => {
    try {
      var headers = getState().config.get( 'headers' ).toJSON();
      var url = route.get( 'href' );
      var method = route.get( 'method' )
    } catch ( error ) {
      throw error
    }

    dispatch( {
      type: BetHistoryTypes.FETCH_GAME_ROUND_HISTORY,
      payload: {
        headers,
        route,
        params
      }
    })

    return axios( {
      method,
      headers,
      url,
      params
    })
    .then(({data}) => {
      return fromJS(data)
    })
    .catch(err => {
      throw err
    })
  }
}

export function setGameRoundHistory(payload) {
  return ( dispatch, getState ) => {
    dispatch( {
      type: BetHistoryTypes.SET_GAME_ROUND_HISTORY,
      payload: payload
    })
    return payload
  }
}

export function fetchGameRoundHistoryPerGame( route, params ) {
  return ( dispatch, getState ) => {
    try {
      var headers = getState().config.get( 'headers' ).toJSON();
      var url = route.get( 'href' );
      var method = route.get( 'method' )
    } catch ( error ) {
      throw error
    }

    dispatch( {
      type: BetHistoryTypes.FETCH_GAME_ROUND_HISTORY_PER_GAME,
      payload: {
        headers,
        route,
        params
      }
    })

    return axios( {
      method,
      headers,
      url,
      params
    })
    .then(({data}) => {
      return fromJS(data)
    })
    .catch(err => {
      throw err
    })
  }
}

export function setGameRoundHistoryPerGame(payload) {
  return ( dispatch, getState ) => {
    dispatch( {
      type: BetHistoryTypes.SET_GAME_ROUND_HISTORY_PER_GAME,
      payload: payload
    })
    return payload
  }
}

export function fetchGameRounds( route ) {
  return ( dispatch, getState ) => {
    try {
      var headers = getState().config.get( 'headers' ).toJSON();
      var url = route.get( 'href' );
      var method = route.get( 'method' )
    } catch ( error ) {
      throw error
    }

    dispatch( {
      type: BetHistoryTypes.FETCH_GAME_ROUNDS,
      payload: {
        headers,
        route
      }
    })

    return axios( {
      method,
      headers,
      url
    })
    .then(({data}) => {
      return fromJS(data)
    })
    .catch(err => {
      throw err
    })
  }
}

export function setGameRounds(payload) {
  return ( dispatch, getState ) => {
    dispatch( {
      type: BetHistoryTypes.SET_GAME_ROUNDS,
      payload: payload
    })
    return payload
  }
}

export function fetchBetHistory( route , params) {
  return ( dispatch, getState ) => {
    try {
      var host = getState().config.get( 'host' );
      var headers = getState().config.get( 'headers' ).toJSON();
      var href = route.get( 'href' );
      var url = `${host}${href}`
      var method = route.get( 'method' )
    } catch ( error ) {
      throw error
    }
     dispatch( {
      type: BetHistoryTypes.SET_GAME_ROUNDS,
      payload: {
        route: route,
        method: method,
        headers: headers,
        url: url,
        params: params
      }
    } )

    return axios( {
      method,
      headers,
      url,
      params
    } )
    .then( ( {
      data
    } ) => {

      /* switch(params.page) {
        case 1:
          data = data1;
          break;
        case 2:
          data = data2;
          break;
        case 3:
          data = data3;
          break;
        case 4:
          data = data4;
          break;
        default:
          break;
      } */

      return dispatch( setBetHistroy(
        fromJS( Object.assign( {
          route,
          data
        } ) )
      ) )
    } )
    .catch( error => {
      dispatch( {
        type: BetHistoryTypes.ERROR_FETCH_BET_HISTORY,
        payload: error
      } )
      throw error
    } )

    //return
  }
}

function setBetHistroy(payload) {
	return {
		type: BetHistoryTypes.SET_BET_HISTORY,
    payload: payload
	}
}

export function clearBetHistory() {
  return {
    type: BetHistoryTypes.CLEAR_BET_HISTORY,
  }
}