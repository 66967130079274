import React, {
    Component
  } from 'react';
  
  class PlayDesktop extends Component {
    constructor( props ) {
      super( props );
  
      let color = props.color ? props.color : "rgba(0,0,0,0.5)";
      let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";
  
      this.state = {
        width: props.width ? props.width : 25,
        height: props.width ? props.width : 25,
        color: props.selected ? selectedColor : color,
        circleRadius: props.circleRadius ? props.circleRadius : "282.43"
      }
    }
  
    componentWillReceiveProps( nextProps ) {
      if ( nextProps.selected !== this.props.selected ) {
        this.setState( {
          color: nextProps.selected ? nextProps.selectedColor : nextProps.color
        } )
      }
    }
  
    render() {
      return <span className={this.props.className}>
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                  viewBox="0 0 528 528"
                  style={{
                      "enableBackground" : "new 0 0 528 528"
                  }}
                  >
  
                <path style={{
                      "fill":"#b40000"
                  }} d="M264,511.624c136.752,0,248-111.088,248-247.624S400.752,16.376,264,16.376S16,127.464,16,264  S127.248,511.624,264,511.624z M192.496,154.928l195.624,104.44L192.496,363.8V154.928z"/>
                <path style={{
                      "fill":"#FFFFFF"
                  }} d="M264,527.624c145.568,0,264-118.256,264-263.624S409.568,0.376,264,0.376S0,118.632,0,264  S118.432,527.624,264,527.624z M264,16.376c136.752,0,248,111.088,248,247.624S400.752,511.624,264,511.624S16,400.536,16,264  S127.248,16.376,264,16.376z"/>
                <polygon style={{
                      "fill":"#FFFFFF"
                  }} points="192.496,154.928 192.496,363.8 388.12,259.368 "/>                  
                  <g>
                  </g>
  
              </svg>
              {
                  this.props.label && <span
                      style={{
                          color: this.state.color,
                          top: 1,
                          position: "relative"
                      }}
                      className="label"
                      >
                      {this.props.label}
                  </span>
              }
          </span>
    }
  }
  
  export default PlayDesktop;