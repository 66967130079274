export const GameTypes = {
  GAME_LAUNCH: "GAME_LAUNCH",
  GAME_CLOSE: "GAME_CLOSE",
  SET_GAME_ACTION: "SET_GAME_ACTION",
  SET_GAME_ACTION_DETAILS: "SET_GAME_ACTION_DETAILS",
  CLEAR_GAME_ACTION: "CLEAR_GAME_ACTION",
  CLEAR_GAME_ACTION_DETAILS: "CLEAR_GAME_ACTION_DETAILS",
  FETCH_GAME_URL: "FETCH_GAME_URL",
  SET_GAME_URL: "SET_GAME_URL",
  FETCH_GAME_DETAILS_URL: "FETCH_GAME_DETAILS_URL",
  SET_GAME_DETAILS_URL: "SET_GAME_DETAILS_URL",
  FETCH_GAME_DETAILS: "FETCH_GAME_DETAILS",
  SET_GAME_DETAILS: "SET_GAME_DETAILS",
  SET_GAME_FULSCREEN: "SET_GAME_FULSCREEN",
  FETCH_GAME: "FETCH_GAME",
  SET_GAME: "SET_GAME",
};
