import GameFrame from "component/game/GameFrame";
import GameItem from "component/game/GameItem";
import GameItemBanner from "component/game/GameItemBanner";

export { GameFrame };
export { GameItem };
export { GameItemBanner };

export default Object.assign({
  GameFrame,
  GameItem,
  GameItemBanner,
});
