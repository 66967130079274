import React, { Component } from 'react';

import 'component/animation/LoadingDualRing/styles.scss';

class LoadingDualRing extends Component {

    render() {
        return <div className="lds-dual-ring"></div>
    }
}

export default LoadingDualRing;