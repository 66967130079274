import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';

import {
    ErrorMobileModal
} from 'component/modal'
import {
    ErrorModal
} from 'component/modal'
import Translations from 'translations';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import 'containers/Error/styles.scss';

class Error extends Component {
    constructor(props) {
        super(props);
        this.refreshHandler = this._refreshHandler.bind(this);
        this.goToExitUrl = this._goToExitUrl.bind(this);
    }

    _refreshHandler() {
        window.location.reload();
    }

    _goToExitUrl() {
        this.props.fetchExit(this.props.exitLink)
        .then(response => {
            if(!response.url) {
                window.location.reload();
            } else {
                window.location.href = response.url
            }
        })
    }

    getHeader(status) {
        switch(status) {
            case 503:
                return <DangerIcon />;
            default:
                return null
        }
    }

    getButtons(status) {
        switch (status) {
            case 401:
                if (!this.props.exitLink) {
                    return null
                }
                return <div className="w-100 center">
                    <button type="button" className="btn btn-danger btn-sm"
                    onClick={this.goToExitUrl}>
                        { Translations.okay }
                    </button>
                </div>
            case 503:
                return <button type="button" className="btn btn-danger btn-sm"
                    onClick={this.refreshHandler}>
                        { Translations.refresh }
                    </button>
            default:
                return null
        }
    }
    getMessage(status) {
        switch (status) {
            case 401:
                return <Fragment>
                    <h6 className="center yellow">{ Translations.sessionExpired }</h6>
                    <h6 className="center">{ Translations.loginAgain }</h6>
                </Fragment>
            case 403:
                return <Fragment>
                    <h6 className="center yellow">{ Translations.sessionInUse }</h6>
                    <h6 className="center">{ Translations.userAlreadyLoggedIn }</h6>
                </Fragment>
            case 417:
                return <Fragment>
                    <h6 className="center yellow">{ Translations.sessionInUse }</h6>
                    <h6 className="center">{ Translations.userAlreadyLoggedIn }</h6>
                </Fragment>
            case 503:
                return <Fragment>
                    <h5>{ Translations.error503 }</h5>
                    <h6>{ Translations.tryAgain }</h6>
                </Fragment>
            default:
                return null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error &&
            nextProps.error !== this.props.error) {
			if (nextProps.error.size === 0) {
                this.props.hideModal("ERROR")
            } else {
                this.props.showModal("ERROR")
            }
		}
    }

    render() {
        if (this.props.error.size === 0) {
            return null
        }

        return <div className="applicationError">
            {
                this.props.error.map((error, key) => {
                    let EModal = this.props.isMobile ? ErrorMobileModal : ErrorModal;
                    let { status, message} = error.response
                    let buttons = this.getButtons(status)
                    let statusMessage = this.getMessage(status);
                    let header = this.getHeader(status);
                    return <EModal
                        key={key}
                        header={header}
                        message={statusMessage ? statusMessage : message}
                        buttons={buttons}
                        />
                })
            }
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    let exitLink
    try {
        exitLink = state.profile.get('links').find(link => {
            return link.get( 'rel' ) === 'exit';
	    });
    } catch (err) {}

    return {
        exitLink: exitLink,
        error: state.error,
        isMobile: state.window.get('isMobile')
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
        fetchUrl: () => dispatchProps.fetchUrl(),
        showModal: (modal) => dispatchProps.showModal(modal),
        hideModal: (modal) => dispatchProps.hideModal(modal),
        fetchExit: (route) => dispatchProps.fetchExit(route)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Error);