import createReducer from 'utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    CategoryTypes,
    LoginTypes,
    LocalStorageTypes,
    SecurityTypes
} from 'actions/types';

export const defaultValue = fromJS({
})

export const categories = createReducer(defaultValue,{
    [CategoryTypes.SET_CATEGORY](state, { payload }) {
        let rel = payload.link.get('rel');
        for (var i = 0; i < payload.data.items.length; i++) {
            let gameId = payload.data.items[i].gameId;

            for (var j = 0; j < payload.data.items[i].relatedLinks.length; j++) {
                payload.data.items[i].relatedLinks[j].rel =  `${gameId}.${payload.data.items[i].relatedLinks[j].rel}`
            }
        }
        payload.data.name = payload.link.get('name');
        let newState = state.mergeIn([rel], payload.data);
        //localStorage.setItem('categories', JSON.stringify(newState.toJSON()))
        return newState
    },
    [LoginTypes.LOGOUT](state, { payload }) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('categories')
        return newState
    },
    [LocalStorageTypes.RESTORE_CATEGORIES](state, { payload }) {
        let newState = state.merge(payload)
        return newState
    },
    [SecurityTypes.TOKEN_REJECTED](state, {payload}) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('categories')
        return newState
    }
})