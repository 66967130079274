import createReducer from 'utils/createReducer';
import {
    UserTypes
} from 'actions/types';

import {
    fromJS
} from 'immutable';

export const defaultValue = fromJS({
    displayName: null,
    showLanguage: false,
    balance: null,
    currency: null,
		symbol: null,
		externalLinks: [],
		links: [],
})

export const profile = createReducer(defaultValue,{
    [UserTypes.SET_BALANCE](state, {payload}) {
        let newState = state.merge(payload.get('data'))
        return newState
    },
    [UserTypes.SET_PROFILE](state, {payload}) {
				let newState = state.merge(payload.get('data'))
				return newState
    }
})