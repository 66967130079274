import {
    LoginTypes
} from 'actions/types';
import Api from 'utils/api';

export function login(username, password) {
    return (dispatch, getState) => {
        try {
            var currentLoginIndex = getState().config.get('currentLoginIndex');
            var route = getState().urls.getIn(['loginUrls', currentLoginIndex]);
            var headers = getState().config.get('headers').toJSON();
            var url = route.get('href');
            var method = route.get('method');
            var codeChallenge = getState().security.get('codeChallenge');
            var params = Object.assign({
                username,
                password,
                codeChallenge
            })

        } catch(error) {
            dispatch(loginError(error))
            throw error
        }

        dispatch({
            type: LoginTypes.LOGIN,
            payload: params
        })

        return Api.xhr(headers, url, params, method)
        .then(response => {
            dispatch(loginSuccess(response))
            return response
        })
        .catch(error => {
            dispatch(loginError(error))
            throw error
        })
    }
}

export function loginSuccess(response) {
    return (dispatch, getState) => {
        dispatch({
            type: LoginTypes.LOGIN_SUCCESS,
            payload: response
        })
    }
}

export function loginError(error) {
    return {
        type: LoginTypes.LOGIN_ERROR,
        payload: error
    }
}

export function logout() {
    return (dispatch, getState) => {
        dispatch({
            type: LoginTypes.LOGOUT
        })
    }
}