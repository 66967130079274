import React, { Component } from 'react';

class LeftIcon extends Component {
    constructor(props) {
        super(props);

        let color = props.color ? props.color : "rgba(0,0,0,0.5)";
        let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

        this.state = {
            width: props.width ? props.width : 25,
            height: props.width ? props.width : 25,
            color: props.selected ? selectedColor : color
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.selected !== this.props.selected) {
            this.setState({
                color: nextProps.selected ? nextProps.selectedColor : nextProps.color
            })
        }
    }

    render() {
        return <div className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height*1}px`}
                viewBox="0 0 492 492"
                style={{
                    "enableBackground" : "new 0 0 492 492"
                }}>
                >
                <g>
                    <path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
                        C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
                        c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
                        l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
                        fill={this.state.color}
                        />
                </g>
            </svg>
            {
                this.props.label && <div
                    style={{
                        color: this.state.color
                    }}
                    >
                    {this.props.label}
                </div>
            }
        </div>
    }
}

export default LeftIcon;