import React, { Component } from 'react';

import 'component/animation/Loading/styles.css';

class MobileIcon extends Component {
    constructor(props) {
        super(props);

        let color = props.color ? props.color : "rgba(0,0,0,0.5)";
        let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

        this.state = {
            width: props.width ? props.width : 25,
            height: props.width ? props.width : 25,
            color: props.selected ? selectedColor : color
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.selected !== this.props.selected) {
            this.setState({
                color: nextProps.selected ? nextProps.selectedColor : nextProps.color
            })
        }
    }

    render() {
        return <span>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height}px`}
                viewBox="0 0 777.46 695"
                style={{
                    "enableBackground" : "new 0 0 777.46 695"
                }}
                >
                <path d="M586.28,247q-.35-24.66-25-25H300.7q-24.65.34-25,25V756q.25,19,15,23.56a34.47,34.47,0,0,0,10,1.42H561.3q24.65-.35,25-25V247m-25-5a5.77,5.77,0,1,1-1.69-4,5.32,5.32,0,0,1,1.69,4m-25.66,0a7.13,7.13,0,0,1-7,7,7,7,0,0,1-7-7,6.82,6.82,0,0,1,2-4.92,6.54,6.54,0,0,1,3.24-1.83,7.43,7.43,0,0,1,1.69-.2,7,7,0,0,1,1.76.2,6.54,6.54,0,0,1,3.24,1.83,6.72,6.72,0,0,1,2.09,4.92M430.8,740.9a14.14,14.14,0,0,1,10.53,4.39,14.38,14.38,0,0,1,4.39,10.53,14.92,14.92,0,0,1-29.84,0,14.3,14.3,0,0,1,4.39-10.53,14.14,14.14,0,0,1,10.53-4.39m-144.95-7.63V259.2H576.22V733.27H285.92M450.38,246a5.4,5.4,0,0,1-3.85,1.68H415.4A5.69,5.69,0,0,1,414,236.52a5.83,5.83,0,0,1,3.1,0h27.75a5.86,5.86,0,0,1,3.11,0,5.77,5.77,0,0,1,2.43,1.48,5.56,5.56,0,0,1,.12,7.85l-.12.12Z"
                    transform="translate(-250 -86)"
                    fill={this.state.color}/>
            </svg>
        </span>
    }
}

export default MobileIcon;

