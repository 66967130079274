import Big from 'big.js'


/**
 * Format numbers to currency or add thousand separators
 * @param  {[num]} number [number to be format i.e. 123456]
 * @param  {[object]} params   [contains proptypes which includes decimalplaces, decimalcharacter, thousandseparater, currency]
 */

var currency_symbols = {
    'USD': '$', // US Dollar
    'EUR': '€', // Euro
    'CRC': '₡', // Costa Rican Colón
    'GBP': '£', // British Pound Sterling
    'ILS': '₪', // Israeli New Sheqel
    'INR': '₹', // Indian Rupee
    'CNY': '¥', // Chinese Yuan
    'JPY': '¥', // Japanese Yen
    'KRW': '₩', // South Korean Won
    'NGN': '₦', // Nigerian Naira
    'PHP': '₱', // Philippine Peso
    'PLN': 'zł', // Polish Zloty
    'PYG': '₲', // Paraguayan Guarani
    'THB': '฿', // Thai Baht
    'UAH': '₴', // Ukrainian Hryvnia
    'VND': '₫', // Vietnamese Dong
    'RUB': '₽', //  Russian Ruble
    'IDR': 'RP', //Indonesian Rupiah
    'TRY': '₺', //Turkish Lira
    'HKD': '$',  //Hong Kong Dollar
    'MYR': 'RM', //Malaysian Ringgit
    'TWD': 'NT$', //Taiwan New Dollar
    'MMK': 'K', //Burmese kyat
    'NOK': 'kr', //Norway Krone
    'SEK': 'kr', //Sweden Krona
    'AUD': '$', //Australia Dollar
    'KZT': '₸', //Kazakhstan Tenge
    'KES': 'KSh', //Kenyan Shilling
    'BRL': 'R$', //Brazilian Real
    'MXN': 'Mex$', //Mexican Peso
    'COP': '$', //Colombian Peso
    'PEN': 'S/', //Peruvian Sol
    'ARS': '$', //Argentinian Peso
    'CLP': '$', //Chilean Peso
    'ZAR': 'R', //South African Rand
}

function numberFormatter ( number, params ) {
	if( typeof number == 'undefined' || number === '' )
		return number

	params = params || {}
	let decimalplaces = typeof params.decimalplaces !== 'undefined' ? params.decimalplaces : ( countDecimalPlaces(number) > 2 ? 8 : 2 )
	let decimalcharacter = typeof params.decimalcharacter !== 'undefined' ? params.decimalcharacter : '.'
	decimalplaces = decimalplaces <= 0 ? 0 : decimalplaces
	decimalcharacter = decimalplaces <= 0 ? '' : decimalcharacter
	let thousandseparater = typeof params.thousandseparater !== 'undefined' ? params.thousandseparater : ','
	// eslint-disable-next-line
	let currency = typeof params.currency !== 'undefined' ? ' ' + params.currency : ''

	if( /\s/g.test( number ) ) {
		let newData = number.split( " " )
		let newDataCount = newData.length
		number = newData[ 0 ] || 0
		if( newDataCount > 1 )
			currency = ' ' + newData[ 1 ]
	}

	Big.DP = 60
	Big.E_POS = 60
	Big.E_NEG = -60
	number = new Big(number) //parseFloat( number )

	if( isNaN( number ) )
		number = parseFloat( 0 )

	let sign = number < 0 ? '-' : ''
	let formatted = number.toFixed( decimalplaces )

	if( decimalcharacter.length && decimalcharacter !== '.' ) {
		formatted = formatted.replace( /\./, decimalcharacter )
	}

	let integer = ''
	let fraction = ''
	let strnumber = formatted.toString()

	let dotpos = decimalcharacter.length ? strnumber.indexOf( decimalcharacter ) : -1

	if( dotpos > -1 ) {
		if( dotpos ) {
			integer = strnumber.substr( 0, dotpos )
		}
		fraction = strnumber.substr( dotpos + 1 )
	} else {
		integer = strnumber
	}

	if( integer ) {
		integer = Big(integer).abs().toString()
	}



	/** Fallback for .toFixed() */
	// while(fraction.length < decimalplaces) {
	//   fraction += '0'
	// }
	/** Fallback for .toFixed() */
	// eslint-disable-next-line
	for( let fractionLength = fraction.length; fraction.length < decimalplaces; fraction += '0' ) {
		fractionLength = fraction.length
	}

	/** Thousands separator */
	let temparray = []
	while( integer.length > 3 ) {
		temparray.unshift( integer.substr( -3 ) )
		integer = integer.substr( 0, integer.length - 3 )
	}
	temparray.unshift( integer )
	integer = temparray.join( thousandseparater )

	return sign + integer + decimalcharacter + fraction
}

function countDecimalPlaces(num) {
	var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
	if (!match) { return 0; }
	return Math.max(
		0,
		(match[1] ? match[1].length : 0)
		- (match[2] ? +match[2] : 0));
}

export function addSymbol(number, params) {
	params = params || {}
	let currency = typeof params.currency !== 'undefined' ? params.currency : ''

	currency = currency_symbols[currency] || ''

	if(params.symbol)
		return params.symbol + ' ' + number
	else
		return currency + ' ' + number
}

export function currencyFormatter(number, params) {
	if( isNaN(number) )
        return number

	if( typeof number == undefined || number === '' || number === null)
		number = 0

	params = params || {}
	let currency = typeof params.currency !== 'undefined' ? params.currency : ''


	if( /\s/g.test( number ) ) {
		let newData = number.split( " " )
		let newDataCount = newData.length
		number = newData[ 0 ] || 0
		if( newDataCount > 1 )
			currency = newData[ 1 ]
	}

	let formattedNumber = numberFormatter(number, params)

	currency = currency_symbols[currency] || ''

	if(params.symbol)
		return params.symbol + ' ' + formattedNumber
	else
		return currency + ' ' + formattedNumber
}

/* function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
        x *= Math.pow(10,e-1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        x /= Math.pow(10,e);
        x += (new Array(e+1)).join('0');
    }
  }
  return x;
} */