import Api from 'utils/api';
import {
    SecurityTypes
} from 'actions/types';

export function setCodeChallenge(code) {
    return {
        type: SecurityTypes.SET_CODE_CHALLENGE,
        payload: {
            codeChallenge: code
        }
    }
}

export function fetchVerifyToken() {
    return (dispatch, getState) => {
        try {
            var domains = getState().urls.get('domains');
            var headers = getState().config.get('headers').toJSON();
            var currentDomainIndex = getState().config.get('currentDomainIndex');
            var route = getState().urls.get('verify');
            var operatorId = getState().config.get('operatorId');
            var token = getState().security.get('accessToken');
            var codeChallenge = getState().security.get('codeChallenge');
            var method = route.get('method');
            var params = {
                token: token,
                appId: operatorId,
                code_challenge: codeChallenge,
            }
            var url = `${domains.get(currentDomainIndex)}${route.get('href')}`;
        } catch (error) {
            dispatch(tokenRejected(error))
            throw error;
        }

        dispatch({
            type: SecurityTypes.FETCH_VERIFY_TOKEN,
            payload: {
                method: method,
                url: url,
                params: params
            }
        })

        return Api.xhr(headers, url, params, method)
        .then( response => {
            dispatch({
                type: SecurityTypes.TOKEN_VERIFIED,
                payload: response
            });
            return response;
        })
        .catch( error => {
            dispatch(tokenRejected(error));
            throw error
        })
    }
}

export function tokenRejected(error) {
    return {
        type: SecurityTypes.TOKEN_REJECTED,
        payload: error
    }
}