/**
 *
 * add translations here
 * always in camel case
 *
 */
/* prettier-ignore */

var languages = {
  "en-US": {
    'language': 'Language',
    'changeLanguage': 'Change Language',
    "mostPopular": "Most Popular",
    "recentlyAdded": "Recently Added",
    "alphabetical": "Name",
    "popular": "Popularity",
    "newLabel": "Newest",
    "filters": "Filters",
    "filter": "Filter",
    "clearAllFilters": "Clear all filters",
    "clearAll": "Clear all",
    "all": "All",
    "provider": "Provider",
    "gameType": "Game Type",
    "themes": "Themes",
    "features": "Features",
    "volatility": "Volatility",
    "released": "Released",
    "releaseDate": "Release Date",
    "availableIn": "Available In",
    "apply": "Apply",
    "playNow": "Play Now",
    "demoPlay": "Demo Play",
    "playForReal": "Play For Real",
    "minimize": "Minimize",
    "maximize": "Maximize",
    "close": "Close",
    "noRecomendedGames": "No recommended games to display.",
    "newVersion": "New Version Available!",
    "pleaseRefresh": "Please refresh before proceeding.",
    "update": "Refresh Now",
    "seeAll": "See All",
    "showMore": "Show More",
    "showLess": "Show Less",
    "seeMore": "See More",
    "version": "Version",
    "build": "Build",
    "englishLanguage": "English",
    "chineseLanguage": "中文",
    "japaneseLanguage": "日本語",
    "exit": "Exit",
    "error503": "System undergoing maintenance.",
    "tryAgain": "Try again later.",
    "refresh": "Refresh",
    "sessionExpired" : "Session Expired",
    "loginAgain" : "Please login again",
    "okay" : "OK",
    "prizePool" : "Prize Pool",
    "dateD" : "d",
    "dateH" : "h",
    "dateM" : "m",
    "startsIn" : "Starts In",
    "startDate" : "Start Date",
    "start": "Start",
    "endsIn" : "Ends In",
    "endDate" : "End Date",
    "end": "End",
    "comingSoon": "Coming soon",
    "ongoing": "Ongoing",
    "ended": "Ended",
    "expired": "Expired",
    "claimYourRewards": "Claim Your Rewards",
    "doYouWantToLeave": "Do you want to leave?",
    "stay": "stay",
    "leave": "leave",
    "sessionInUse": "Session in use",
    "userAlreadyLoggedIn" : "User already logged in from other device",
    "sortBy" : "Sort by",
    "games" : "Games",
    "noResultsFor": "Found 0 games with",
    "categories": "Categories",
    "found": "Found",
    "providers": "Providers",
    "search": "Try the first letter of each word in the game name",
    "searchMessage1" : "Search for games, providers, categories, types, features, themes and more.",
    "searchMessage2": "Please enter at least 3 characters to automatically start the search.",
    "gamesWith": "games with",
    "searchPlaceHolder": "Games, Providers, Types and more",
    "tournament": "Tournament",
    "results": "Results",
    "howToWin": "How to Win",
    "prizes": "Prizes",
    "termsAndCondition": "Terms & Conditions",
    "viewGames": "View Games",
    "category": "Category",
    "prizeTable": "Daily Prizes",
    "overallPrizeTable": "Overall Prizes",
    "overall": "Overall",
    "condition" : "Condition",
    "pointsCondition": "Points Condition",
    "rank": "Rank",
    "payout": "Payout",
    "prize": "Prize",
    "points": "Points",
    "player": "Player",
    "type": "Type",
    "from": "From",
    "to": "to",
    "day": "Day",
    "whatAreYouLookingFor": "What are you looking for?",
    "qualifyingGames": "Qualifying Games",
    "yourTotalWin": "Your Total Win",
    "status": "Status",
    "gameHistory": "Game History",
    "thereIsNoData": "There is no data to display.",
    "game": "Game",
    "pending": "Pending",
    "failed": "Failed",
    "completed": "Completed",
    "lastPlayed": "Last Played",
    "totalBet": "Total Bet",
    "totalWin": "Total Win",
    "initiated": "Initiated",
    "rounds": "Rounds",
    "roundDetails": "Round Details",
    "overview": "Overview",
    "gameResult": "Game Result",
    "roundId": "Round ID",
    "name": "Name",
    "currency": "Currency",
    "roundRefId": "Round Ref ID",
    "playerCurrency": "Player Currency",
    "playerToGameFx": "Player to Game FX",
    "startBalance": "Start Balance",
    "endBalance": "End Balance",
    "totalFreeBet": "Total Free Bet",
    "totalPayout": "Total Payout",
    "minBet": "Min Bet",
    "roundIdCopied": "Round ID copied",
    "noMinimumBet": "No minimum bet",
    "grand": "Grand",
    "major": "Major",
    "minor": "Minor",
    "winners": "Winners",
    "date": "Date",
    "pool": "Pool",
    "viewWinners": "View Winners",
    "jackpotPools": "Jackpot Pools",
    "progressivePools": "Progressive Pools",
    "mini": "Mini",
    "numberOfGames": "No. of Games",
    "numberOfWinners": "No. of Winners",
    "view": "View",
    "more": "more",
    "less": "less",
    "sort": "Sort",
    "sortEditor": "Sorted by Editor",
    "sortPopularity": "Sorted by Popularity",
    "sortNewest": "Sorted by Newest",
    "sortName": "Sorted by Name",
    "sortLastPlayed": "Sorted by Last Played",
    "sortedRelevance": "Sorted by Relevance",
    "noAvailableGameplay": "No available gameplay for this game",
    "discover": "Discover",
    "depositWithdraw" : "Deposit / Withdraw",
    "switchDevice": "Switch Device",
    "lobby": "Lobby",
  },
  "zh-CN": {
    'language': '语言',
    'changeLanguage': '更换语言',
    "mostPopular": "热门游戏",
    "recentlyAdded": "最新游戏",
    "alphabetical": "游戏名",
    "popular": "流行度",
    "newLabel": "最新",
    "filters": "筛选",
    "filter": "筛选",
    "clearAllFilters": "清除所有过滤器",
    "clearAll": "清除",
    "all": "全部",
    "provider": "提供商",
    "gameType": "游戏类型",
    "themes": "主题",
    "features": "特色功能",
    "volatility": "浮动率",
    "released": "已发布",
    "releaseDate": "发布日期",
    "availableIn": "可用于",
    "apply": "申请",
    "playNow": "开始游戏",
    "demoPlay": "试玩",
    "playForReal": "开始游戏",
    "minimize": "最大化",
    "maximize": "最小化﻿",
    "close": "关闭",
    "noRecomendedGames": "无推荐游戏",
    "newVersion": "新版本可用！",
    "pleaseRefresh": "请更新至最新版本",
    "update": "更新",
    "seeAll": "查看全部",
    "showMore": "显示更多",
    "showLess": "显示更少",
    "seeMore": "查看更多",
    "version": "Version",
    "build": "Build",
    "englishLanguage": "English",
    "chineseLanguage": "中文",
    "japaneseLanguage": "日本語",
    "exit": "Exit",
    "error503": "系统正在维护中.",
    "tryAgain": "请稍后尝试.",
    "refresh": "刷新",
    "sessionExpired" : "会话已过期",
    "loginAgain" : "请重新登录",
    "okay" : "好的",
    "prizePool" : "奖池",
    "dateD" : "天",
    "dateH" : "小时",
    "dateM" : "分钟",
    "start": "开始",
    "startsIn" : "距活动开始时间还有",
    "startDate" : "开始日期",
    "end": "结束",
    "endsIn" : "距活动结束时间还有",
    "endDate" : "完成日期",
    "comingSoon": "即将上线",
    "ongoing": "正在进行",
    "ended": "已结束",
    "expired": "已过期",
    "claimYourRewards": "领取奖励",
    "doYouWantToLeave": "你需要退出？",
    "stay": "不退出",
    "leave": "退出",
    "sessionInUse": "会话使用中",
    "userAlreadyLoggedIn" : "用户已在其他设备登录",
    "sort": "分类",
    "sortBy" : "排序方式",
    "games" : "游戏",
    "noResultsFor": "找到 0 款游戏",
    "categories": "类别",
    "found": "找到",
    "providers": "提供商",
    "search": "搜索",
    "searchMessage1" : "搜索游戏、提供商、类别、类型、功能、主题等等。",
    "searchMessage2": "搜索至少需要 3 个字符。",
    "gamesWith": " 款游戏",
    "searchPlaceHolder": "游戏、提供商、类型等等",
    "tournament": "竞赛",
    "results": "结果",
    "howToWin": "如何获胜",
    "prizes": "奖金",
    "termsAndCondition": "活动条款",
    "viewGames": "查看游戏",
    "category": "分类",
    "prizeTable": "每日奖金",
    "overallPrizeTable": "总奖金",
    "overall": "总体",
    "condition" : "条件",
    "pointsCondition": "积分条件",
    "rank": "排名",
    "payout": "支出",
    "prize": "奖金",
    "points": "积分",
    "player": "玩家",
    "type": "类型",
    "whatAreYouLookingFor" : "你要寻找什么？",
    "from": "从",
    "to": "到",
    "day": "每日",
    "qualifyingGames": "合格游戏",
    "yourTotalWin": "你的总赢",
    "status": "状态",
    "gameHistory": "游戏历史纪录",
    "thereIsNoData": "没有可显示的数据。",
    "game": "游戏",
    "pending": "待完成",
    "failed": "失败",
    "completed": "完成",
    "lastPlayed": "最近游戏",
    "totalBet": "总投注",
    "totalWin": "总赢奖",
    "initiated": "启动",
    "rounds": "游戏局",
    "roundDetails": "游戏局细节",
    "overview": "概述",
    "gameResult": "游戏结果",
    "roundId": "局数Id",
    "name": "名称",
    "currency": "货币",
    "roundRefId": "游戏局参考 ID",
    "playerCurrency": "玩家货币",
    "playerToGameFx": "玩家到游戏 FX",
    "startBalance": "开始余额",
    "endBalance": "结束余额",
    "totalFreeBet": "总免费投注",
    "totalPayout": "总出款",
    "minBet": "最小投注",
    "roundIdCopied": "游戏局ID已复制",
    "noMinimumBet": "没有最低投注",
    "grand": "巨奖",
    "major": "大奖",
    "minor": "中奖",
    "winners": "获奖者",
    "date": "日期",
    "pool": "奖池",
    "viewWinners": "查看获奖者",
    "jackpotPools": "累积奖金池",
    "progressivePools": "累积池",
    "mini": "小奖",
    "numberOfGames": "比赛场数",
    "numberOfWinners": "获奖人数",
    "view": "查看",
    "more": "更多",
    "less": "减少",
    "sortEditor": "按编辑排序",
    "sortPopularity": "按受欢迎程度排序",
    "sortNewest": "按最新排序",
    "sortName": "按名称排序",
    "sortLastPlayed": "按上次游玩排序",
    "sortedRelevance": "按相关性排序",
    "noAvailableGameplay": "此游戏不支持游玩",
    "discover": "发现",
    "depositWithdraw" : "存款 / 提款",
    "switchDevice": "更换设备",
    "lobby": "大厅",
  },

  "ja-JP": {
    'language': '言語',
    'changeLanguage': '言語を変更',
    "mostPopular": "Most Popular",
    "recentlyAdded": "Recently Added",
    "alphabetical": "名前",
    "popular": "人気",
    "newLabel": "最新",
    "filters": "フィルター",
    "filter": "フィルター",
    "clearAllFilters": "すべてのフィルタをクリアする",
    "clearAll": "すべてクリア",
    "all": "全て",
    "provider": "メーカー",
    "gameType": "ゲームの種類",
    "themes": "テーマ",
    "features": "特徴",
    "volatility": "ボラティリティ",
    "released": "リリース日",
    "releaseDate": "リリース日",
    "availableIn": "Available In",
    "apply": "Apply",
    "playNow": "今すぐプレイ",
    "demoPlay": "デモプレイ",
    "playForReal": "ゲームプレイ",
    "minimize": "Minimize",
    "maximize": "最大化",
    "close": "閉じる",
    "noRecomendedGames": "No recommended games to display.",
    "newVersion": "新バージョン登場！",
    "pleaseRefresh": "ゲーム前にアップデートをお願いします",
    "update": "Refresh Now",
    "seeAll": "See All",
    "showMore": "もっと表示する",
    "showLess": "表示を減らす",
    "seeMore": "See More",
    "version": "Version",
    "build": "Build",
    "englishLanguage": "English",
    "chineseLanguage": "中文",
    "japaneseLanguage": "日本語",
    "exit": "Exit",
    "error503": "システムメンテナンス中です。",
    "tryAgain": "後ほどお試しください。",
    "refresh": "更新",
    "sessionExpired" : "セッションの有効期限が切れました",
    "loginAgain" : "もう一度ログインしてください",
    "okay" : "OK",
    "prizePool" : "キャリーオーバー",
    "dateD" : "日",
    "dateH" : "時間",
    "dateM" : "分",
    "startsIn" : "イベント開始時刻まで",
    "startDate" : "開始日",
    "start": "スタート",
    "endsIn" : "イベント終了時刻まで",
    "endDate" : "終了日",
    "end": "終了",
    "comingSoon": "Coming soon",
    "ongoing": "開催中",
    "ended": "終了",
    "expired": "開催終了",
    "claimYourRewards": "報酬獲得",
    "doYouWantToLeave": "終了しますか？",
    "stay": "留まる",
    "leave": "退出",
    "sessionInUse": "使用中のセッション",
    "userAlreadyLoggedIn" : "ユーザーはすでに他のデバイスからログインしています",
    "sort": "並べ替え",
    "sortBy" : "並び替え",
    "games" : "ゲーム",
    "noResultsFor": "で検索の結果 0 件見つかりました",
    "categories": "カテゴリ",
    "found": "件見つかりました",
    "providers": "メーカー",
    "search": "検索",
    "searchMessage1" : "ゲーム、メーカー、カテゴリ、ジャンル、機能、テーマなどを検索します",
    "searchMessage2": "検索には最低3文字以上が必要です",
    "gamesWith": "で検索の結果",
    "searchPlaceHolder": "ゲーム、メーカー、タイプなど",
    "tournament": "トーナメント",
    "results": "結果",
    "howToWin": "勝利条件",
    "prizes": "賞金",
    "termsAndCondition": "利用規約",
    "viewGames": "ゲームを観る",
    "category": "カテゴリ",
    "prizeTable": "ログインボーナス",
    "overallPrizeTable": "総合賞金",
    "overall": "全体",
    "condition" : "条件",
    "pointsCondition": "ポイント獲得条件",
    "rank": "ランキングボード",
    "payout": "ペイアウト",
    "prize": "賞金",
    "points": "ポイント",
    "player": "プレイヤー",
    "type": "種類",
    "from": "から",
    "to": "まで",
    "day": "Day",
    "whatAreYouLookingFor" : "何をお探しですか？",
    "qualifyingGames": "対象ゲーム",
    "yourTotalWin": "Your Total Win",
    "status": "ステータス",
    "gameHistory": "ゲーム結果",
    "thereIsNoData": "表示するデータがありません。",
    "game": "ゲーム",
    "pending": "保留",
    "failed": "失敗",
    "completed": "終了時間",
    "lastPlayed": "前回プレイ",
    "totalBet": "トータルBET",
    "totalWin": "合計賞金",
    "initiated": "開始時間",
    "rounds": "ラウンド",
    "roundDetails": "ラウンド詳細",
    "overview": "概要",
    "gameResult": "ゲーム結果",
    "roundId": "ラウンドID",
    "name": "名称",
    "currency": "通貨",
    "roundRefId": "ラウンド照会ID",
    "playerCurrency": "プレイヤー通貨",
    "playerToGameFx": "プレイヤーからGame FXへ",
    "startBalance": "開始時残高",
    "endBalance": "終了時残高",
    "totalFreeBet": "トータル無料BET",
    "totalPayout": "トータルPayout",
    "minBet": "最小ベット",
    "roundIdCopied": "ラウンドIDをコピーしました",
    "noMinimumBet": "最低ベットなし",
    "grand": "グランド",
    "major": "メジャー",
    "minor": "マイナー",
    "winners": "勝者",
    "date": "日付",
    "pool": "プール",
    "viewWinners": "受賞者を見る",
    "jackpotPools": "ジャックポットプール",
    "progressivePools": "プログレッシブプール",
    "mini": "ミニ",
    "numberOfGames": "ゲーム数",
    "numberOfWinners": "勝者数",
    "view": "表示",
    "more": "もっと",
    "less": "少ない",
    "sortEditor": "編集者で並べ替え",
    "sortPopularity": "人気順に並べ替え",
    "sortNewest": "最新順に並べ替え",
    "sortName": "名前で並べ替え",
    "sortLastPlayed": "最後にプレイした順に並べ替え",
    "sortedRelevance": "関連性で並べ替え",
    "noAvailableGameplay": "このゲームで利用可能なゲームプレイはありません",
    "discover": "検索",
    "depositWithdraw" : "出金/入金",
    "switchDevice": "端末を切り替え",
    "lobby": "ロビー",
  },

}

/**
 *
 * end translations
 *
 */

var win = window,
  loc = win.location,
  pathname = loc.pathname,
  supportedLanguage = ["en-US", "zh-CN", "ja-JP"];

var path = pathname.split("/"),
  selectedLanguage = supportedLanguage[0];

supportedLanguage.map((elem) => {
  path.map((a) => {
    if (a === elem) selectedLanguage = elem;
    return a;
  });
  return elem;
});

export default languages[selectedLanguage];
