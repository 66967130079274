import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class ShootingIcon extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
      this.setState( {
        width: nextProps.width,
        height: nextProps.height
      } )
  }

  render() {
    return <div className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height*1}px`}
                viewBox="0 0 850 850"
                style={{
                    "enableBackground" : "new 0 0 850 850"
                }}>
                <defs>
                    <mask id="shmask" x="0" y="0">
                    <path d="M586,584.64q55.31-55.3,64.56-129.71H769.42l15.82-16.57v-3.51a1,1,0,0,0,0-.74q.15-4.56,0-9,.15-4.47,0-8.88a1.16,1.16,0,0,0,0-.82v-3.43l-15.82-16.72H650.9q-8.8-75.6-64.86-131.65-55.9-55.9-131.28-64.78V80.57L438.12,64.74h-3.44a1.84,1.84,0,0,0-.74,0q-4.56-.08-9,0c-3,0-6,0-9,0a1.88,1.88,0,0,0-.75,0h-3.43L395.05,80.57V198.93q-74.62,9.1-130.16,64.64-56,56-64.78,131.65H80.55L64.72,411.94v3.43a2.81,2.81,0,0,0,0,.82q-.06,4.41,0,8.88t0,9a2.3,2.3,0,0,0,0,.74v3.51l15.83,16.57H200.26q9.33,74.4,64.63,129.71,55.53,55.53,130.16,64.78v120l16.72,15.83h3.43a1.88,1.88,0,0,0,.75,0c3,0,6,0,9,0s6,0,9,0a1.84,1.84,0,0,0,.74,0h3.44l16.64-15.83V649.5q75.38-9,131.28-64.86m26.5-189.42H577.76v59.71h34.55q-9,58.74-53.14,102.92-44.7,44.77-104.41,53.36v-34.7H395.05v34.55q-58.87-9-103.14-53.21t-53-102.92h34.56V395.22H238.7q8.51-59.85,53.21-104.56,44.25-44.27,103.14-53.21V272.6h59.71V237.3q59.7,8.58,104.41,53.36t53.37,104.56m-180.47-37-3.88-5h-6l-3.74,5v59.56h-55.9l-4.7,4.11v6.56l4.7,4h55.9V492l3.74,5h6l3.88-5V432.46H488l4.63-4v-6.56L488,417.76h-55.9Z"
                            fill="white"/>
                    </mask>
                </defs>
                <rect x="0" y="0" width="850" height="850"
                    mask="url('#shmask')"
                    fill="url('#categoryNormal')"
                    />
            </svg>
            {
                this.props.label && <div
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default ShootingIcon;