import VericalList from "component/list/Vertical";
import Games4by2 from "component/list/Games4by2";

export { VericalList };
export { Games4by2 };

export default Object.assign({
  VericalList,
  Games4by2,
});
