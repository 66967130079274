import axios from 'axios';
import {
    UpdateTypes
} from 'actions/types';

export function checkForUpdate() {
	return (dispatch, getState) => {
		var host = getState().config.get( 'host' );
		var timestamp = new Date().getTime();

		dispatch({
			type: UpdateTypes.CHECK_FOR_UPDATE,
			payload: {
				url: `${host}/version.json?timestamp=${timestamp}`
			}
		})
		return axios({
			url: `${host}/version.json?timestamp=${timestamp}`,
			method: 'GET',
		})
		.then(response => {
			dispatch(updateAvailable(response.data));
			return response.data
		})
		.catch(error => {
			throw(error)
		})
	}
}

export function updateAvailable(data) {
	return (dispatch, getState) => {
		return dispatch({
			type: UpdateTypes.UPDATE_AVAILABLE,
			payload: data
		})
	}
}