export function convertMS(ms) {
  var d, h, m, s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s = pad(s % 60);
  h = Math.floor(m / 60);
  m = pad(m % 60);
  d = Math.floor(h / 24);
  h = pad(h % 24);
  return { d: d, h: h, m: m, s: s };
};

export function pad(number) {
  return (number < 10 ? '0' : '') + number
}