import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import { isEmpty } from "lodash";
import { fromJS, is } from "immutable";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import "containers/ShowMore/Games/styles.scss";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import {
  CategoryType3Skeleton,
  CategoryType4Skeleton,
} from "component/listItemSkeleton";
import { CommonHeader } from "component/common";
import {
  CategoryType3ListItem,
  CategoryType4ListItem,
} from "component/listItem";
import { CategoryFiltersComponent } from "component/filters";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { VericalList } from "component/list";
import Translations from "translations";
import ShowMoreFilters from "containers/ShowMoreFilters";

const SEE_ALL_MODAL = "SEE_ALL_MODAL";
class ShowMoreGames extends Component {
  constructor(props) {
    super(props);
    this.seeMoreRef = createRef();
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.onClickPrevious = this._onClickPrevious.bind(this);
    this.onReachEnd = this._onReachEnd.bind(this);
    this.handleScroll = this._handleScroll.bind(this);
    this.loadList = this._loadList.bind(this);
    this.onFilterClickHandler = this._onFilterClickHandler.bind(this);
    this.onFilterClearHandler = this._onFilterClearHandler.bind(this);
    this.onApplyFilterClickHandler = this._onApplyFilterClickHandler.bind(this);
    this.state = {
      skeleton: [1, 2, 3, 4, 5, 6],
      showCategoryLabel: false,
      startsIn: null,
      endsIn: null,
      supportingText: null,
      activeFilter: null,
    };
  }

  _onFilterClickHandler(subCategory, value) {
    this.props.setActiveFilter(this.props.filtersLink, subCategory, value);
  }

  _onFilterClearHandler(subCategory) {
    this.props.clearFilterByCategory(
      this.props.filtersRoute.get("rel"),
      subCategory
    );
  }

  _onApplyFilterClickHandler() {
    this.props.showFilters(!this.props.filtersVisible);
  }

  _loadList(route, filters, sortBy, page) {
    if (!route) return;

    let params = new URLSearchParams();
    params.append("size", 50);
    params.append("page", page ? page : 1);

    if (sortBy) {
      params.append("sortBy", sortBy);
    }

    if (filters) {
      filters.forEach((filter) => {
        filter.get("values").forEach((value) => {
          if (value.get("toggled") === true) {
            params.append(filter.get("id"), value.get("id"));
          }
        });
      });
    }
    this.props.fetchGames(route, params);
  }

  _onClickPrevious() {
    this.props.goBack(
      `${this.props.location.pathname}${this.props.location.search}`
    );
    this.props.history.goBack();
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    let params = queryString.parse(this.props.location.search);
    params.playType = rel;
    params.nav = this.props.route.get("rel");
    params.gameId = game.get("gameId");

    this.props.setGameAction(params);
  }

  _onReachEnd() {
    if (!this.props.games) return;

    let { totalPages, page } = this.props.games.toJSON();

    if (page < totalPages && !this.props.isLoading) {
      let nextPage = page + 1;
      let params = {
        size: 50,
        page: nextPage,
      };

      this.props.fetchGames(this.props.route, params);
    }
  }

  _handleScroll() {
    const scrollDemo = document.querySelector(".show-more-games-modal");
    if (scrollDemo.scrollTop !== 0) {
      this.setState({ showCategoryLabel: true });
    } else {
      this.setState({ showCategoryLabel: false });
    }
  }

  componentDidMount() {
    if (!this.props.filterRoute && !this.props.sortBy) {
      this.loadList(this.props.route, null, null, 1);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showMoreRel !== this.props.showMoreRel) {
      if (!nextProps.showMoreRel) {
        this.props.hideModal(SEE_ALL_MODAL);
      } else {
        this.props.showModal(SEE_ALL_MODAL);
      }
    }

    if (nextProps.games) {
      this.setState({ supportingText: nextProps.games.get("supportingText") });
    }

    if (
      (!is(nextProps.route, this.props.route) ||
        !is(nextProps.filters, this.props.filters) ||
        !is(nextProps.sortBy, this.props.sortBy)) &&
      (nextProps.filters || nextProps.sortBy)
    ) {
      this.loadList(this.props.route, nextProps.filters, nextProps.sortBy, 1);
    }
  }

  renderSeeAllListItem(game, key) {
    let linkDemo = game.get("links").find((link) => {
      return link.get("rel") === "launch.demo";
    });
    let linkReal = game.get("links").find((link) => {
      return link.get("rel") === "launch.real";
    });
    let linkDetails = game.get("links").find((link) => {
      return link.get("rel") === "game.details";
    });

    let ListItem;
    switch (this.props.showMoreRel) {
      case "show.more.categories-home.recentlyplayed":
        ListItem = CategoryType3ListItem;
        break;
      case "show.more.play-it-again":
        ListItem = CategoryType3ListItem;
        break;
      default:
        ListItem = CategoryType4ListItem;
        break;
    }

    return (
      <>
        <ListItem
          key={key}
          game={game}
          linkDemo={linkDemo}
          linkReal={linkReal}
          linkDetails={linkDetails}
          host={this.props.host}
          isMobile={this.props.isMobile}
          onClick={(rel) => {
            this.onGameItemClickHandler(game, rel);
          }}
          onShowActionClick={() => {
            this.launchActions(game);
          }}
          currency={this.props.currency}
          symbol={this.props.symbol}
        />
      </>
    );
  }

  resize2fit(elements) {
    elements.forEach((el) => {
      if (!el.parentElement) return;

      el.style.setProperty("--font-size", "1.5em");
      const {
        width: max_width,
        height: max_height,
      } = el.getBoundingClientRect();
      const { width, height } = el.children[0].getBoundingClientRect();
      el.style.setProperty(
        "--font-size",
        Math.min(max_width / width, max_height / height) + "em"
      );
    });
  }

  renderSortTitle(sortBy) {
    let sortTitle;
    switch (sortBy) {
      case "editor":
        sortTitle = Translations.sortEditor;
        break;
      case "popularity":
        sortTitle = Translations.sortPopularity;
        break;
      case "newest":
        sortTitle = Translations.sortNewest;
        break;
      case "name":
        sortTitle = Translations.sortName;
        break;
      case "lastPlayed":
        sortTitle = Translations.sortLastPlayed;
        break;
      case "relevance":
        sortTitle = Translations.sortedRelevance;
        break;
      default:
        sortTitle = "Default";
        break;
    }

    if (sortTitle.includes("並べ替え") || sortTitle.includes("排序")) {
      return (
        <span className="sorted-by">
          &#8226;
          <span
            className="sort-btn"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({
                activeFilter: "sortBy",
              });
            }}
          >
            {sortTitle}
          </span>
        </span>
      );
    } else {
      return (
        <span className="sorted-by">
          &#8226; {"Sorted by"}
          <span
            className="sort-btn"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({
                activeFilter: "sortBy",
              });
            }}
          >
            {sortTitle
              .split("by")
              .pop()
              .trim()}
          </span>
        </span>
      );
    }
  }

  render() {
    if (!this.props.showMoreRel) return <div className="d-none" />;

    this.resize2fit(document.querySelectorAll(".prize-container"));

    return (
      <Fragment>
        <div
          ref={this.seeMoreRef}
          className="show-more-games-modal"
          onScroll={this.handleScroll}
        >
          <div className="header">
            {this.props.route && (
              <CommonHeader
                link={this.props.route}
                showCategoryLabel={this.state.showCategoryLabel}
                detailsAvailable={this.props.detailsAvailable}
                onInfoClick={() => {
                  let params = this.props.qs;
                  params.tournamentDetails = this.props.route.get("rel");

                  let newLoc = `${
                    this.props.location.pathname
                  }?${queryString.stringify(params)}`;
                  this.props.navigateTo(newLoc);
                }}
                leftPanel={() => {
                  return (
                    <span
                      className="float-left prev-btn"
                      onClick={this.onClickPrevious}
                    >
                      <PreviousIcon width="34" />
                    </span>
                  );
                }}
                rightPanel={() => {
                  return (
                    <CloseIcon
                      width="34"
                      onClick={() => {
                        this.props.navigateTo(this.props.location.pathname);
                      }}
                    />
                  );
                }}
              />
            )}
          </div>
          <div className="content">
            {this.props.route && (
              <span className={`label`}>{this.props.route.get("name")}</span>
            )}

            <CategoryFiltersComponent
              filters={this.props.filters}
              filterRoute={this.props.filtersRoute}
              isMobile={true}
              onApplyFilterClickHandler={(filter) => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: filter.get("id"),
                });
              }}
              onSortClick={() => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: "sortBy",
                });
              }}
              onFilterClickHandler={this.props.setActiveFilter}
              showSelectedFilter={true}
              games={this.props.games}
              link={this.props.route}
              sortBy={this.props.sortBy}
              enableClearAll={this.props.enableClearAll}
              clearAllFilters={this.props.clearAllFilters}
              sortingSize={this.props.sortingSize}
            />
            <VericalList onReachEnd={this.onReachEnd}>
              {this.props.games &&
                this.props.games.getIn(["items"]) &&
                this.props.games.getIn(["items"]).map((game, key) => {
                  return (
                    <div
                      className={`col ${this.props.showMoreRel.replace(
                        ".",
                        ""
                      )}`}
                      key={key}
                    >
                      {this.renderSeeAllListItem(game, key)}
                    </div>
                  );
                })}
              {(!this.props.games ||
                (this.props.games && this.props.isLoading)) && (
                <Fragment>
                  {this.state.skeleton.map((key2) => {
                    if (
                      this.props.showMoreRel ===
                      "categories-home.recentlyplayed"
                    ) {
                      return (
                        <div className={`col`} key={key2}>
                          <CategoryType3Skeleton />
                        </div>
                      );
                    } else {
                      return (
                        <div className={`col`} key={key2}>
                          <CategoryType4Skeleton />
                        </div>
                      );
                    }
                  })}
                </Fragment>
              )}
            </VericalList>
          </div>
        </div>
        <ShowMoreFilters
          activeFilter={this.state.activeFilter}
          filters={this.props.filters}
          filtersLink={this.props.filtersRoute}
          sorts={this.props.sorts}
          games={this.props.games}
          onClose={() => {
            this.setState({
              activeFilter: null,
            });
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let qs = queryString.parse(window.location.search);
  let { showMore, sub, status, route } = qs;
  let routeDecrypt = fromJS(JSON.parse(decodeURIComponent(atob(route))));
  let filtersRoute;
  let links;
  let games;
  let tournamentPreview;
  let isLoading = true;
  let enableClearAll;
  let detailsAvailable = false;
  let subLink;
  let subGames;
  let currency = null;
  let filters;
  let sortBy;
  let sorts;
  let sortingSize = 0;

  try {
    subLink = state.pages
      .getIn(["wl-pages", 0, "content"])
      .find((contentItem) => {
        return contentItem.get("rel") === sub;
      })
      .getIn(["links", 0]);
  } catch (err) {}
  try {
    subGames = state.games.getIn([sub]);
  } catch (err) {}
  try {
    games = state.games.getIn([showMore, "data"]);
  } catch (err) {}
  try {
    isLoading = state.games.getIn([showMore, "isLoading"]);
  } catch (err) {}
  try {
    tournamentPreview = state.tournaments.getIn([showMore]);
  } catch (err) {}
  try {
    detailsAvailable = state.games.getIn([
      routeDecrypt.get("rel"),
      "route",
      "links",
    ])
      ? true
      : false;
  } catch (err) {}

  try {
    filtersRoute = routeDecrypt.getIn(["links"]).find((link) => {
      return link.get("rel").indexOf("-filters") > -1;
    });
    filtersRoute = filtersRoute.setIn(
      ["rel"],
      `show.more.${filtersRoute.getIn(["rel"])}`
    );
  } catch (err) {}

  try {
    sortBy = state.filters.getIn([filtersRoute.get("rel"), "data", "sortBy"]);
  } catch (err) {}

  try {
    links = routeDecrypt.get("links");
  } catch (err) {}

  try {
    filters = state.filters.getIn([filtersRoute.get("rel"), "data", "filters"]);
  } catch (err) {}

  try {
    let toggledSize = 0;
    for (var i = 0; filters.size > i; i++) {
      toggledSize = filters.getIn([i, "toggledCount"]);
      sortingSize = sortingSize + toggledSize;
    }
  } catch (err) {}

  try {
    sorts = state.filters.getIn([filtersRoute.get("rel"), "data", "sorts"]);
  } catch (err) {}

  if (!isEmpty(state.links.get("currency"))) {
    currency = state.links.get("currency");
  }
  try {
    enableClearAll = state.filters.getIn([
      filtersRoute.get("rel"),
      "enableClearAll",
    ]);
  } catch (err) {}

  return {
    isMobile: state.window.get("isMobile"),
    qs,
    subLink,
    subGames,
    status,
    currency: currency,
    detailsAvailable,
    showMoreRel: showMore,
    route: routeDecrypt,
    filtersRoute,
    links,
    games,
    tournamentPreview,
    isLoading,
    filters,
    sorts,
    sortBy,
    sortingSize,
    enableClearAll,
    isMultiGame: state.links.get("isMultiGame"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    setGameAction: (params) => dispatchProps.setGameAction(params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    navigateTo: (path) => ownProps.history.push(path),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    showFilters: (bol) => dispatchProps.showFilters(bol),
    setActiveFilter: (category, subCategory, filter) =>
      dispatchProps.setActiveFilter(category, subCategory, filter),
    clearAllFilters: (category) => dispatchProps.clearAllFilters(category),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ShowMoreGames);
