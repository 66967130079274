import React, {
    Component
  } from 'react';
import {
  LoadingAnimation
} from 'component/animation';
import 'component/modal/GameAction/styles.css';

  class GameAction extends Component {

    constructor(props) {
      super(props)
      this.onClickHandler = this._onClickHandler.bind(this);
      this.state = {
        demoLink: null,
        realLink: null,
        likeLink: null,
        detailsLink: null
      }
    }

    _onClickHandler(link) {
      let rel = link.get('rel');
      this.props.onClick(rel);
    }

    componentDidMount() {
      this.setLinkState(this.props.game)
    }

    setLinkState(game) {
      let demoLink
      try { demoLink = game.get('links').find(link => {return link.get('rel') === "launch.demo"}) } catch {}

      let realLink
      try { realLink = game.get('links').find(link => {return link.get('rel') === "launch.real"}) } catch {}

      let likeLink
      try { likeLink = game.get('links').find(link => {return link.get('rel') === "game.like"}) } catch {}

      let detailsLink
      try { detailsLink = game.get('links').find(link => {return link.get('rel') === "game.details"}) } catch {}

      this.setState({
        demoLink: demoLink,
        realLink: realLink,
        likeLink: likeLink,
        detailsLink: detailsLink,
      })
    }


    componentWillReceiveProps(nextProps) {
      if(nextProps.game &&
        nextProps.game !== this.props.game) {
          this.setLinkState(nextProps.game)
      }
    }

    render() {
      let isMobileLoading = (this.props.isMobile &&
        ((this.state.realLink && !this.props.realUrl) || (!this.state.realLink)) &&
        ((this.state.demoLink && !this.props.demoUrl) || (!this.state.demoLink)))

      let launchTarget = this.props.game.get('launchTarget') ? this.props.game.get('launchTarget') : "_blank"

      if(isMobileLoading) {
        return <div className="gameAction modal" role="dialog" style={{
          "display": "block"
          }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <LoadingAnimation
                      style={{
                      background: 'rgba(256, 256, 256, 0.75)'
                      }}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      return <div className="gameAction modal" role="dialog" style={{
              "display": "block"
          }}
          onClick={() => {
            this.props.onClose()
          }}
          onTouchMove={(event) => {
            event.stopPropagation();
          }}

          >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body" onClick={(event) => {
                    event.stopPropagation();
                    return false;
                  }}>

                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                  onClick={() => {
                    this.props.onClose()
                  }}
                  >
                  <span aria-hidden="true">&times;</span>
                </button>
                <img
                  className="img img-fluid"
                  src={this.props.imgSrc}
                  alt={this.props.game.get('name')}
                  />
                {
                  this.state.realLink && !this.props.isMobile && <button type="button" className="btn btn-sm btn-danger btn-block real"
                    onClick={() => this.onClickHandler(this.state.realLink)}
                    >
                    {this.state.realLink.get('name')}
                  </button>
                }
                {
                  this.state.realLink && this.props.realUrl && this.props.isMobile && <a className="btn btn-sm btn-danger btn-block real"
                    href={this.props.realUrl}

                    >
                    {this.state.realLink.get('name')}
                  </a>
                }
                {
                  this.state.demoLink && !this.props.isMobile && <button type="button" className="btn btn-sm btn-secondary btn-block demo" data-dismiss="modal"
                    onClick={() => this.onClickHandler(this.state.demoLink)}
                    >
                    {this.state.demoLink.get('name')}
                  </button>
                }
                {
                  this.state.demoLink && this.props.demoUrl && this.props.isMobile && <a className="btn btn-sm btn-secondary btn-block demo"
                    href={this.props.demoUrl}
                    target={launchTarget}
                    >
                    {this.state.demoLink.get('name')}
                  </a>
                }
                {
                  this.state.detailsLink && <button type="button" className="btn btn-sm btn-light btn-block btn-details"
                    onClick={() => this.onClickHandler(this.state.detailsLink)}
                    >
                    {this.state.detailsLink.get('name')}
                  </button>
                }
              </div>
            </div>
          </div>
        </div>

    }
  }

  export default GameAction;