import React, {
  Component
} from 'react';

import 'component/modal/ErrorMobile/styles.scss';

class ErrorMobileModal extends Component {
  render() {
    return <div className="error-mobile modal" tabIndex="-1" role="dialog" style={{
            "display": "block"
        }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {
              this.props.header && <div className="modal-header">
                {
                  this.props.header
                }
              </div>
            }
            <div className="modal-body">
              {this.props.message}
            </div>
              {
                this.props.buttons && <div className="modal-footer">
                  {
                    this.props.buttons
                  }
                </div>
              }
            </div>
        </div>
      </div>

  }
}

export default ErrorMobileModal;