import React, { Component } from "react";

import { ReactComponent as LobbyIcon } from "assets/icons/lobby.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";

import Translations from "translations";

import "./styles.scss";

class GameController extends Component {
  render() {
    return (
      <div className="game-controller">
        <div className="row nopadding">
          <div className="col-auto" />

          <div className="col" />

          {this.props.onRefreshClickHandler && (
            <div
              className="col-auto"
              onClick={this.props.onRefreshClickHandler}
              title={Translations.refresh}
            >
              <RefreshIcon />
            </div>
          )}
          {this.props.onCloseClickHandler && (
            <div
              className="col-auto"
              onClick={this.props.onCloseClickHandler}
              title={Translations.lobby}
            >
              <LobbyIcon />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GameController;
