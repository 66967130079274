import {
	ModalHistoryTypes
} from 'actions/types';
export function goBack(url) {
	return {
		type: ModalHistoryTypes.GO_BACK,
		payload: {
			url
		}
	}
}