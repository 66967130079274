import React from 'react';
import { Provider } from 'react-redux';
import {
    ConnectedRouter
} from "react-router-redux";
import configureStore from 'store';
import createHistory from "history/createBrowserHistory";

export default ({ children, initialState = {} }) => {
    return <Provider store={configureStore(initialState)}>
        <ConnectedRouter history={createHistory()}>
            { children }
        </ConnectedRouter>
    </Provider>
}
