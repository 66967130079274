import {
    LocalStorageTypes
} from 'actions/types';
import {
    fetchVerifyToken
} from 'actions/security';

export function restoreState() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type:  LocalStorageTypes.RESTORE_STATE
            })
            resolve()
        })
        .then(() => {
            return dispatch(restoreSecurity())
        })
        .then(() => {
            return dispatch(restoreUrls())
        })
        .then(() => {
            return dispatch(fetchVerifyToken())
        })
        .then(() => {
            return dispatch(restoreUser())
        })
        .then(() => {
            return dispatch(restoreContent())
        })
        .then(() => {
            return dispatch(restoreCategories())
        })
        .then(() => {
            return dispatch(restoreComplete())
        })
        .catch(error => {
            dispatch({
                type: LocalStorageTypes.ERROR_RESTORE,
                payload: error
            })
            throw error
        })
    }
}

export function restoreComplete() {
    return {
        type: LocalStorageTypes.RESTORE_STATE_COMPLETE
    }
}

export function restoreSecurity() {
    let security = localStorage.getItem('security')
    if (!security) {
        let error = {
            code: LocalStorageTypes.ERROR_NO_SECURITY,
            message: 'no stored security object'
        }
        throw error
    }

    return {
        type: LocalStorageTypes.RESTORE_SECURITY,
        payload: JSON.parse(security)
    }
}

export function restoreUser() {
    let user = localStorage.getItem('user')
    if (!user) {
        let error = {
            code: LocalStorageTypes.ERROR_NO_USER,
            message: 'no stored user object'
        }
        throw error
    }

    return {
        type: LocalStorageTypes.RESTORE_USER,
        payload: JSON.parse(user)
    }
}

export function restoreUrls() {
    let urls = localStorage.getItem('urls')
    if (!urls) {
        let error = {
            code: LocalStorageTypes.ERROR_NO_URLS,
            message: 'no stored urls object'
        }
        throw error
    }

    return {
        type: LocalStorageTypes.RESTORE_URLS,
        payload: JSON.parse(urls)
    }
}

export function restoreContent() {
    let content = localStorage.getItem('content')
    if (!content) {
        let error = {
            code: LocalStorageTypes.ERROR_NO_CONTENT,
            message: 'no stored content object'
        }
        throw error
    }

    return {
        type: LocalStorageTypes.RESTORE_CONTENT,
        payload: JSON.parse(content)
    }
}

export function restoreCategories() {
    let categories = localStorage.getItem('categories')
    if (!categories) {
        let error = {
            code: LocalStorageTypes.ERROR_NO_CATEGORIES,
            message: 'no stored categories object'
        }
        throw error
    }

    return {
        type: LocalStorageTypes.RESTORE_CATEGORIES,
        payload: JSON.parse(categories)
    }
}