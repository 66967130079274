import React, { Component } from 'react';
import {
    Route,
    Switch
  } from 'react-router-dom';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';

import Category from 'containers/Category';
import GameCategory from 'containers/GameCategory';

import 'containers/Content/styles.css';

class Content extends Component {
    render() {
        return (
            <Switch>
                <Route path={`/:locale/:sessionId/games/:page/:category`}
                    render={ props => {
                        if ( this.props.gameLink && !this.props.isMobile) {
                            return <GameCategory {...props} link={this.props.gameLink}/>
                        } else {
                            return <Category {...props} />
                        }
                    }}
                    />
                <Route exact path={`/:locale/:sessionId/games/:page/:category/:game`} component={() => {return <div>load game info</div>}} />
            </Switch>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let link;
    try {
        let pages = state.pages.get('wl-pages').find( page => {
            return page.get( 'rel' ) === 'webLobbyMain'
        } ).get( 'content' )
        let page = pages.find( page => {
            return page.get('rel') === ownProps.match.params.page
        })
        link = page.get('links').find(link => {
            return ( link.get('rel') === 'launch.real' || link.get('rel') === 'launch.demo')
        })
    } catch (err) {}

    return {
        gameLink: link,
        isMobile: state.window.get('isMobile')
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Content)