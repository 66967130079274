import React, {
  Component
} from 'react';
import 'component/icons/Menu/styles.scss';

class Menu extends Component {

	render() {
		return <div className="icon-wrapper icon-wrapper-home icon-wrapper- m-nav m-nav d-flex align-items-center"
			onClick={() => {
				if(this.props.onClick) {
					this.props.onClick()
				}
			}}>
			<span id="menu-icon" className={`${this.props.active ? 'active' : 'inactive'} m-nav`}>
				<span className="line line-1 m-nav"></span>
				<span className="line line-2 m-nav"></span>
				<span className="line line-3 m-nav"></span>
			</span>
		</div>
	}
}

export default Menu;