import React, { Component, createRef} from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'actions';
import { bindActionCreators } from 'redux';
//import { isEqual } from 'lodash';
import * as queryString from 'query-string';
import { fromJS } from 'immutable';
import { ReactComponent as PreviousIcon} from 'assets/icons/previous.svg';
import { ReactComponent as CloseIcon} from 'assets/icons/close.svg';
import GameRoundsMobile from 'containers/GameRounds/Mobile';
import GameRoundsDesktop from 'containers/GameRounds/Desktop';

import 'containers/GameRounds/styles.scss';
import { Route, Switch } from 'react-router-dom';

class GameRounds extends Component {
    constructor(props) {
		super(props);
    this.gameHistoryRef = createRef();
		this.gameHistoryRoundsRef = createRef();
		this.onClickPrevious = this._onClickPrevious.bind(this);
		this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
		this.dummyArr = [0,1,2,3,4,5];
		this.state = {
			gameRounds: null,
			isLoading: true
		};
  }

	_onClickPrevious() {
		this.props.goBack(`${this.props.location.pathname}${this.props.location.search}`)
		this.props.history.goBack();
	}

	_onCloseClickHandler() {
    this.props.navigateTo(this.props.location.pathname)
  }

	render() {
		if (!this.props.modalRel || (this.props.modalRel && this.props.modalRel.indexOf("game-rounds") < 0)) {
			return null
		}

		return<div className="game-rounds">
			<div className="header">
					<div className='row'>
						<div className="col-auto">
							<span className="float-left prev-btn"
								onClick={this.onClickPrevious}>
								<PreviousIcon width="34"/>
							</span>
						</div>
						<div className="col-auto icon-wrapper">
							<CloseIcon
								width="34"
								onClick={this.onCloseClickHandler}
							/>
						</div>
					</div>
			</div>
			<Switch>
				<Route path="/:locale/:sessionId" render={props => {
					if (this.props.isMobile) {
						return <GameRoundsMobile {...props} />
					} else {
						return <GameRoundsDesktop {...props} />
					}
				}}/>
			</Switch>
			</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	let { modalRel, link } = queryString.parse(ownProps.location.search);

	if(!modalRel || (modalRel && modalRel.indexOf("game-rounds") < 0)) {
		return {}
	}

	let gameRoundsLink = link ? fromJS(JSON.parse(link)) : undefined;
	return {
		modalRel,
		gameRoundsLink,
		locale: state.config.getIn( [ 'locale' ] ),
    isMobile: state.window.get( 'isMobile' )
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			navigateTo: ( path ) => ownProps.history.push( path ),
			goBack: url => dispatchProps.goBack(url)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(GameRounds);
