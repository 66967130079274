import Info from 'component/details/Info';
import BannerImage from 'component/details/BannerImage';
import GameButtons from 'component/details/GameButtons';

export { Info };
export { BannerImage };
export { GameButtons };

export default Object.assign({
	Info,
	BannerImage,
	GameButtons
})