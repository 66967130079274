/**
 * CHANGE FOR FEATURE PROOF REACT 17
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { fromJS } from "immutable";
import { includes, find } from "lodash";
import * as queryString from "query-string";
import { isEmpty } from "lodash";
import Translations from "translations";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down-2.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import { CommonHeader } from "component/common";
import { CategoryType8Skeleton } from "component/listItemSkeleton";
import "containers/CategoryVerticalList/styles.scss";
class CategoryVerticalList extends Component {
  constructor(props) {
    super(props);
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.seeMore = this._seeMore.bind(this);
    this.onSeeMoreCloseHandler = this._onSeeMoreCloseHandler.bind(this);
    this.state = {
      showMore: false,
      seeMore: false,
      startsIn: null,
      endsIn: null,
      skeleton: [1, 2, 3, 4],
    };
  }

  _seeMore() {
    this.setState({
      seeMore: !this.state.seeMore,
    });
  }

  _onSeeMoreCloseHandler() {
    this.props.navigateTo(this.props.location.pathname);
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    if (this.props.isMobile) {
      let params = {
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
        gameId: game.get("gameId"),
      };
      this.props.setGameAction(params);
      return;
    } else {
      let params = {
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
    }
  }

  componentDidMount() {
    let params = new URLSearchParams();
    params.append("size", 50);
    params.append("sortBy", this.props.sorting);

    if (this.props.games) {
      return;
    }

    if (this.props.filters) {
      this.props.filters.forEach((filter) => {
        filter.get("values").forEach((value) => {
          if (value.get("toggled") === true) {
            params.append(filter.get("id"), value.get("id"));
          }
        });
      });
    }
    if (this.props.link) {
      this.setState({
        isLoading: true,
      });
      this.props.fetchGames(this.props.link, params).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.link && nextProps.link !== this.props.link) {
      let params = new URLSearchParams();
      params.append("size", 50);
      params.append("sortBy", this.props.sorting);

      if (nextProps.filters) {
        nextProps.filters.forEach((filter) => {
          filter.get("values").forEach((value) => {
            if (value.get("toggled") === true) {
              params.append(filter.get("id"), value.get("id"));
            }
          });
        });
      }

      if (nextProps.link) {
        this.setState({
          isLoading: true,
        });
        this.props.fetchGames(nextProps.link, params).then(() => {
          this.setState({
            isLoading: false,
          });
        });
      }
    }
  }

  render() {
    let ComponentListItem = this.props.listItemComponent;

    return (
      <div ref="container" className="category-vertical-list">
        <div className="header">
          {this.props.link && this.props.seeMoreItems ? (
            <CommonHeader
              link={this.props.link}
              tournamentPreview={this.props.tournamentPreview}
              detailsAvailable={this.state.detailsAvailable}
            />
          ) : (
            <div className="dummy" />
          )}
        </div>
        <div
          className={`row item-container ${
            this.state.showMore ? "show-more-games" : ""
          } similar-list`}
        >
          {!this.props.link || !this.props.seeMoreItems || this.state.isLoading
            ? this.state.skeleton.map((key) => {
                return (
                  <div className="col col-item" key={key}>
                    {" "}
                    <CategoryType8Skeleton />{" "}
                  </div>
                );
              })
            : this.props.seeMoreItems &&
              this.props.seeMoreItems.map((game, key) => {
                let linkDemo = game.get("links").find((link) => {
                  return link.get("rel") === "launch.demo";
                });
                let linkReal = game.get("links").find((link) => {
                  return link.get("rel") === "launch.real";
                });
                let linkDetails = game.get("links").find((link) => {
                  return link.get("rel") === "game.details";
                });

                return (
                  <ComponentListItem
                    className="col col-item"
                    game={game}
                    linkDemo={linkDemo}
                    linkReal={linkReal}
                    linkDetails={linkDetails}
                    host={this.props.host}
                    key={key}
                    isMobile={this.props.isMobile}
                    onClick={(rel) => {
                      this.onGameItemClickHandler(game, rel);
                    }}
                    onShowActionClick={() => {
                      this.launchActions(game);
                    }}
                    currency={this.props.currency}
                    symbol={this.props.symbol}
                  />
                );
              })}
        </div>
        {!this.state.isLoading && this.props.items.size > 4 ? (
          <div className="show-more text-center">
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                this.setState({
                  showMore: !this.state.showMore,
                });
              }}
            >
              {this.state.showMore
                ? Translations.showLess
                : Translations.showMore}
              {this.state.showMore ? <ArrowUp /> : <ArrowDown />}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let games;
  let isLoading = true;
  let items = fromJS([]);
  let seeMoreItems = fromJS([]);
  let width = state.window.get("width");
  let isMobile = state.window.get("isMobile");
  let currentDisplay;
  let currency;
  let filters;
  let tournamentPreview;
  let qs = queryString.parse(window.location.search);
  let displays = [
    { name: "small", current: width < 576, col: 3, rems: [1] },
    { name: "medium", current: width >= 576 && width < 768, col: 3, rems: [1] },
    { name: "large", current: width >= 768, col: 3, rems: [1] },
  ];
  let symbols = null;
  if (!isEmpty(state.links.getIn(["symbol"]))) {
    let symbolList = state.links.getIn(["symbol"]);
    symbolList = symbolList.map((symbol) => {
      return String.fromCharCode(symbol);
    });
    symbols = symbolList.toJSON().join("");
  }

  try {
    let gamesData = state.games.getIn([ownProps.link.get("rel"), "data"]);
    let gamesFilter = gamesData.get("items").filter((item) => {
      return item.get("gameId") !== qs.gameId;
    });
    if (gamesData.get("items").size !== gamesFilter.size) {
      gamesData = gamesData.setIn(["items"], gamesFilter);
      gamesData = gamesData.setIn(
        ["totalCount"],
        gamesData.get("totalCount") -
          (gamesData.get("totalCount") - gamesFilter.size)
      );
    }
    games = gamesData;
    filters = state.filters.getIn([
      ownProps.link.get("rel").replace("games", "filter"),
      "data",
      "filters",
    ]);
    isLoading = state.games.getIn([ownProps.link.get("rel"), "isLoading"]);
    items = games.get("items");
    seeMoreItems = games.get("items");
    currentDisplay = find(displays, { current: true });
    tournamentPreview = state.tournaments.get(ownProps.link.get("rel"));

    if (
      isMobile &&
      currentDisplay &&
      seeMoreItems.size > currentDisplay.col &&
      includes(currentDisplay.rems, seeMoreItems.size % currentDisplay.col)
    ) {
      seeMoreItems = seeMoreItems.slice(
        0,
        -(seeMoreItems.size % currentDisplay.col)
      );
    }
  } catch (err) {}

  if (!isEmpty(state.links.get("currency"))) {
    currency = state.links.get("currency");
  }

  return {
    gameDefaultAction: state.links.get("gameDefaultAction")
      ? state.links.get("gameDefaultAction")
      : null,
    host: state.config.get("host"),
    height: state.window.get("height"),
    filters: filters,
    sorting: state.config.get("sorting"),
    games: games,
    seeMoreItems: seeMoreItems,
    items: items,
    isLoading: isLoading,
    isMobile: isMobile,
    currency: currency,
    symbol: symbols,
    tournamentPreview: tournamentPreview,
    isMultiGame: state.links.get("isMultiGame"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    setGameAction: (params) => dispatchProps.setGameAction(params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CategoryVerticalList);
