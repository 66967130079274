export const LocalStorageTypes = {
    RESTORE_STATE: 'RESTORE_STATE',
    RESTORE_SECURITY: 'RESTORE_SECURITY',
    RESTORE_USER: 'RESTORE_USER',
    RESTORE_URLS: 'RESTORE_URLS',
    RESTORE_CONTENT: 'RESTORE_CONTENT',
    RESTORE_CATEGORIES: 'RESTORE_CATEGORIES',
    RESTORE_STATE_COMPLETE: 'RESTORE_STATE_COMPLETE',
    ERROR_RESTORE: 'ERROR_RESTORE',
    ERROR_NO_SECURITY: 'ERROR_NO_SECURITY',
    ERROR_NO_CONFIG: 'ERROR_NO_CONFIG',
    ERROR_NO_USER: 'ERROR_NO_USER',
    ERROR_NO_URLS: 'ERROR_NO_URLS',
    ERROR_NO_CONTENT: 'ERROR_NO_CONTENT',
    ERROR_NO_CATEGORIES: 'ERROR_NO_CATEGORIES'
}