import React, { Component, Fragment } from 'react';
import Translations from 'translations';
import 'component/modal/ExitDialog/styles.scss';

class ExitDialogModal extends Component {
	constructor(props) {
		super(props);
		this.onLeaveClickHandler = this._onLeaveClickHandler.bind(this);
		this.state = {
			hideModal: false,
		}
	}

	_onLeaveClickHandler() {
    this.props.onLeaveClickHandler();
	}

	render() {
			return <div className="exit-container modal" role="dialog">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					{<Fragment>
						<div className="modal-body">
							<p>{Translations.doYouWantToLeave}</p>
						</div>
						<div className="modal-footer">
									<button type="button" className="btn" onClick={this.onLeaveClickHandler}>{Translations.leave}</button>
									<button type="button" className="btn btn-danger" onClick={() => {
										this.props.onClose()}}>{Translations.stay}</button>
						</div>
					</Fragment>}
				</div>
			</div>
		</div>
	}
}

export default ExitDialogModal;