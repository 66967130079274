import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class EsportsIcon extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
      this.setState( {
        width: nextProps.width,
        height: nextProps.height
      } )
  }

  render() {
    return <div className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height*1}px`}
                viewBox="0 0 850 850"
                style={{
                    "enableBackground" : "new 0 0 850 850"
                }}>
                <defs>
                    <mask id="esportsmask" x="0" y="0">
                    <path d="M589.8,178.7c2,2.3,3.7,4.4,5.3,6.5c8.1,10.1,13.6,18.9,16.4,26.3c1.4,3.7,2.1,7.1,2.1,10.2c0,3.5-0.9,6.7-2.7,9.4
											l-83.8,82.8h32.5l53.6-52.4c3,3.3,5.3,6.7,6.9,10l-43.6,42.6c6.2,0.5,12,1.9,17.2,4.1l37.5-36.7c2.7-6,2.2-12.6-1.3-19.7
											c-1.6-3.2-3.9-6.6-6.8-10c-2.2-2.8-4.8-5.6-7.9-8.3c1.2-1.3,2.4-2.4,3.6-3.5l41,45.1c-1.2,1-2.4,2.2-3.6,3.5
											c-15.2,14.8-30.1,29.3-44.7,43.6c1.9,2.4,3.6,5,5.2,7.8l49.4-47.9c2.7-1.8,5.7-2.6,9.1-2.4c0,0,0,0,0.1,0.1
											c2.9,0.2,6.1,1.2,9.5,2.7c8.5,4,19.2,12.8,32.1,26.3c-14.4-25.2-28.9-38.1-43.5-38.9c-2.2,0-4.4,0.1-6.6,0.6l-43.2-47.6
											c0.5-2.3,0.8-4.5,0.7-6.9c0.2-13.6-9.1-27.7-28-42.1C594.5,182,592.2,180.3,589.8,178.7 M805.9,137c0.8-2.3,1.5-4.6,2.1-6.8
											c-0.4-8.1-2.9-16.4-7.2-24.9c-21.4-13.6-41.7-12.2-60.8,4l1.4,10.7c2.2,17.5-4.3,30-19.2,37.7c-26.1,10.2-48.3,25.8-66.7,46.8
											l-15.1-3.3c-11-7.5-22.1-16.6-33.3-27.3c-2.5-2.5-5.1-5-7.7-7.7c0.3-1.6,0.5-3.3,0.5-5.1c-0.2-5.9-2.4-11.1-6.5-15.6
											c-4-4.6-8.9-7.1-14.5-7.5c-5.5-0.4-10.2,1.1-13.9,4.5c-0.2,0.1-0.3,0.2-0.3,0.4c-3.9,3.8-5.8,8.7-5.7,14.6
											c0.2,5.9,2.3,11,6.4,15.6c4.1,4.5,9,7,14.5,7.4c3.8,0.3,7.2-0.2,10.1-1.8c1.5-0.8,3-1.9,4.4-3.2c0.7-0.7,1.3-1.5,1.8-2.1
											L723,313.3c-0.5,0.2-1.1,0.7-1.8,1.3c-0.1,0.1-0.2,0.2-0.3,0.4c-3.9,3.8-5.9,8.6-5.8,14.5c0.3,5.9,2.5,11.2,6.6,15.6
											c4.1,4.5,8.9,7,14.5,7.5c5.7,0.4,10.4-1.2,14.4-5c0.7-0.7,1.3-1.5,1.8-2.1c2.6-3.4,3.9-7.5,3.8-12.2c-0.2-5.9-2.4-11.1-6.5-15.6
											c-4-4.5-8.9-7.1-14.6-7.7c-1.8-0.2-3.4-0.1-5,0.1c-12.8-15.2-23.2-30-31.2-44.5l-2.1-15.6c4.1-3.5,8-7.1,11.7-10.8
											c2.6-2.7,5.1-5.4,7.6-8c3.6-4.3,7.1-8.7,10.4-13.1c1.9-3,3.8-5.9,5.7-8.7c3.4-5.6,6.7-11.4,9.7-17.3c1.3-2.7,2.5-5.3,3.6-8
											c0.1-0.2,0.2-0.3,0.2-0.4c0.4-0.8,0.9-1.6,1.4-2.3c0-0.3,0.1-0.5,0.1-0.5c2.6-5.5,5.7-9.9,9.4-13.2c7-5.9,15.9-8.1,26.8-6.6
											l10.3,1.5c1-1.4,2-2.7,2.9-4C801.5,151.6,804.6,144.4,805.9,137 M784.3,127.9c2.4-2.4,3.9-5.1,4.4-8c0.2-1.3,0.3-2.4,0.3-3.2
											l3.8-3.9c6.4,13.7,5.2,27.5-3.5,41.3l-16-22.1c0.9,0.1,1.9,0.1,3.2,0C779.3,131.6,781.9,130.2,784.3,127.9 M711.6,224.5
											l-12.8-19.6l11.6-11.2l11.8,17.6C719,215.8,715.4,220.2,711.6,224.5 M679,224.4l12.4-12.3l13,20.4c-4.6,4.8-9.4,9.4-14.5,13.9
											l-13.3-19.8c0.7-0.7,1.4-1.4,2.1-1.8C678.7,224.5,678.9,224.4,679,224.4 M742.7,176c-0.4,0.8-0.8,1.5-1.1,2.1l-6.3-9l11.1-10.7
											l4.9,6.4C748.1,167.8,745.2,171.5,742.7,176 M729.9,174.5l7.8,11.7c-3,5.8-6.3,11.4-9.9,16.9l-10.8-15.9L729.9,174.5
											M593.4,171.3c-0.5,0.6-1,1.2-1.5,1.7c-3.3,3.2-7.3,4.6-12.1,4.2c-4.8-0.4-8.9-2.6-12.4-6.3c-3.4-3.8-5.3-8.2-5.5-13.3
											c-0.1-3.6,0.6-6.7,2.4-9.2c0.6,4.7,3.7,7.4,9.1,8.3c11.7,0.9,18.8,4.9,21.3,12C594.4,169.5,594,170.4,593.4,171.3 M720.9,320.5
											c0.5,4.6,3.6,7.3,9.1,8.3c11.6,0.9,18.7,4.9,21.3,12c-0.4,0.8-0.9,1.7-1.5,2.6c-0.5,0.6-1,1.2-1.5,1.7
											c-3.3,3.2-7.4,4.5-12.2,4.1c-4.8-0.3-8.9-2.4-12.2-6.1c-3.5-3.9-5.4-8.3-5.5-13.3C718.4,326.1,719.2,323,720.9,320.5
											M537.6,552.7c-5.6-26.4-23.9-40.3-54.8-41.6l57.7,56.3C539.3,562.9,538.4,558,537.6,552.7 M362.8,511.2c-0.8,0-1.6,0.1-2.4,0.1
											c-28.3,2.5-45.1,16.3-50.4,41.4c-0.5,3.3-1,6.5-1.6,9.5c-0.2,0.9-0.4,1.8-0.5,2.7L362.8,511.2 M440.9,517.6L440.9,517.6
											L440.9,517.6L440.9,517.6 M236.1,623.4c-5.2,0-10.4-0.6-15.7-1.7l-51.9,51.2c-4.5,7.9-7.9,16-10.3,24.3l-16.6,55.4l54.3-14
											c1.7-0.5,3.4-1,5.2-1.3c6.3-2,12.6-4.6,18.8-7.8l203-196.7l207.6,201.1c6.2,3.2,12.5,5.9,18.8,7.8c1.8,0.3,3.5,0.8,5.2,1.3
											l54.3,14l-16.6-55.4c-2.4-8.3-5.9-16.4-10.3-24.3L625.8,622c-1,0.2-2.1,0.4-3.1,0.5c-3.7,0.6-7.4,0.9-11.1,0.9
											c-5.4,0-10.6-0.6-15.9-1.8l66.3,64.8l3.6,20.2L465.6,511h-21L639,701.4c11.6,11.4,21,16.4,28.3,15l25.7,25.3l-58.7-14.8L425,523
											h0.1L413,511h-32.8L184.6,702.2l3.6-20.2l61.2-59.8c-0.9,0.1-1.8,0.3-2.7,0.4C243.3,623.1,239.7,623.4,236.1,623.4 M182.9,712
											c7.3,1.4,16.7-3.6,28.3-15L401,511.5l15.7,15.2L215.9,722.4l-58.7,14.8L182.9,712 M257.6,184c-2.4,1.6-4.6,3.3-6.8,5.1
											c-18.8,14.5-28.1,28.5-28,42.1c0,2.3,0.2,4.6,0.7,6.9l-43.2,47.6c-2.2-0.4-4.4-0.6-6.6-0.6c-14.7,0.7-29.2,13.7-43.5,38.9
											c12.9-13.5,23.6-22.2,32.1-26.3c3.4-1.6,6.6-2.5,9.5-2.7c0.1-0.1,0.1-0.1,0.1-0.1c3.4-0.2,6.4,0.5,9.1,2.4l49.9,48.4
											c1.4-3,3-5.8,4.7-8.4c-14.6-14.3-29.5-28.8-44.7-43.5c-1.2-1.3-2.4-2.4-3.6-3.5l41-45.1c1.2,1,2.4,2.2,3.6,3.5
											c-3,2.8-5.7,5.5-7.9,8.3c-2.9,3.4-5.2,6.8-6.8,10c-3.5,7.1-4,13.6-1.3,19.7l35.7,34.9c4.6-2.8,9.8-4.7,15.5-5.8l-40-39.1
											c1.7-3.3,4-6.6,6.9-10l49.1,48h32.6l-79.4-78.3c-1.9-2.7-2.8-5.8-2.7-9.4c0-3.1,0.8-6.5,2.1-10.2c2.8-7.4,8.3-16.2,16.4-26.3
											C253.8,188.4,255.6,186.2,257.6,184 M46.6,110.6c-4.4,8.6-6.8,16.9-7.2,24.9c0.6,2.2,1.3,4.5,2.1,6.8c1.3,7.4,4.3,14.6,9.1,21.6
											c0.9,1.3,1.8,2.6,2.9,4l10.3-1.5c10.9-1.5,19.9,0.7,26.8,6.6c3.7,3.3,6.9,7.7,9.4,13.2c0,0,0.1,0.2,0.1,0.5
											c0.5,0.7,1,1.5,1.4,2.3c0,0.1,0.1,0.2,0.2,0.4c1.1,2.7,2.3,5.4,3.6,8c3,5.9,6.2,11.7,9.7,17.3c1.9,2.9,3.8,5.8,5.7,8.7
											c3.3,4.5,6.8,8.9,10.4,13.1c2.5,2.7,5,5.3,7.6,8c3.7,3.6,7.6,7.2,11.7,10.8l-2.1,15.6c-8,14.5-18.4,29.3-31.2,44.5
											c-1.6-0.3-3.2-0.3-5-0.1c-5.7,0.6-10.6,3.1-14.6,7.7c-4.1,4.4-6.3,9.6-6.5,15.6c0,4.7,1.2,8.8,3.8,12.2c0.4,0.7,1,1.4,1.8,2.1
											c3.9,3.8,8.7,5.5,14.4,5c5.6-0.5,10.4-3,14.5-7.5c4-4.5,6.2-9.7,6.6-15.6c0.1-5.9-1.8-10.7-5.8-14.5c0-0.1-0.1-0.3-0.3-0.4
											c-0.6-0.6-1.2-1-1.8-1.3l127.1-139.9c0.5,0.7,1.1,1.4,1.8,2.1c1.4,1.3,2.8,2.3,4.4,3.2c3,1.5,6.3,2.1,10.1,1.8
											c5.6-0.5,10.4-3,14.5-7.4c4.1-4.5,6.2-9.7,6.4-15.6c0.1-5.9-1.7-10.8-5.7-14.6c0-0.1-0.1-0.3-0.3-0.4c-3.8-3.4-8.4-4.9-13.9-4.5
											c-5.7,0.4-10.5,3-14.5,7.5c-4.1,4.4-6.3,9.6-6.5,15.6c0,1.8,0.2,3.5,0.5,5.1c-2.6,2.7-5.2,5.2-7.7,7.7
											c-11.2,10.7-22.3,19.8-33.3,27.3l-15.1,3.3c-18.4-20.9-40.7-36.5-66.7-46.8c-15-7.7-21.4-20.2-19.2-37.7l1.4-10.7
											C88.4,98.3,68.1,97,46.6,110.6 M54.6,118.1l3.8,3.9c0,0.9,0,2,0.3,3.2c0.5,2.9,2,5.6,4.4,8c2.4,2.3,5,3.6,7.9,4.1
											c1.2,0.1,2.3,0.1,3.2,0l-16,22.1C49.4,145.6,48.2,131.8,54.6,118.1 M273.9,161.9c5.5-0.8,8.5-3.6,9.1-8.3
											c1.7,2.6,2.5,5.6,2.4,9.2c-0.2,5.1-2,9.5-5.5,13.3c-3.4,3.8-7.6,5.9-12.4,6.3c-4.8,0.4-8.8-1-12.1-4.2c-0.5-0.5-1-1.1-1.5-1.7
											c-0.6-0.9-1.1-1.8-1.4-2.7C255.2,166.9,262.3,162.8,273.9,161.9 M96.1,170.2l4.9-6.4l11.1,10.7l-6.3,9c-0.3-0.6-0.7-1.3-1.1-2.1
											C102.2,176.8,99.3,173.1,96.1,170.2 M119.6,208.3c-3.6-5.5-6.9-11.1-9.9-16.9l7.8-11.7l12.8,12.6L119.6,208.3 M137.1,198.9
											l11.6,11.2l-12.8,19.6c-3.8-4.3-7.4-8.7-10.5-13.3L137.1,198.9 M170.8,231.8l-13.3,19.8c-5.1-4.5-10-9.1-14.5-13.9l13-20.4
											l12.4,12.3c0.1,0,0.3,0.1,0.4,0.3C169.4,230.5,170.1,231.1,170.8,231.8 M97.5,348.7c-0.6-0.9-1.1-1.7-1.5-2.6
											c2.7-7.1,9.8-11.1,21.3-12c5.6-0.9,8.6-3.7,9.1-8.3c1.7,2.6,2.5,5.6,2.4,9.1c-0.1,5-1.9,9.5-5.5,13.3c-3.4,3.7-7.5,5.8-12.2,6.1
											c-4.8,0.5-8.9-0.9-12.2-4.1C98.5,349.8,98,349.3,97.5,348.7 M613.8,358.1c-8.6-19.9-23.1-30-43.7-30.5H283.4
											c-20.6,0.4-35.2,10.6-43.7,30.5l-40.4,165.7c-2.3,9.2-3.4,17.9-3.4,26c0,21.6,8.1,38.5,24.2,50.8c8.5,4.8,16.7,7.2,24.8,7.2
											c14.9,0,29.2-8.4,42.6-25.2c5.7-9.2,9.5-23,11.5-41.2c4.2-25.7,18.5-38.8,42.8-39.2h170.1c24.2,0.4,38.5,13.4,42.7,39.2
											c2,18.2,5.9,31.9,11.5,41.2c13.4,16.7,27.6,25.1,42.6,25.2c8.1,0,16.3-2.5,24.9-7.2c16.1-12.4,24.1-29.4,24.1-51
											c0-8-1.1-16.6-3.3-25.9L613.8,358.1 M547,358c5.1,0,9.5,1.8,13.1,5.4c3.7,3.6,5.5,7.9,5.5,12.9c0,5.1-1.8,9.4-5.5,13
											c-3.6,3.5-8,5.3-13.1,5.3c-5.2,0-9.6-1.8-13.3-5.3c-3.6-3.6-5.4-7.9-5.4-13c0-5,1.8-9.3,5.4-12.9C537.4,359.7,541.8,358,547,358
											M257.3,391.4h34.2V358h33.6v33.5h34.2v33h-34.2V458h-33.6v-33.6h-34.2V391.4 M580.6,390.9c5.1,0,9.5,1.8,13.2,5.4
											c3.6,3.6,5.5,7.9,5.5,12.9c0,5.1-1.8,9.4-5.5,13c-3.7,3.5-8.1,5.3-13.2,5.3c-5.2,0-9.6-1.8-13.3-5.3c-3.6-3.6-5.4-7.9-5.4-13
											c0-5,1.8-9.3,5.4-12.9C571,392.7,575.5,390.9,580.6,390.9 M560.1,429.3c3.7,3.6,5.5,7.9,5.5,12.9c0,5.1-1.8,9.4-5.5,13
											c-3.6,3.5-8,5.3-13.1,5.3c-5.2,0-9.6-1.8-13.3-5.3c-3.6-3.6-5.4-7.9-5.4-13c0-5,1.8-9.3,5.4-12.9c3.7-3.6,8.1-5.4,13.3-5.4
											C552.1,423.9,556.5,425.7,560.1,429.3 M513.2,390.9c5.2,0,9.6,1.8,13.3,5.4s5.5,7.9,5.5,12.9c0,5.1-1.8,9.4-5.5,13
											c-3.6,3.5-8.1,5.3-13.3,5.3c-5.1,0-9.5-1.8-13.1-5.3c-3.7-3.6-5.5-7.9-5.5-13c0-5,1.8-9.3,5.5-12.9
											C503.7,392.7,508.1,390.9,513.2,390.9z" fill="white"/>
                    </mask>
                </defs>
                <rect x="0" y="0" width="850" height="850"
                    mask="url('#esportsmask')"
                    fill="url('#categoryNormal')"
                    />
            </svg>
            {
                this.props.label && <div
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default EsportsIcon;