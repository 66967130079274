import React, { Component } from 'react';
import bannerDefault from 'assets/images/bannerDefault.png';
import 'component/listItemSkeleton/CategoryType8Skeleton/styles.scss';

class CategoryType8Skeleton extends Component {
  render() {
      return <div
          className={`category-type-8-skeleton ${this.props.placeholderType}`}>
          <div>
          <div className="img-holder">
            <img src={bannerDefault} className="icon" alt="preloading"></img>
          </div>
            <div className="details">
              <div className="name"><div className="dummy"></div></div>
              <div className="provider"><div className="dummy"></div></div>
              <div className="game-type"><div className="dummy"></div></div>
            </div>
          </div>
      </div>
  }
}

export default CategoryType8Skeleton;