import React, { Component, Fragment } from 'react';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
	fromJS
} from "immutable";
import {
    ActionCreator
} from 'actions';
import * as queryString from 'query-string';
import Translations from 'translations';
import {
    isEmpty,
    isEqual
  } from 'lodash';
import {
	CommonHeaderDesktop
} from 'component/common';
import { ReactComponent as PreviousIcon} from 'assets/icons/previous.svg';
import { ReactComponent as CloseIcon} from 'assets/icons/close.svg';
import LeaderBoard from 'containers/LeaderBoard';
import bannerDefault from 'assets/images/tournamentDefault.png';

import 'containers/TournamentLeaderboard/styles.scss';

class TournamentLeaderboard extends Component {
    constructor(props) {
        super(props);
        this.onClickPrevious = this._onClickPrevious.bind(this);
        this.handleScroll = this._handleScroll.bind(this);
        this.state = {
            showCategoryLabel: false,
			result: null,
            bannerLoaded: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if(!isEqual(this.props.resultsRoute, nextProps.resultsRoute)) {
            if(nextProps.resultsRoute) {
                this.props.fetchTournamentResults(nextProps.resultsRoute)
                .then(resp => {
                    let imResp = fromJS(resp)

                    this.setState({
                        result: imResp
                    })

                    return imResp
                })
                .then(resp => {
                    this.props.setTournamentResults(resp)
                    return resp
                })
            } else {
                this.setState({
                    result: null,
                    bannerLoaded: null

                })
            }
        }

    }

    _handleScroll(event){
		const { scrollTop } = event.currentTarget;
		if(scrollTop !== 0){
			this.setState({ showCategoryLabel: true })
		} else {
			this.setState({ showCategoryLabel: false })
		}
	}

    _onClickPrevious() {
        this.props.goBack(`${this.props.location.pathname}${this.props.location.search}`)
        this.props.history.goBack();
      }

    render() {
        if (!this.props.showTournamentResultsRel)
			return null

            return <div className="tournament-leaderboard"
            onScroll={this.handleScroll}>
		    {
                this.props.resultsRoute && <div className="header"><CommonHeaderDesktop
                        link = {this.props.resultsRoute}
                        showCategoryLabel = {this.state.showCategoryLabel}
                        tournamentComponent={true}
                        detailsAvailable = {true}
                        leftPanel = {() => {
                            return <span className="float-left prev-btn"
                                onClick={this.onClickPrevious}>
                                <PreviousIcon
                                    width="34"
                                    /></span>
                        }}
                        rightPanel = {() => {
                            return <CloseIcon
                                width="34"
                                onClick={() => {
                                    this.props.navigateTo( this.props.location.pathname )
                                }}
                                />
                            }}
                        />
                </div>
            }
            {
                <div className={`banner-holder ${this.props.isMobile ? 'mobile' : 'desktop'}`}>
                <img
                    className={`banner-image ${this.state.bannerLoaded ? 'd-none' : ''}`}
                    src={bannerDefault}
                    alt=""
                    />
                <img className={`banner-image ${this.state.bannerLoaded ? '' : 'd-none'}`}
                    alt={bannerDefault}
                    src={`${this.props.image}&theme=dark`}
                    onLoad={() => {
                        this.setState({
                            bannerLoaded : true,
                        });
                    }}
                    />
                </div>
            }

            {
                !this.state.result && <Fragment>
                    <div className='title'>
                        <span className='dummy'>XXXXXXXX</span>
                    </div>
                    <div className='row providers'>
                        <div className='col'>
                            <span className='dummy'>XXXXXXXXXXXX</span>
                        </div>
                    </div>
                    <div className='row providers'>
                        <div className='col'>
                            <span className='dummy'>XXXXXXXXXXX</span>&nbsp;
                        </div>
                    </div>
                </Fragment>
            }

            {
                this.state.result && <Fragment>
                    <div className='title'>
                        { this.props.resultsRoute.get('name') }
                    </div>
                    <div className='row providers'>
                        <div className='col'>{ Translations.tournament } { Translations.results }</div>
                    </div>
                    <div className='row providers'>
                        <div className='col'>{ Translations.yourTotalWin } {this.props.symbol}{this.state.result.get('totalWin')} </div>
                    </div>
                </Fragment>
            }

            {
                this.props.resultsRoute && <LeaderBoard
                    route={this.props.resultsRoute}
                    />
            }
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    let qs = queryString.parse(window.location.search)
	let { showTournamentResults, images, route, providers } = qs;
    let resultsRoute
    let image
    let providersArr;
    let symbols;


    try {
        providersArr = JSON.parse(providers)
    } catch(err) {}

    try {
		resultsRoute = fromJS(JSON.parse(route))
	} catch(err) {}

    if(!state.security.get('sessionId') || !showTournamentResults) {
		return {}
	}
    if(!isEmpty(state.user.getIn(['symbol']))) {
		let symbolList = state.user.getIn(['symbol'])
		symbolList = symbolList.map(symbol => {
		  return String.fromCharCode(symbol);
		})
		symbols = symbolList.toJSON().join('');
	}
    try {
		let qsimages =  fromJS(JSON.parse(images));
		image = qsimages.get("tournaments")
	} catch(err) {};
    return {
        showTournamentResultsRel: showTournamentResults,
        isMobile: state.window.get('isMobile'),
        displayName: state.user.get('displayName'),
        pages: state.urls.get('pages'),
        resultsRoute,
        providers: providersArr,
        image,
        symbol: symbols
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
        navigateTo: ( path ) => ownProps.history.push( path ),
        goBack: url => dispatchProps.goBack(url),
        fetchTournamentResults: (resultsRoute) => dispatchProps.fetchTournamentResults(resultsRoute),
        setTournamentResults: (data) => dispatchProps.setTournamentResults(data)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TournamentLeaderboard)