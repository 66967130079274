import React, {
	Component,
} from 'react';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';
import { ReactComponent as CloseIcon} from 'assets/icons/close.svg';

import 'containers/BetHistory/styles.scss';

class BetHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			betHistoryUrl: null,
			isLoading: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.showBetHistory !== this.props.showBetHistory) {

			nextProps.showBetHistory ? this.props.showModal("BET_HISTORY") : this.props.hideModal("BET_HISTORY")

			this.setState({
				betHistoryUrl: null,
				isLoading: true
			})

			this.props.fetchBetHistoryUrl()
			.then(response => {
				this.setState({
					betHistoryUrl: response.url,
					isLoading: false
				})
			})
		}
	}

	render() {
		if (!this.props.betHistoryLink || !this.props.showBetHistory) {
			return null
		}

		return <div className="bet-history modal" style={{
			height: `${this.props.height}px`,
			overflow: "inherit"
			}}>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-body">
						{
							this.state.isLoading && <div>Loading...</div>
						}
						{
							!this.state.isLoading && this.state.betHistoryUrl && <iframe title="Bet History" scrolling="no" id="lobbyframe" frameBorder="0" src={this.state.betHistoryUrl} height={`${this.props.height}px`} width="100%"></iframe>
						}
					</div>
				</div>
			</div>
			<div className="closeButton"
				onClick={this.props.hideBetHistory}>
				<CloseIcon
					width="34"
					/>
			</div>
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	let betHistoryLink = state.profile.get('links').find( link => {
		return link.get( 'rel' ) === 'service.url';
	});
	return {
		height: state.window.get('height'),
		isMobile: state.window.get( 'isMobile' ),
		showBetHistory: state.application.get('showBetHistory'),
		betHistoryLink: betHistoryLink,
		betHistory: state.betHistory.get('history'),
		loadedItems: state.betHistory.get('loadedItems')
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			fetchBetHistory:(link, params) => dispatchProps.fetchBetHistory(link, params),
			fetchBetHistoryUrl:() => dispatchProps.fetchBetHistoryUrl(),
			showModal: (value) => dispatchProps.showModal(value),
			hideModal: (value) => dispatchProps.hideModal(value),
			hideBetHistory: () => dispatchProps.hideBetHistory(),
		})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BetHistory)