import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import { fromJS } from "immutable";
import { ReactComponent as FullscreenIcon } from "assets/icons/fullscreen-alt-svgrepo-com.svg";
import { ReactComponent as ExitFullscreenIcon } from "assets/icons/fullscreen-exit-alt-svgrepo-com.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";
import { ReactComponent as CashierIcon } from "assets/icons/cashier.svg";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import Translations from "translations";

import "containers/Game/styles.scss";

const GAME = "GAME";

class Game extends Component {
  constructor(props) {
    super(props);
    this.gameContainer = createRef();
    this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
    this.onFullscreenHandler = this._onFullscreenHandler.bind(this);
    this.state = {
      gameUrl: null,
      details: null,
      relatedLinks: null,
      real: null,
      realLink: null,
      demoLink: null,
    };
  }

  componentDidMount() {
    if (this.props.gameId && this.props.isMobile & this.props.playType) {
      this.props.showModal(GAME);
      this.loadGame(this.props.gameId, this.props.playType);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.gameId !== this.props.gameId ||
        nextProps.playType !== this.props.playType) &&
      nextProps.isMobile &&
      nextProps.gameId &&
      nextProps.playType
    ) {
      this.props.showModal(GAME);
      this.loadGame(nextProps.gameId, nextProps.playType);
    }
  }

  _onFullscreenHandler(bol) {
    let elem = this.gameContainer.current;
    this.props.setGameFullscreen(bol);
    if (bol) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } else {
      if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  _onCloseClickHandler() {
    this.props.fetchBalance();
    this.props.onGameClose({
      location: this.props.location,
    });
    let search = queryString.parse(this.props.location.search);
    delete search.gameId;
    delete search.playType;
    delete search.nav;
    this.props.navigateTo(`${this.props.location.pathname}`);
  }

  loadGame(gameId, playType) {
    this.setState({
      realLink: null,
      relatedLinks: null,
      demoLink: null,
    });
    this.retrieveGameDetailsUrl(gameId)
      .then((link) => {
        return this.retrieveGameDetails(link);
      })
      .then((details) => {
        this.setState({
          details: details,
        });

        let playtypeLink = details.get("links").find((link) => {
          return link.get("rel") === playType;
        });

        /**
         *
         * check if demo mode on keyops
         *
         */

        let realLink = details.get("links").find((link) => {
          return link.get("rel") === "launch.real";
        });
        let demoLink = details.get("links").find((link) => {
          return link.get("rel") === "launch.demo";
        });

        let real = false;
        if (typeof realLink === "undefined") real = false;
        else real = true;

        this.setState({
          real: real,
          realLink: realLink,
          demoLink: demoLink,
        });

        /**
         *
         * end
         *
         */

        if (playType === "launch.real" || playType === "launch.demo") {
          this.retrieveGameUrl(playtypeLink);
        }
        return details;
      })
      .then((details) => {
        let relatedLink;
        try {
          relatedLink = details.get("links").find((link) => {
            return link.get("rel") === "related.links";
          });
          let { nav } = queryString.parse(this.props.location.search);
          let params = {
            nav,
          };
          return this.props
            .fetchRelatedLinks(relatedLink, params)
            .then((response) => {
              this.setState({
                relatedLinks: fromJS(response),
              });
            });
        } catch (err) {}
      })
      .then(() => {
        let params = {
          location: this.props.location,
          playType,
          url: this.state.gameUrl,
          hideSplash: this.props.hideSplash,
          isMobile: this.props.isMobile, //TODO: FORCE TO LAUNCH ON SELF
          appId: this.props.appId,
          target: this.props.gameLaunchTarget,
        };
        this.props.onGameLaunch(params);
      });
  }

  retrieveGameDetailsUrl(gameId) {
    return this.props.fetchGameDetailsUrl(gameId).then((link) => {
      return fromJS(link);
    });
  }

  retrieveGameDetails(link) {
    return this.props.fetchGameDetails(link).then(({ payload }) => {
      return fromJS(payload.data);
    });
  }

  retrieveGameUrl(link) {
    this.setState({
      gameUrl: null,
    });

    let params = new URLSearchParams();

    switch (this.props.gameLaunchTarget) {
      case "_top":
      case "_self":
      case "_parent":
        let urlParams = new URLSearchParams(this.props.location.search);
        let redirectParams = new URLSearchParams();
        let url = `${this.props.location.pathname}?${urlParams.toString()}`;
        redirectParams.set("redirect", btoa(encodeURIComponent(url)));
        params.returnUrl = `${window.location.origin}/${
          this.props.match.params.locale
        }/${
          this.props.match.params.sessionId
        }/exit?${redirectParams.toString()}`;
        break;
      default:
        params.returnUrl = `${window.location.origin}/${
          this.props.match.params.locale
        }/${this.props.match.params.sessionId}/exit`;
        break;
    }

    return this.props.fetchGameUrl(link, params).then((response) => {
      this.setState({
        gameUrl: `${response.payload.data.url}${
          this.props.hideSplash ? "&hideSplash=true" : ""
        }`,
      });
      return response;
    });
  }

  render() {
    if (!this.props.gameId || !this.props.isMobile) return null;

    return (
      <div className="game-mobile" ref={this.gameContainer}>
        <div className="row game-controller">
          <div
            className="col-auto"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <PreviousIcon />
          </div>

          <div className="col">&nbsp;</div>

          {this.props.showCashier && (
            <div
              className="col-auto"
              onClick={this.props.broadcastCashier}
              title={Translations.depositWithdraw}
            >
              <CashierIcon />
            </div>
          )}
          {this.props.showSwitchDevice && (
            <div
              className="col-auto"
              onClick={this.props.broadcastSwitchDevice}
              title={Translations.switchDevice}
            >
              <TransferIcon />
            </div>
          )}

          {this.props.gameFullscreen && (
            <div
              className="col-auto"
              onClick={() => {
                this.onFullscreenHandler(!this.props.gameFullscreen);
              }}
              title={Translations.minimize}
            >
              <ExitFullscreenIcon />
            </div>
          )}

          {!this.props.gameFullscreen && (
            <div
              className="col-auto"
              onClick={() => {
                this.onFullscreenHandler(!this.props.gameFullscreen);
              }}
              title={Translations.maximize}
            >
              <FullscreenIcon />
            </div>
          )}

          <div
            className="col-auto"
            onClick={this.onCloseClickHandler}
            title={Translations.close}
          >
            <CloseIcon />
          </div>
        </div>

        {this.state.gameUrl && (
          <iframe
            title="Game"
            className="game-frame"
            src={this.state.gameUrl}
            frameBorder="0"
            allowFullScreen={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let sessionId = state.security.get("sessionId");
  let isMobile = state.window.get("isMobile");
  //app not yet initialized
  if (!sessionId && !isMobile) {
    return {};
  }
  let { gameId, playType, nav } = queryString.parse(ownProps.location.search);
  let isRound = false;
  let showCashier;
  let showSwitchDevice;
  let gameLaunchTarget;

  try {
    gameLaunchTarget = state.links.get("gameLaunchTarget");
  } catch (err) {}

  try {
    showCashier = state.profile.get("showCashier");
  } catch (err) {}
  try {
    showSwitchDevice = state.profile.get("showSwitchDevice");
  } catch (err) {}

  try {
    if (
      nav.indexOf("home.recentlyplayed") > -1 ||
      nav.indexOf("play-it-again") > -1
    ) {
      isRound = true;
    }
  } catch (err) {}

  return {
    isMobile,
    isRound,
    playType:
      playType === "launch.demo" || playType === "launch.real"
        ? playType
        : null,
    gameId,
    showCashier,
    showSwitchDevice,
    gameLaunchTarget,
    gameFullscreen: state.config.get("gameFullscreen"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    navigateTo: (path) => ownProps.history.push(`${path}`),
    onGameLaunch: (params) => dispatchProps.onGameLaunch(params),
    onGameClose: (params) => dispatchProps.onGameClose(params),
    setGameFullscreen: (bol) => dispatchProps.setGameFullscreen(bol),
    fetchBalance: () => dispatchProps.fetchBalance(),
    fetchGameDetailsUrl: (gameId) => dispatchProps.fetchGameDetailsUrl(gameId),
    fetchGameDetails: (link, params) =>
      dispatchProps.fetchGameDetails(link, params),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    fetchRelatedLinks: (route, params) =>
      dispatchProps.fetchRelatedLinks(route, params),
    broadcastCashier: () => dispatchProps.broadcastCashier(),
    broadcastSwitchDevice: () => dispatchProps.broadcastSwitchDevice(),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Game);
