import { CategoryTypes } from "actions/types";
import axios from "axios";
import { fromJS } from "immutable";
import { serverError } from "actions/error";

export function fetchGameTypes(route) {
  return (dispatch, getState) => {
    try {
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var url = `${href}`;
      var method = route.get("method");
    } catch (error) {
      throw error;
    }
    dispatch({
      type: CategoryTypes.FETCH_GAME_TYPES,
      payload: {
        route: route,
        method: method,
        headers: headers,
        url: url,
      },
    });

    return axios({
      method,
      headers,
      url,
    })
      .then(({ data }) => {
        dispatch(
          setGameTypes(
            fromJS(
              Object.assign({
                route,
                data,
              })
            )
          )
        );

        return data;
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setGameTypes(gameTypes) {
  return {
    type: CategoryTypes.SET_GAME_TYPES,
    payload: {
      category: gameTypes,
    },
  };
}

export function fetchRecommendedLinks(route) {
  return (dispatch, getState) => {
    try {
      var host = getState().config.get("host");
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var url = `${host}${href}`;
      var method = route.get("method");
    } catch (error) {
      throw error;
    }
    dispatch({
      type: CategoryTypes.FETCH_RECOMMENDED_LINKS,
      payload: {
        route: route,
        method: method,
        headers: headers,
        url: url,
      },
    });

    return axios({
      method,
      headers,
      url,
    })
      .then(({ data }) => {
        return dispatch(
          setRecommendedLinks(
            fromJS(
              Object.assign({
                route,
                data,
              })
            )
          )
        );
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setRecommendedLinks(payload) {
  return {
    type: CategoryTypes.SET_RECOMMENDED_LINKS,
    payload: payload,
  };
}

export function setSorting(sorting) {
  return {
    type: CategoryTypes.SET_SORTING,
    payload: {
      sorting,
    },
  };
}

export function setActiveFilter(route, subCategory, filter) {
  return {
    type: CategoryTypes.TOGGLE_FILTER,
    payload: {
      category: route.get("rel"),
      route,
      subCategory,
      filter,
    },
  };
}

export function setCategorySorting(route, sorting) {
  return {
    type: CategoryTypes.SET_CATEGORY_SORTING,
    payload: {
      category: route.get("rel"),
      route,
      sorting,
    },
  };
}

export function clearFilterByCategory(category, subCategory) {
  return {
    type: CategoryTypes.CLEAR_FILTER_BY_CATEGORY,
    payload: {
      category: category,
      subCategory: subCategory,
    },
  };
}

export function clearAllFilters(category) {
  return {
    type: CategoryTypes.CLEAR_ALL_FILTERS,
    payload: {
      category: category,
    },
  };
}

export function showFilters(bol) {
  return {
    type: CategoryTypes.SHOW_FILTERS,
    payload: {
      filtersVisible: bol,
    },
  };
}

export function fetchFilters(route, params) {
  return (dispatch, getState) => {
    try {
      var host = getState().config.get("host");
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var url = `${host}${href}`;
      var method = route.get("method");
    } catch (error) {
      throw error;
    }

    dispatch({
      type: CategoryTypes.FETCH_FILTERS,
      payload: {
        route: route,
        method: method,
        headers: headers,
        url: url,
        params: params,
      },
    });

    return axios({
      method,
      headers,
      url,
      params,
    })
      .then(({ data }) => {
        dispatch(
          setFilters(
            Object.assign({
              route,
              data,
            })
          )
        );
        return data;
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setFilters(payload) {
  return {
    type: CategoryTypes.SET_FILTERS,
    payload: payload,
  };
}

export function clearGames(route) {
  return (dispatch, getState) => {
    dispatch({
      type: CategoryTypes.CLEAR_GAMES,
      payload: route,
    });
  };
}

export function fetchGames(route, params) {
  return (dispatch, getState) => {
    try {
      var host = getState().config.get("host");
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var url = `${host}${href}`;
      var method = route.get("method");
    } catch (error) {
      throw error;
    }

    dispatch({
      type: CategoryTypes.FETCH_GAMES,
      payload: fromJS({
        route: route,
        params: params,
        url: url,
      }),
    });

    return axios({
      headers,
      method,
      params,
      url,
    })
      .then(({ data }) => {
        dispatch(
          setGames(
            fromJS(
              Object.assign({
                route,
                data,
              })
            )
          )
        );
        return data;
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setGames(payload) {
  return {
    type: CategoryTypes.SET_GAMES,
    payload: payload,
  };
}

export function fetchCategory(link) {
  return (dispatch, getState) => {
    let url = `${[getState().config.get("host"), link.get("href")].join(
      ""
    )}?size=50`;
    let headers = getState().config.get("headers");

    dispatch({
      type: CategoryTypes.FETCH_CATEGORY,
      payload: {
        headers: headers,
        url: url,
        link: link,
      },
    });

    return axios({
      headers: headers.toJSON(),
      method: link.get("method"),
      url: url,
    })
      .then(({ data }) => {
        dispatch(
          setCategory(
            Object.assign({
              link,
              data,
            })
          )
        );
        return data;
      })
      .catch((error) => {
        dispatch({
          type: CategoryTypes.ERROR_FETCH_CATEGORY,
          payload: error,
        });
        throw error;
      });
  };
}

export function setCategory(payload) {
  return {
    type: CategoryTypes.SET_CATEGORY,
    payload: payload,
  };
}

//TODO: refactor this
export function showSeeAllCategory(payload) {
  return {
    type: CategoryTypes.SHOW_SEE_ALL_CATEGORY,
    payload: payload,
  };
}

export function hideSeeAllCategory(payload) {
  return {
    type: CategoryTypes.HIDE_SEE_ALL_CATEGORY,
    payload: payload,
  };
}
