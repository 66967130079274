import React, { Component } from "react";
import GameDetails from "containers/Game3/GameDetails";

import "component/game/GameFrame/styles.scss";

class GameFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      isLiked: false,
    };
  }

  render() {
    return (
      <div className={this.props.className}>
        <div
          className={`gameFrameContainer ${
            this.props.isFullHeight ? "full-height" : ""
          }`}
          ref="GAME_CONTAINER"
        >
          {this.props.src && (
            <iframe
              title="Game"
              className="gameFrame"
              src={this.props.src}
              frameBorder="0"
              height="1200"
              scrolling="yes"
            />
          )}
          {this.props.isNewSkin && (
            <GameDetails
              skin={this.props.skin}
              onGameDetailsClick={this.props.onGameDetailsClick}
              gameDetailsOpened={this.props.gameDetailsOpened}
              smScreenSize={this.props.smScreenSize}
              details={this.props.details}
              params={this.props.params}
              match={this.props.match}
              fetchGameUrl={this.props.fetchGameUrl}
              onPlayClick={this.props.onPlayClick}
              playType={this.props.playType}
              relatedLinks={this.props.relatedLinks}
              location={this.props.location}
              navigateTo={this.props.navigateToUrl}
              history={this.props.history}
            />
          )}
        </div>
      </div>
    );
  }
}

export default GameFrame;
