import React, { Component } from 'react';

import 'component/page/PageNotFound/styles.css';

class ErrorModal extends Component {
    render() {
        return <div className="pageNotFound">
        <p>Page Not Found</p>
        <p>{`proper url (e.g. /{locale}/{sessionId}/games)`}</p>
        <p>sample: <a href="en-US/12345/games">en-US/12345/games</a></p>
      </div>

    }
}

export default ErrorModal;