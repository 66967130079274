import createReducer from 'utils/createReducer';
import {
    UrlsTypes,
    LoginTypes,
    LocalStorageTypes,
    SecurityTypes
} from 'actions/types';

import {
    fromJS
} from 'immutable';

export const defaultValue = fromJS({})

export const urls = createReducer(defaultValue,{
    [UrlsTypes.SET_URLS](state, { payload }) {
        let newState = state.merge(payload)
        //localStorage.setItem('urls', JSON.stringify(newState.toJSON()))
        return newState
    },
    [LoginTypes.LOGOUT](state, { payload }) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('urls')
        return newState
    },
    [LocalStorageTypes.RESTORE_URLS](state, { payload }) {
        let newState = state.merge(payload)
        return newState
    },
    [SecurityTypes.TOKEN_REJECTED](state, {payload}) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('urls')
        return newState
    }
})