import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    UrlsTypes,
    ErrorTypes
} from '../actions/types';

export const defaultValue = fromJS([])

export const error = createReducer(defaultValue,{
    [UrlsTypes.ERROR_FETCH_URLS](state, { payload }) {
        let newState = state.push(payload)
        //localStorage.setItem('error', JSON.stringify(newState.toJSON()))
        return newState
    },
    [ErrorTypes.SERVER_ERROR](state, { payload }) {
        switch(payload.response.status) {
            case 401:
                let has401 = state.find(error => {
                    console.log(`is 401`, error.response.status === 401)
                    return error.response.status === 401
                })
                if (has401) {
                    return state
                } else {
                    return state.push(payload)
                }
            case 403:
                let has403 = state.find(error => {
                    console.log(`is 403`, error.response.status === 403)
                    return error.response.status === 403
                })
                if (has403) {
                    return state
                } else {
                    return state.push(payload)
                }
            case 417:
                let has417 = state.find(error => {
                    console.log(`is 417`, error.response.status === 417)
                    return error.response.status === 417
                })
                if (has417) {
                    return state
                } else {
                    return state.push(payload)
                }
            case 503:
                let has503 = state.find(error => {
                    console.log(`is 503`, error.response.status === 503)
                    return error.response.status === 503
                })
                if (has503) {
                    return state
                } else {
                    return state.push(payload)
                }
            default:
                return state
        }
    }
})