import React, { Component } from 'react';

import 'component/animation/Loading/styles.css';

class TabletIcon extends Component {
    constructor(props) {
        super(props);

        let color = props.color ? props.color : "rgba(0,0,0,0.5)";
        let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

        this.state = {
            width: props.width ? props.width : 25,
            height: props.width ? props.width : 25,
            color: props.selected ? selectedColor : color
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.selected !== this.props.selected) {
            this.setState({
                color: nextProps.selected ? nextProps.selectedColor : nextProps.color
            })
        }
    }

    render() {
        return <span>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height}px`}
                viewBox="0 0 777.46 695"
                style={{
                    "enableBackground" : "new 0 0 777.46 695"
                }}
                >
                <path d="M645.14,117.06Q644.72,86.42,614.08,86H247.44q-30.63.42-31.06,31.06V749.94q.42,30.64,31.06,31.06H614.08q30.63-.42,31.06-31.06V117.06M423.88,741.13a14.75,14.75,0,0,1,10.74-4.36,15,15,0,0,1,15.11,15.1A15.19,15.19,0,0,1,434.62,767a15.06,15.06,0,0,1-15.11-15.11,14.79,14.79,0,0,1,4.37-10.74M610,726.52H251.55V107H610Z"
                    transform="translate(-40.81 -86)"
                    fill={this.state.color}/>
            </svg>
        </span>
    }
}

export default TabletIcon;

