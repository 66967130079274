import React, { Component } from "react";

import { ReactComponent as FullscreenIcon } from "assets/icons/fullscreen-alt-svgrepo-com.svg";
import { ReactComponent as ExitFullscreenIcon } from "assets/icons/fullscreen-exit-alt-svgrepo-com.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";
import { ReactComponent as CashierIcon } from "assets/icons/cashier.svg";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";

import { ReactComponent as Screen1Icon } from "assets/icons/screens-icon-01.svg";
import { ReactComponent as Screen2Icon } from "assets/icons/screens-icon-02.svg";
import { ReactComponent as Screen3Icon } from "assets/icons/screens-icon-03.svg";
import { ReactComponent as Screen4Icon } from "assets/icons/screens-icon-04.svg";

import Translations from "translations";

import "./styles.scss";

class MultiGameController extends Component {
  render() {
    return (
      <div className="multi-game-controller">
        <div className="row nopadding">
          <div
            className="col-auto"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <PreviousIcon />
          </div>

          <div className="col text-center game-num">
            <span
              className={this.props.multiGameCount === 1 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(1)}
            >
              <Screen1Icon />
            </span>
            <span
              className={this.props.multiGameCount === 2 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(2)}
            >
              <Screen2Icon />
            </span>
            <span
              className={this.props.multiGameCount === 3 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(3)}
            >
              <Screen3Icon />
            </span>
            <span
              className={this.props.multiGameCount === 4 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(4)}
            >
              <Screen4Icon />
            </span>
          </div>

          {this.props.showCashier && (
            <div
              className="col-auto"
              onClick={this.props.broadcastCashier}
              title={Translations.depositWithdraw}
            >
              <CashierIcon />
            </div>
          )}
          {this.props.showSwitchDevice && (
            <div
              className="col-auto"
              onClick={this.props.broadcastSwitchDevice}
              title={Translations.switchDevice}
            >
              <TransferIcon />
            </div>
          )}

          {this.props.onRefreshClickHandler && this.props.multiGameCount === 1 && (
            <div
              className="col-auto refresh"
              onClick={this.props.onRefreshClickHandler}
              title={Translations.refresh}
            >
              <RefreshIcon />
            </div>
          )}

          {this.props.gameFullscreen && (
            <div
              className="col-auto"
              onClick={() => {
                this.props.onFullscreenHandler(!this.props.gameFullscreen);
              }}
              title={Translations.minimize}
            >
              <ExitFullscreenIcon />
            </div>
          )}

          {!this.props.gameFullscreen && (
            <div
              className="col-auto"
              onClick={() => {
                this.props.onFullscreenHandler(!this.props.gameFullscreen);
              }}
              title={Translations.maximize}
            >
              <FullscreenIcon />
            </div>
          )}

          <div
            className="col-auto"
            onClick={this.props.onCloseClickHandler}
            title={Translations.close}
          >
            <CloseIcon />
          </div>
        </div>
      </div>
    );
  }
}

export default MultiGameController;
