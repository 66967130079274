import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class VirtualSportsIcon extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
      this.setState( {
        width: nextProps.width,
        height: nextProps.height
      } )
  }

  render() {
    return <div>
            <svg xmlns="http://www.w3.org/2000/svg"
                width={`${this.state.width}px`}
                height={`${this.state.height}px`}
                viewBox="0 0 850 850"
                style={{
                    "enableBackground" : "new 0 0 850 850"
                }}>
                <defs>
                    <mask id="tgmask" x="0" y="0">
                        <path d="M453.19,134.08Q434.85,103.61,403,118.47L74.75,258.8q-33.09,12.93-17.6,44.69L305,759.84q18.18,30.52,50.13,15.53L683.36,635c1.25-.51,2.47-1,3.67-1.53q28.53-13.1,13.94-43L453.19,134.08M407.14,350.16q22.41,3.21,32.19,20,8.24,14.24,2.2,30.15a31.81,31.81,0,0,1-2.44,5.66.74.74,0,0,1-.17.39L400.37,496v.15l-.16-.15L295.72,471.06a2.25,2.25,0,0,1-.65-.08q-3.27-.69-6.28-1.6-18.09-5.13-26.33-19.51-9.61-16.61,0-34.59A52.84,52.84,0,0,1,291.4,390Q311,382.6,346,399.21q1.78-31.36,20.21-41.85t40.92-7.2M615.71,86.56q25.59-.91,32.11,18.82a46.58,46.58,0,0,1,2,12.7V474.43l21.68,39.33V103.24q-1.62-34.67-37.74-33.52H269.64Q233.52,68.58,232,103.24v79l18.75-8V118.08q1.46-32.67,34.15-31.52H615.71M687,114.72v17.44l59.91,14.24q32.19,6.42,23.88,38.33L687,462.72V537L796.16,175.47q8.4-33.9-26.65-41.09Z"
                                fill="white"/>
                    </mask>
                </defs>
                <rect x="0" y="0" width="850" height="850"
                    mask="url('#tgmask')"
                    fill="url('#categoryNormal')"
                    />
            </svg>
            {
                this.props.label && <div
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default VirtualSportsIcon;