import {
    combineReducers
} from 'redux';
import {
    routerReducer,
} from 'react-router-redux';
import * as LocalStorageReducer from 'reducers/localStorage';
import * as ConfigReducer from 'reducers/config';
import * as SecurityReducer from 'reducers/security';
import * as UserReducer from 'reducers/user';
import * as UrlReducer from 'reducers/urls';
import * as CategoriesReducer from 'reducers/categories';
import * as WindowReducer from 'reducers/window';
import * as ErrorReducer from 'reducers/error';
import * as PagesReducer from 'reducers/pages';
import * as GameReducer from 'reducers/game';
import * as GamesReducer from 'reducers/games';
import * as FilterReducer from 'reducers/filters';
import * as RecommendedReducer from 'reducers/recommended';
import * as LinksReducer from 'reducers/links';
import * as ApplicationReducer from 'reducers/application';
import * as CookieReducer from 'reducers/cookie';
import * as ProfileReducer from 'reducers/profile';
import * as BetHistoryReducer from 'reducers/betHistory';
import * as TournamentReducers from 'reducers/tournament';
import * as HistoryReducers from 'reducers/history';
import * as SearchReducers from 'reducers/search';
import * as ServerSentEventsReducers from 'reducers/severtSentEvents';


export default combineReducers(Object.assign({
    router: routerReducer
},
    LocalStorageReducer,
    ConfigReducer,
    SecurityReducer,
    UrlReducer,
    UserReducer,
    CategoriesReducer,
    WindowReducer,
    ErrorReducer,
    PagesReducer,
    GameReducer,
    GamesReducer,
    FilterReducer,
    RecommendedReducer,
    LinksReducer,
    ApplicationReducer,
    CookieReducer,
    ProfileReducer,
    BetHistoryReducer,
    TournamentReducers,
    HistoryReducers,
    SearchReducers,
    ServerSentEventsReducers
))
