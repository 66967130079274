export const CategoryTypes = {
  FETCH_CATEGORY: 'FETCH_CATEGORY',
  SET_CATEGORY: 'SET_CATEGORY',
  ERROR_FETCH_CATEGORY: 'ERROR_FETCH_CATEGORY',
  FETCH_RECOMMENDED_LINKS: 'FETCH_RECOMMENDED_LINKS',
  SET_RECOMMENDED_LINKS: 'SET_RECOMMENDED_LINKS',
  FETCH_GAMES: 'FETCH_GAMES',
  SET_GAMES: 'SET_GAMES',
  CLEAR_GAMES: 'CLEAR_GAMES',
  FETCH_FILTERS: 'FETCH_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  SHOW_FILTERS: 'SHOW_FILTERS',
  TOGGLE_FILTER: 'TOGGLE_FILTER',
  SET_CATEGORY_SORTING: 'SET_CATEGORY_SORTING',
  SET_SORTING: 'SET_SORTING',
  CLEAR_FILTER_BY_CATEGORY: 'CLEAR_FILTER_BY_CATEGORY',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  SHOW_SEE_ALL_CATEGORY: 'SHOW_SEE_ALL_CATEGORY',
  HIDE_SEE_ALL_CATEGORY: 'HIDE_SEE_ALL_CATEGORY',
  FETCH_GAME_TYPES: 'FECTH_GAME_TYPES',
  SET_GAME_TYPES: 'GET_GAME_TYPES'
}