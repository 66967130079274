import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class ScratchIcon extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
      this.setState( {
        width: nextProps.width,
        height: nextProps.height
      } )
  }

  render() {
    return <div className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height*1}px`}
                viewBox="0 0 850 850"
                style={{
                    "enableBackground" : "new 0 0 850 850"
                }}>
                <defs>
                    <mask id="scmask" x="0" y="0">
                    <path d="M570.67,486q28.22-34.18,54.08,4.69l24.42,37Q706.08,646.67,686,749H704.7q76.39-8,78.18-78.17V179.25Q780,105.2,704.7,101H145.3Q70,105.22,67.12,179.25V670.83Q68.92,741,145.3,749h257L299.7,696.54l-2.18-1.38a68.29,68.29,0,0,1-10.19-8.32q-10.26-9.87-11.56-23-1.45-12.93,7.6-23.77a39.88,39.88,0,0,1,23.93-13.42q10.43-1.94,21.5,3.72a55.11,55.11,0,0,1,6.63,4l38.4,22.15Q402.29,675,392.75,643.1L265.18,408a64.64,64.64,0,0,1-4.36-8.48q-5.67-12.94-2-25.55t16.09-19.16A39.42,39.42,0,0,1,302,351.52q14.64,3,24.82,20.53c1.19,2.05,2.26,4,3.23,5.82s1.7,3.67,2.35,5.34l64.1,118.91q13.35,19.32,26.44-3.39,16.41-29.26,67.34-.09Q530.17,453,562.18,487a13.91,13.91,0,0,0,2.19-.32c.75-.05,1.51-.13,2.26-.24,1.35-.22,2.69-.38,4-.49M713.43,198.41q10.68,1.69,14.47,11.56V282.8q-3.79,9.94-14.47,11.64H136.57q-10.68-1.69-14.47-11.64V210q3.8-9.87,14.47-11.56Z"
                            fill="white"/>
                    </mask>
                </defs>
                <rect x="0" y="0" width="850" height="850"
                    mask="url('#scmask')"
                    fill="url('#categoryNormal')"
                    />
            </svg>
            {
                this.props.label && <div
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default ScratchIcon;