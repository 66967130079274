import React, { Component, createRef} from 'react';
import 'component/list/Vertical/styles.scss';
class VericalList extends Component {
    constructor(props) {
        super(props);
        this.endRef = createRef()
    }

    componentDidMount() {
        this.initObeserver();
    }

    onReachEnd() {
        if(this.props.onReachEnd) {
            this.props.onReachEnd()
        }
    }

    initObeserver() {
		if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
        ) {
            let observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (this.endRef.current) {
                            this.onReachEnd()
                            /* this.initLoadDelay = setTimeout(() => {
                                this.getGames(this.props.link);
                                this.getTournamentPreview(this.props.links)
                            }, 500) */
                        }
                    } else {
                        if (this.endRef.current) {
                            //clearInterval(this.initLoadDelay)
                        }
                    }
                })
            }, {
                //rootMargin: '500px 0px 500px 0px'
                rootMargin: '0px 0px 0px 0px'
            });
            observer.observe(this.endRef.current);
        } else {
            //this.getGames(this.props.link);
            //this.getTournamentPreview(this.props.links)
        }
	}

    render() {
        return <div className={`vertical-list row ${this.props.className ? this.props.className : ''}`}>
            { this.props.children }
            <div className='col-end' ref={this.endRef}>&nbsp;</div>
        </div>

    }
}

export default VericalList;