import React, { Component } from 'react';
import 'component/listItem/ProviderType/styles.scss';
import iconDefault from 'assets/images/iconDefault.png';

const ICON_DEFAULT = "ICON_DEFAULT";

  class ProviderType extends Component {
    constructor(props) {
				super(props)
        this.onMouseOverHandler = this._onMouseOverHandler.bind( this );
        let imgUrl = (props.game && props.game.getIn(['images', 'providers']) ?
          `${props.game.getIn(['images', 'providers'])}` :
          iconDefault);

        this.state = {
            hovered: false,
            imgUrl
        }
    }

    _onMouseOverHandler( bol ) {
      this.setState( {
        hovered: bol
      } )
    }

    render() {
        let {
            game,
        } = this.props

        return <div
            className={`${this.props.className} provider-type`} onMouseOver={() => {
                    this.onMouseOverHandler(true)
                }}
                onMouseOut={() => {
                    this.onMouseOverHandler(false)
                }}
                onClick={(event) => {
                  if(!this.props.categoryLink) {
                    this.props.onClick();
                  } else {
                    this.props.categoryLink.get('rel');
                  }

                  event.stopPropagation();
                }}
                >
            <div>
                {
                  this.props.game && <img src={`${(this.state.imgUrl)}&width=200&theme=dark`} className="icon d-none" alt={game.get('name')}
                    onLoad={({currentTarget}) => {
                        currentTarget.classList.remove("d-none");
                        this.refs[ICON_DEFAULT].classList.add("d-none");
                    }}
                    ></img>
                }
                <img ref={ICON_DEFAULT} src={iconDefault} className="icon" alt=""></img>
            </div>
        </div>
    }
  }

  export default ProviderType;