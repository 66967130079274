import createReducer from 'utils/createReducer';
import {
    LoginTypes,
    SecurityTypes,
    LocalStorageTypes,
    ApplicationTypes
} from 'actions/types';

import {
    fromJS
} from 'immutable';

export const defaultValue = fromJS({
    accessToken: null,
    codeChallenge: null,
    sessionId: null
})

export const security = createReducer(defaultValue,{
    [LoginTypes.LOGIN_SUCCESS](state, { payload }) {
        let newState = state.merge({
            accessToken: payload.accessToken,
            codeChallenge: payload.codeChallenge
        })
        //localStorage.setItem('security', JSON.stringify(newState.toJSON()))
        return newState;
    },
    [LoginTypes.LOGOUT](state, { payload }) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('security')
        return newState;
    },
    [SecurityTypes.SET_CODE_CHALLENGE](state, { payload }) {
        let newState = state.merge({
            codeChallenge: payload.codeChallenge
        })
        //localStorage.setItem('security', JSON.stringify(newState.toJSON()))
        return newState
    },
    [SecurityTypes.TOKEN_VERIFIED](state, { payload }) {
        let newState = state.merge({
            sessionId: payload.sessionId
        })
        //localStorage.setItem('security', JSON.stringify(newState.toJSON()))
        return newState
    },
    [LocalStorageTypes.RESTORE_SECURITY](state, { payload }) {
        return state.merge(payload)
    },
    [SecurityTypes.TOKEN_REJECTED](state, { payload }) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('security')
        return newState
    },
    [LocalStorageTypes.ERROR_RESTORE](state, { payload }) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('security')
        return newState
    },
    [ApplicationTypes.INITIALIZE_APPLICATION](state, {payload}) {
        let newState = state.setIn(['locale'], payload.locale)
        newState = newState.setIn(['sessionId'], payload.sessionId);
        return newState
    }
})