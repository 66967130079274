import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import * as queryString from 'query-string';
import {
  ActionCreator
} from 'actions';
import { IconCategoryListItem } from 'component/listItem';
import 'containers/RelatedGames/styles.scss';
import CategoryVerticalList from 'containers/CategoryVerticalList';

class RelatedGames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: null,
      selectedTabId: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.relatedLinks !== this.props.relatedLinks) {
      if(nextProps.relatedLinks) {
        this.setState({
          link: nextProps.relatedLinks.getIn([0]),
          filtersLink: nextProps.relatedLinks.getIn([1]),
          selectedTabId: nextProps.relatedLinks.getIn([0, 'rel'])
        })
      } else {
        this.setState({
          link: null,
          filtersLink: null,
          selectedTabId: null
        })
      }

    }
  }

  render() {
      if (this.props.relatedLinks && this.props.relatedLinks.size === 0) {
        return null
      }
      return (
        <div className={`${this.props.className} relatedGames`}>
          <div className="row row-navigation">
          {
            this.props.ownCategoryLink && <div className={`col-auto col-tab ${ (this.state.selectedTabId === this.props.ownCategoryLink.get('rel')) ? 'selected' : '' }`}>
              <span>
                { this.props.ownCategoryLink.get('name') }
              </span>
            </div>
          }

          {
            !this.props.relatedLinks &&
              <>
                <CategoryVerticalList />
                <CategoryVerticalList />
              </>
          }
          {
            this.props.relatedLinks && this.props.relatedLinks.map((link, key) => {
              if ( this.props.ownCategoryLink && this.props.ownCategoryLink.get('rel') === link.get('rel')) {
                return null;
              } else if (this.props.ownCategoryGamesTotal === 1 && this.props.nav === link.get('rel')) {
                return null
              }

              return <CategoryVerticalList
                key={key}
                location={this.props.location}
                link={link}
                relatedLinks={this.props.relatedLinks}
                match={this.props.match}
                navigateTo={this.props.navigateTo}
                listItemComponent={IconCategoryListItem}
                showIfNoList={true}
                />
            })
          }
          </div>
        </div>
      )
  }

}

const mapStateToProps = ( state, ownProps ) => {
  let isLoading = true;
  let { gameId, nav } = queryString.parse(ownProps.location.search);
  let ownCategoryGamesTotal;
  try {
    ownCategoryGamesTotal = state.games.getIn([nav, 'data', 'totalCount'])
  } catch (err) {}
  return {
      ownCategoryGamesTotal,
      gameId,
      nav,
      host: state.config.get( 'host' ),
      isLoading: isLoading,
      isMobile: state.window.get('isMobile'),
      maxCount: 50
  }
}

const mapDispatchToProps = ( dispatch, ownProps ) => {
  return bindActionCreators( ActionCreator, dispatch );
}

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
  return Object.assign( {}, stateProps, ownProps, {
      navigateToUrl: ( path ) => ownProps.history.push( `${path}` ),
      navigateTo: ( path ) => ownProps.history.push( path ),
  })
}

export default connect( mapStateToProps, mapDispatchToProps, mergeProps )( RelatedGames )