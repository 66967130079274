import React, { Component } from 'react';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';
import {
	fromJS
} from 'immutable';
import * as queryString from 'query-string';

import 'containers/JackpotDetails/styles.scss';
import { ReactComponent as CloseIcon} from 'assets/icons/close.svg';

const TOURNAMENT_DETAILS_MODAL = "TOURNAMENT_DETAILS_MODAL";

class JackpotDetails extends Component {
	constructor(props) {
		super(props)
		this.onChangeTab = this._onChangeTab.bind(this);
		this.state = {
			tab: 0,
			urls: fromJS([])
		}
	}

	_onChangeTab(index) {
		this.setState({
			tab: index
		})
	}

	componentDidMount() {
		if (!this.props.tournamentLink) {
			return
		}

		this.props.fetchTournamentUrl(this.props.tournamentLink)
		.then(response => {
			let urls = fromJS(response.urls);
			if(urls.size > 0) {
				this.props.onDetailsAvailable()
			}
			this.setState({
				urls: urls
			})
		})
	}
	componentWillReceiveProps(nextProps) {
		if(nextProps.tournamentLink !== this.props.tournamentLink) {
			if(nextProps.tournamentLink) {
				this.props.showModal(TOURNAMENT_DETAILS_MODAL)
				this.props.fetchTournamentUrl(nextProps.tournamentLink)
				.then(response => {
					let urls = fromJS(response.urls);
					this.setState({
						urls: urls
					})
				})
			} else {
				this.props.hideModal(TOURNAMENT_DETAILS_MODAL)
			}

		}
	}



	render() {
			if(!this.props.tournamentLink || !this.state.urls || this.state.urls.size === 0) {
				return null
			}
			return <div className="jackpot-details" style={{
					height: `${this.props.height}px`
				}}>
				<CloseIcon
					className="close-button"
					width="34"
					onClick={(event) => {
						this.setState({
							tab: 0,
							urls: fromJS([])
						})
						delete this.props.qs.tournamentDetails;
						let newLoc = `${this.props.location.pathname}?${queryString.stringify(this.props.qs)}`
						this.props.navigateTo( newLoc )
					}}
					/>
				<div className="header">

				</div>
				<section>
					<ul className="nav nav-tabs">
						{
							this.state.urls.map((url, key) => {
								return <li className="nav-item"
									key={key}
									onClick={() => {
										this.onChangeTab(key)
									}}>
									<span className={`nav-link ${ (key === this.state.tab) ? 'active' : ''}`}>
										{url.get('name')}
									</span>
								</li>
							})
						}
					</ul>
					<div className="tab-content">
						{
							this.state.urls.map((url, key) => {
								return <div
									className={`tab-pane fade ${(key === this.state.tab) ? 'show active' : ''}`}
									key={key}
									>
									<iframe src={url.get('href')} title={url.get('name')}/>
								</div>
							})
						}
					</div>
				</section>
			</div>
    }
}

const mapStateToProps = (state, ownProps) => {
	let tournamentLink;
	let qs = queryString.parse(window.location.search)
	let { tournamentDetails } = qs;
	try {
		tournamentLink = state.games.getIn([tournamentDetails, 'route', 'links']).find(link => {
			return (link.get('rel').indexOf('jackpot.details') > -1)
		})
	}catch(err) {}
	return {
		qs,
		tournamentLink,
		height: state.window.get('height'),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			fetchTournamentUrl: ( link ) => dispatchProps.fetchTournamentUrl(link),
			navigateTo: ( path ) => ownProps.history.push( path ),
			showModal: (modal) => dispatchProps.showModal(modal),
			hideModal: (modal) => dispatchProps.hideModal(modal)
		})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(JackpotDetails)