import axios from 'axios';
import {
    PagesTypes
} from 'actions/types';

export function wlFetchPages() {
    return (dispatch, getState) => {
        try {
            var host = getState().config.get('host');
            var headers = getState().config.get('headers').toJSON();
            var route = getState().links.getIn(['getPages', 'href'])
            var url = `${host}${route}`;
            var method = 'GET';
        } catch (error) {
            throw error;
        }

        dispatch({
            type: PagesTypes.WL_FETCH_PAGES,
            payload: {
                headers: headers,
                method: method,
                url: url
            }
        })
        return axios( {
            method,
            headers,
            url
        } )
        .then(response => {
            return dispatch(wlSetPages(response.data));
        })
        .catch(error => {
            throw error
        })
    }
}

export function wlSetPages(args) {
    return {
        type: PagesTypes.WL_SET_PAGES,
        payload: args
    }
}