import FiltersComponent from 'component/filters/Filter';
import ShowMoreFilterComponent from 'component/filters/ShowMoreFilter';
import CategoryFiltersComponent from 'component/filters/CategoryFilters';

export { FiltersComponent }
export { ShowMoreFilterComponent }
export { CategoryFiltersComponent }

export default Object.assign({
	FiltersComponent,
	ShowMoreFilterComponent,
	CategoryFiltersComponent
})