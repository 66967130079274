export const BetHistoryTypes = {
	SHOW_BET_HISTORY: "SHOW_BET_HISTORY",
	HIDE_BET_HISTORY: "HIDE_BET_HISTORY",
	FETCH_GAME_ROUND_HISTORY: "FETCH_GAME_ROUND_HISTORY",
	SET_GAME_ROUND_HISTORY: "SET_GAME_ROUND_HISTORY",
	FETCH_GAME_ROUND_HISTORY_PER_GAME: "FETCH_GAME_ROUND_HISTORY_PER_GAME",
	SET_GAME_ROUND_HISTORY_PER_GAME: "SET_GAME_ROUND_HISTORY_PER_GAME",
	FETCH_GAME_ROUNDS: "FETCH_GAME_ROUNDS",
	SET_GAME_ROUNDS: "SET_GAME_ROUNDS",
	FETCH_BET_HISTORY_URL: "FETCH_BET_HISTORY_URL",
	SET_BET_HISTORY_URL: "SET_BET_HISTORY_URL",
	FETCH_BET_HISTORY: "FETCH_BET_HISTORY",
	SET_BET_HISTORY: "SET_BET_HISTORY",
	ERROR_FETCH_BET_HISTORY: "ERROR_FETCH_BET_HISTORY",
	CLEAR_BET_HISTORY:  "CLEAR_BET_HISTORY"
}