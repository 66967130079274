import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class ExitFullscreenIcon extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <div className={this.props.className} title={this.props.title}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height*1}px`}
                viewBox="0 0 32 32"
                style={{
                    "enableBackground" : "new 0 0 32 32"
                }}>
                <polygon points="24.586,27.414 29.172,32 32,29.172 27.414,24.586 32,20 20,20 20,32 			"
                    fill={this.state.color} />
                <polygon points="0,12 12,12 12,0 7.414,4.586 2.875,0.043 0.047,2.871 4.586,7.414 			"
                    fill={this.state.color} />
                <polygon points="0,29.172 2.828,32 7.414,27.414 12,32 12,20 0,20 4.586,24.586 			"
                    fill={this.state.color} />
                <polygon points="20,12 32,12 27.414,7.414 31.961,2.871 29.133,0.043 24.586,4.586 20,0 			"
                    fill={this.state.color} />
            </svg>
            {
                this.props.label && <div
                    style={{
                        color: this.state.color
                    }}
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default ExitFullscreenIcon;