import React, { Component, Fragment } from 'react';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';

import Translations from 'translations';

import {
  LoadingAnimation
} from 'component/animation';

import 'containers/Update/styles.scss';


class Update extends Component {
	constructor(props) {
		super(props);
		this.onUpdateClickHandler = this._onUpdateClickHandler.bind(this);
		this.checkForUpdate = this._checkForUpdate.bind(this);
		this.state = {
			updateAvailable: false,
			checkInterval: null
		}
	}

	componentDidMount() {
		let checkInterval = setInterval(this.checkForUpdate, 300000);
		this.setState({
			checkInterval: checkInterval
		})
	}

	componentWillUnmount() {
		clearInterval(this.state.checkInterval);
		this.setState({
			checkInterval: null
		})
	}

	_checkForUpdate() {
		this.props.checkForUpdate()
		.then(response => {
			if (response.buildno !== this.props.buildno) {
				this.setState({
					updateAvailable: true
				})
				clearInterval(this.state.checkInterval);
				this.setState({
					checkInterval: null
				})
			}
		})
	}

	componentWillReceiveProps(nextProps) {

	}

	_onUpdateClickHandler() {
		window.location.reload();
	}

	render() {
			if (this.props.isPlaying || !this.state.updateAvailable) {
				return null
			}

			return <div className="update-container modal" role="dialog">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					{ this.state.updateInstalling && <div>
						<div className="row justify-content-center align-items-center nopadding loadingAnim">
							<LoadingAnimation style={{
								background: 'rgba(256, 256, 256, 0.75)'
								}}/>
						</div>
						</div>}
					{ !this.state.updateInstalling && <Fragment>
						<div className="modal-body">
							<p>{Translations.newVersion}</p>
							<p>{Translations.pleaseRefresh}</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger" onClick={this.onUpdateClickHandler}>{Translations.refresh}</button>
						</div>
					</Fragment>}
				</div>
			</div>
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
		let {url} = ownProps.match;
    return {
			isPlaying: (url.indexOf('launch.real') > -1 || url.indexOf('launch.demo') > -1),
			version: state.application.get('version'),
			buildno: state.application.get('buildno'),
			displayName: state.user.get('displayName'),
			pages: state.urls.get('pages')
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
				logout: () => dispatchProps.logout(),
				checkForUpdate: () => dispatchProps.checkForUpdate()
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Update)