import ErrorMobileModal from 'component/modal/ErrorMobile';
import ErrorModal from 'component/modal/Error';
import ExitDialogModal from './ExitDialog';
import GameAction from 'component/modal/GameAction';

export { ErrorMobileModal }
export { ErrorModal }
export { ExitDialogModal }
export { GameAction }

export default Object.assign({
    ErrorMobileModal,
    ErrorModal,
    ExitDialogModal,
    GameAction
})