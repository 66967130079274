import React, { Component } from 'react';
import 'component/listItemSkeleton/MainNavigationSkeleton/styles.scss';

class MainNavigationSkeleton extends Component {
  render() {
      return <div
          className={`navigation-placeholder ${this.props.placeholderType}`}>
          <div>
            <div className="img-holder">
            </div>
            <div className="details">
                <div className="name"><div className="dummy"></div></div>
            </div>
          </div>
      </div>
  }
}

export default MainNavigationSkeleton;