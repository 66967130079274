import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class PopularityIcon extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.props.width || this.state.width}px`}
                height={`${this.props.height*1 || this.state.height*1}px`}
                viewBox="0 0 850 850"
                style={{
                    "enableBackground" : "new 0 0 850 850"
                }}>
                >
                <g>
                <path d="M208.8,287.14q16.94-23.4-7-49.08-7.44,26.25-40.9,43-39,19.31-3.19,58.49-15.28-34.93,10.06-33.46,32.24,3,41-19M664.72,423.32q.49,15.54-10,13.83-15.63-2.53-7.45-13.91,14.64-38.52-26.42-40.16-23.22-1.56-10.88-18.4,67.82-29.78,35.09-104.29-18-78.69-150.17-135.94-60.53-16.2-82.53-84.83-33.45,68.31,0,102.66,35.91,34.35,0,51.28Q243.73,241,306.87,407.7q12.53,67.23-80.24,49.08-57.33-17.26-25.19-78-59.3,3.67-85.07,43.26-54.79,83.85,31.25,137.83,47.85,30.25,35.5,71.08Q146.07,671.81,247.25,738q191.24,144.76,346.4,0,82-2.54,82.53-66.91Q779.56,603.35,747.26,477q-53.67-51.21-25.2-96-64.77-10.63-57.34,42.37M521.83,520.58q21,12.92,22.25,37,1,20.28-13.42,36.89a41.81,41.81,0,0,1-5.23,5.72,3.68,3.68,0,0,1-.49.58L440.12,692v.24l-.25-.32L347.53,619.3c-.28,0-.44-.16-.5-.32a60.46,60.46,0,0,1-5.64-4.5,46.94,46.94,0,0,1-16.52-34.11q-1-23.88,17.83-41.39t42.12-18.4q23.57-.74,50.23,33.45,18-36.64,42-41.71T521.83,520.58Z" fill={this.state.color}></path>
                </g>
            </svg>
            {
                this.props.label && <span
                    style={{
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default PopularityIcon;