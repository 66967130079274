import CategoryType3Skeleton from 'component/listItemSkeleton/CategoryType3Skeleton';
import CategoryType8Skeleton from 'component/listItemSkeleton/CategoryType8Skeleton';
import CategoryType4Skeleton from 'component/listItemSkeleton/CategoryType4Skeleton';
import MainNavigationSkeleton from 'component/listItemSkeleton/MainNavigationSkeleton';
import ProviderTypeSkeleton from 'component/listItemSkeleton/ProviderTypeSkeleton';
import FilterSkeleton from 'component/listItemSkeleton/FilterSkeleton';
import SimilarTypeSkeleton from 'component/listItemSkeleton/SimilarTypeSkeleton';



export { ProviderTypeSkeleton, CategoryType3Skeleton, CategoryType4Skeleton, CategoryType8Skeleton, FilterSkeleton, MainNavigationSkeleton, SimilarTypeSkeleton };

export default Object.assign({
	CategoryType3Skeleton,
	CategoryType4Skeleton,
	CategoryType8Skeleton,
	MainNavigationSkeleton,
	FilterSkeleton,
	ProviderTypeSkeleton,
	SimilarTypeSkeleton
})