import createReducer from '../utils/createReducer';
import {
	ApplicationTypes
} from 'actions/types';
import {
  fromJS
} from 'immutable';

export const defaultValue = fromJS({
	sessionId: null,
	hideSplash: false,
	showMenu: false,
	menu: {
		expand: false,
		activePanel: null
	},
	srvcred: {}
})
export const cookie = createReducer( defaultValue, {
	[ApplicationTypes.SET_COOKIE](state, { payload }) {
		let newState = state.mergeDeep(payload)
		return newState
	},
	[ApplicationTypes.GET_MENU](state, { payload }) {
		let newState = state.mergeDeepIn(['menu'], payload);
		return newState
	},
	[ApplicationTypes.SET_MENU](state, { payload }) {
		let newState = state.mergeDeepIn(['menu'], payload);
		return newState
	}
})