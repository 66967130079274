import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import "containers/ShowMore/Categories/styles.scss";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ProviderTypeSkeleton } from "component/listItemSkeleton";

import { CategoryFiltersComponent } from "component/filters";

import { CommonHeader } from "component/common";
import ShowMoreFilters from "containers/ShowMoreFilters";
import { ProviderTypeListItem } from "component/listItem";
import { fromJS, is } from "immutable";

const SEE_ALL_MODAL = "SEE_ALL_MODAL";
class ShowMoreCategories extends Component {
  constructor(props) {
    super(props);
    this.seeMoreRef = createRef();
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.loadList = this._loadList.bind(this);
    this.onSeeAllScrollHandler = this._onSeeAllScrollHandler.bind(this);
    this.getTournamentPreview = this._getTournamentPreview.bind(this);
    this.onClickPrevious = this._onClickPrevious.bind(this);
    this.onShowMoreHandler = this._onShowMoreHandler.bind(this);
    this.onFilterClickHandler = this._onFilterClickHandler.bind(this);
    this.onFilterClearHandler = this._onFilterClearHandler.bind(this);
    this.onApplyFilterClickHandler = this._onApplyFilterClickHandler.bind(this);
    this.state = {
      skeleton: [1, 2, 3, 4, 5, 6],
      showCategoryLabel: false,
      activeFilter: null,
    };
  }

  _onFilterClickHandler(subCategory, value) {
    this.props.setActiveFilter(this.props.filtersLink, subCategory, value);
  }

  _onFilterClearHandler(subCategory) {
    this.props.clearFilterByCategory(
      this.props.filtersLink.get("rel"),
      subCategory
    );
  }

  _onApplyFilterClickHandler() {
    this.props.showFilters(!this.props.filtersVisible);
  }

  _onShowMoreHandler(rel, route) {
    let params = {
      showMore: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onClickPrevious() {
    this.props.goBack(
      `${this.props.location.pathname}${this.props.location.search}`
    );
    this.props.history.goBack();
  }

  _getTournamentPreview(links) {
    if (!links) return;

    let link = links.find((link) => {
      return link.get("rel").indexOf("tournament.preview") > -1;
    });
    if (link)
      this.props.fetchTournamentPreview(link, {
        parentRel: this.props.route.get("rel"),
      });
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    let params = {
      showMore: game.get("gameId"),
      sub: this.props.route.get("rel"),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _loadList(route, filters, sortBy, page) {
    if (!route) return;

    let params = new URLSearchParams();
    params.append("size", 50);
    params.append("page", page ? page : 1);

    if (sortBy) {
      params.append("sortBy", sortBy);
    }

    if (filters) {
      filters.forEach((filter) => {
        filter.get("values").forEach((value) => {
          if (value.get("toggled") === true) {
            params.append(filter.get("id"), value.get("id"));
          }
        });
      });
    }
    this.props.fetchGames(route, params);
  }

  _onSeeAllScrollHandler(event) {
    const scrollDemo = document.querySelector(".show-more-categories-modal");
    if (scrollDemo.scrollTop !== 0) {
      this.setState({ showCategoryLabel: true });
    } else {
      this.setState({ showCategoryLabel: false });
    }

    if (!this.props.games) return;
    let { page } = this.props.games.toJSON();

    if (
      this.props.games &&
      this.props.games.get("totalPages") === this.props.games.get("page")
    ) {
      return;
    }

    let positionToload =
      event.target.scrollHeight - event.target.offsetHeight * 2;
    let scrollTop = event.target.scrollTop;

    if (
      scrollTop > positionToload &&
      !this.props.isLoading &&
      !this.props.isLoadingNext
    ) {
      let nextPage = page + 1;
      this.loadList(
        this.props.route,
        this.props.filters,
        this.props.sortBy,
        nextPage
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showMoreRel !== this.props.showMoreRel) {
      if (!nextProps.showMoreRel) {
        this.props.hideModal(SEE_ALL_MODAL);
      } else {
        this.props.showModal(SEE_ALL_MODAL);
      }
    }

    if (
      (!is(nextProps.filters, this.props.filters) ||
        !is(nextProps.sortBy, this.props.sortBy)) &&
      (nextProps.filters || nextProps.sortBy)
    ) {
      this.loadList(this.props.route, nextProps.filters, nextProps.sortBy, 1);
    }
  }

  renderSeeAllListItem(item, key) {
    let ListItem = ProviderTypeListItem;
    let categoryLink = item.getIn(["links", 0]);

    return (
      <ListItem
        key={key}
        game={item}
        onClick={() => {
          this.onShowMoreHandler(categoryLink.get("rel"), categoryLink);
        }}
        symbol={this.props.symbol}
      />
    );
  }

  render() {
    if (!this.props.showMoreRel) return null;

    return (
      <Fragment>
        <div
          ref={this.seeMoreRef}
          className="show-more-categories-modal"
          onScroll={this.onSeeAllScrollHandler}
        >
          {this.props.route && (
            <div className="header">
              <CommonHeader
                link={this.props.route}
                showCategoryLabel={this.state.showCategoryLabel}
                tournamentPreview={this.props.tournamentPreview}
                detailsAvailable={this.props.detailsAvailable}
                onInfoClick={() => {
                  let params = this.props.qs;
                  params.tournamentDetails = this.props.route.get("rel");

                  let newLoc = `${
                    this.props.location.pathname
                  }?${queryString.stringify(params)}`;
                  this.props.navigateTo(newLoc);
                }}
                leftPanel={() => {
                  return (
                    <span
                      className="float-left prev-btn"
                      onClick={this.onClickPrevious}
                    >
                      <PreviousIcon width="34" />
                    </span>
                  );
                }}
                rightPanel={() => {
                  return (
                    <CloseIcon
                      width="34"
                      onClick={() => {
                        this.props.navigateTo(this.props.location.pathname);
                      }}
                    />
                  );
                }}
              />
            </div>
          )}
          <div className="content">
            {this.props.route && (
              <span className={`label`}>{this.props.route.get("name")}</span>
            )}

            <CategoryFiltersComponent
              filters={this.props.filters}
              filterRoute={this.props.filtersRoute}
              onApplyFilterClickHandler={(filter) => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: filter.get("id"),
                });
              }}
              onSortClick={() => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: "sortBy",
                });
              }}
              onFilterClickHandler={this.props.setActiveFilter}
              showSelectedFilter={false}
              games={this.props.games}
              link={this.props.route}
              sortBy={this.props.sortBy}
              enableClearAll={this.props.enableClearAll}
              clearAllFilters={this.props.clearAllFilters}
              isMobile={true}
              sortingSize={this.props.sortingSize}
            />

            <div
              className="row"
              style={{
                maxHeight: `${this.props.height}px`,
              }}
            >
              {this.props.games &&
                this.props.games.getIn(["items"]).map((game, key) => {
                  return (
                    <div className={`col`} key={key}>
                      {this.renderSeeAllListItem(game, key)}
                    </div>
                  );
                })}
              {(this.props.isLoading || this.props.isLoadingNext) && (
                <Fragment>
                  {this.state.skeleton.map((key2) => {
                    return (
                      <div className={`col`} key={key2}>
                        <ProviderTypeSkeleton />
                      </div>
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <ShowMoreFilters
          activeFilter={this.state.activeFilter}
          filters={this.props.filters}
          filtersLink={this.props.filtersRoute}
          sorts={this.props.sorts}
          games={this.props.games}
          onClose={() => {
            this.setState({
              activeFilter: null,
            });
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let qs = queryString.parse(window.location.search);
  let { showMore, route } = qs;
  let routeDecrypt = fromJS(JSON.parse(decodeURIComponent(atob(route))));
  let filtersRoute;
  let filters;
  let filtersIsLoading;
  let enabledFilters;
  let sortBy;
  let sorts;
  let games;
  let isLoadingNext;
  let isLoading = true;
  let detailsAvailable = false;
  let enableClearAll;
  let sortingSize = 0;

  try {
    games = state.games.getIn([showMore, "data"]);
  } catch (err) {}
  try {
    isLoading = state.games.getIn([showMore, "isLoading"]);
  } catch (err) {}
  try {
    isLoadingNext = state.games.getIn([showMore, "isLoadingNext"]);
  } catch (err) {}
  try {
    detailsAvailable = routeDecrypt.getIn(["links"]) ? true : false;
  } catch (err) {}
  try {
    filtersRoute = routeDecrypt.getIn(["links"]).find((link) => {
      return link.get("rel").indexOf("-filters") > -1;
    });
    filtersRoute = filtersRoute.setIn(
      ["rel"],
      `show.more.${filtersRoute.getIn(["rel"])}`
    );
  } catch (err) {}
  try {
    sortBy = state.filters.getIn([filtersRoute.get("rel"), "data", "sortBy"]);
  } catch (err) {}

  try {
    sorts = state.filters.getIn([filtersRoute.get("rel"), "data", "sorts"]);
  } catch (err) {}

  try {
    filters = state.filters.getIn([filtersRoute.get("rel"), "data", "filters"]);
  } catch (err) {}
  try {
    enabledFilters = state.filters.getIn([
      filtersRoute.get("rel"),
      "data",
      "filters",
    ]);
  } catch (err) {}
  try {
    let toggledSize = 0;
    for (var i = 0; filters.size > i; i++) {
      toggledSize = filters.getIn([i, "toggledCount"]);
      sortingSize = sortingSize + toggledSize;
    }
  } catch (err) {}
  try {
    filtersIsLoading = state.filters.getIn([
      filtersRoute.get("rel"),
      "isLoading",
    ]);
  } catch (err) {}
  try {
    enableClearAll = state.filters.getIn([
      filtersRoute.get("rel"),
      "enableClearAll",
    ]);
  } catch (err) {}

  return {
    isMobile: state.window.get("isMobile"),
    route: routeDecrypt,
    filtersRoute,
    filters,
    filtersIsLoading,
    enabledFilters,
    enableClearAll,
    sortBy,
    sorts,
    qs,
    detailsAvailable,
    showMoreRel: showMore,
    games,
    isLoading,
    isLoadingNext,
    sortingSize,
    isMultiGame: state.links.get("isMultiGame"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    navigateTo: (path) => ownProps.history.push(path),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchTournamentPreview: (link, params) =>
      dispatchProps.fetchTournamentPreview(link, params),
    showFilters: (bol) => dispatchProps.showFilters(bol),
    setActiveFilter: (category, subCategory, filter) =>
      dispatchProps.setActiveFilter(category, subCategory, filter),
    clearAllFilters: (category) => dispatchProps.clearAllFilters(category),
    setCategorySorting: (route, sorting) =>
      dispatchProps.setCategorySorting(route, sorting),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ShowMoreCategories);
