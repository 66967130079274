import React, { Component, Fragment } from 'react';
import 'component/listItem/CategoryType3/styles.scss';
import { RibbonIcon, TrophyIcon } from 'component/icons'
import bannerDefault from 'assets/images/iconDefault.png';
import Highlighter from "react-highlight-words";

/**
 * game icon with game title, provider and game type on the left
 */

const ICON_DEFAULT = "ICON_DEFAULT";

  class CategoryType3 extends Component {
    constructor(props) {
        super(props)
        this.onMouseOverHandler = this._onMouseOverHandler.bind( this );
        this.state = {
            hovered: false,
        }
    }

    _onMouseOverHandler( bol ) {
        this.setState( {
          hovered: bol
        } )
      }

    render() {
        let {
            game,
            filter
        } = this.props

        let searchArr;

        if(filter){
          searchArr = filter.split(' ');
        }

        return <div
            className={`${this.props.className} category-type-3`} onMouseOver={() => {
                    this.onMouseOverHandler(true)
                }}
                onMouseOut={() => {
                    this.onMouseOverHandler(false)
                }}
                onClick={(event) => {
                  this.props.onClick(this.props.linkDetails.get('rel'));
                  event.stopPropagation();
                }}
                >
            <div className="row game">
								<div className="icon">
									<img src={`${game.getIn(['images', 'iconUrl'])}&theme=dark`} className="d-none" alt={game.get('name')}
										onLoad={({currentTarget}) => {
												currentTarget.classList.remove("d-none");
												this.refs[ICON_DEFAULT].classList.add("d-none");
										}}
									></img>
									<img ref={ICON_DEFAULT} src={bannerDefault} alt={game.get('name')}></img>
								</div>
                <div className="col-8 details">
                  <div className="h-100 d-flex align-items-center">
                    <div>
                      { filter ? <Fragment>
                          <div className="name">
                            <Highlighter highlightClassName="matched-search" searchWords={searchArr} textToHighlight={game.get('name')}/>
                          </div>
                          <div className="provider">
                            <Highlighter highlightClassName="matched-search" searchWords={searchArr} textToHighlight={game.get('provider')}/>
                          </div>
                          <div className="game-type">
                            <Highlighter highlightClassName="matched-search" searchWords={searchArr} textToHighlight={game.get('gameType')}/>
                          </div>
                        </Fragment> :
                        <Fragment>
                          <div className="name">{game.get('name')}</div>
                          <div className="provider">{game.get('provider')}</div>
                          <div className="game-type">{game.get('gameType') ? game.get('gameType') : <>&nbsp;</>}</div>
                        </Fragment>
                      }
                    </div>
                    <span className="game-feature-badge">
									{
									 game.get('hasFreeRounds') && <span className="ribbonIcon">
										<RibbonIcon
										color="rgba(255, 204, 0, 1)"
										selectedColor=""
										/>
									</span>
									}
									{
									game.get('partOfOnGoingTournament') && <span className="trophyIcon">
											<TrophyIcon
													color="rgba(255, 204, 0, 1)"
													selectedColor=""
													/>
									</span>
									}
									</span>
                  </div>
                </div>
            </div>
        </div>
    }
  }

  export default CategoryType3;