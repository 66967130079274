import React, { Component } from 'react';

import 'component/listItem/Filter/styles.css';

class Filter extends Component {
    render() {
        return <span className="filter">Filter</span>
    }
}

export default Filter