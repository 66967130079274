export function hideShowButton( screenWidth, size , template){
    let screenWidthSize;

    switch(true) {
        case screenWidth <= 576:
            screenWidthSize = 'sm';
            break;
        case screenWidth > 576:
            screenWidthSize = 'md';
            break;
        case screenWidth > 992:
            screenWidthSize = 'lg';
            break;
        default:
            screenWidthSize = '';
            break;
    }

    //TODO: Update parameters based on template size (this.props.template.get('size'))

    return ((screenWidth < 1256 && size >= 6) ||
        (screenWidthSize === 'md' && size >= 7) ||
        (screenWidth < 1256 && size >= 5) ||
        (screenWidthSize === 'sm' && size >= 3) ||
        (screenWidth < 800 && size <= 4) ||
        (template === 'l' && size > 4)) && size !== 1
}
