import createReducer from '../utils/createReducer';
import {
	ModalHistoryTypes
} from 'actions/types'
import {
    fromJS
} from 'immutable';
export const defaultValue = fromJS([])

export const modalHistory = createReducer(defaultValue,{
	// eslint-disable-next-line
	['@@router/LOCATION_CHANGE'](state, {payload}) {
		let newState = state;
		let lastUrl
		if(state.last()) {
			lastUrl = `${state.last().pathname}${state.last().search}`;
		}
		if(payload.search.indexOf('gameId=') > -1 ||
			payload.search.indexOf('showMore=') > -1 ||
			payload.search.indexOf('q=') > -1
			) {
			if (lastUrl !== `${payload.pathname}${payload.search}`) {
				newState = state.push(payload);
			}
			return newState;
		}
		return defaultValue
	},
	[ModalHistoryTypes.GO_BACK](state, {payload}) {
		let lastUrl
		try {
			lastUrl = `${state.last().pathname}${state.last().search}`;
		} catch (err) {}
		let newState = state;
		if (lastUrl === payload.url) {
			return newState.pop();
		}
		return newState
	}
})