import React, {
	Component,
	Fragment
} from 'react';
import Translations from 'translations';
import {
	currencyFormatter
} from 'utils/currencyHelper';
import { ReactComponent as InfoIcon} from 'assets/icons/information-button.svg';

import {
	convertMS
} from 'utils/date';
import {
	isEqual
} from 'lodash';

import 'component/common/Header/styles.scss';

class CommonHeader extends Component {
	constructor(props) {
		super(props)
		this.requestAnimationFrameHandler = this._requestAnimationFrameHandler.bind(this);
		this.animationFrameId = null;
		this.state = {
			startsIn: null,
			endsIn: null,
			animationFrameId: null
		}
	}

	componentDidMount() {
		this.requestAnimationFrameHandler(this.props.tournamentPreview);
	}

	componentWillUnmount() {
		window.cancelAnimationFrame( this.animationFrameId );
	}

	componentWillReceiveProps(nextProps) {

		if(nextProps.tournamentPreview && !isEqual(nextProps.tournamentPreview, this.props.tournamentPreview)) {
			window.cancelAnimationFrame( this.animationFrameId );
			this.requestAnimationFrameHandler(nextProps.tournamentPreview);
		}
	}

	_requestAnimationFrameHandler(tournamentPreview) {
		if(tournamentPreview) {
			let newDate = new Date().getTime()
			let startsIn = tournamentPreview.get('startsIn') === 0 ? null : convertMS(tournamentPreview.get('startsIn') - newDate)
			let endsIn = tournamentPreview.get('endsIn') === 0 ? null : convertMS(tournamentPreview.get('endsIn') - newDate);
			let startsInDHS = startsIn ? { d: startsIn.d, h: startsIn.h, m: startsIn.m } : null;
			let endsInDHS = endsIn ? {d: endsIn.d, h: endsIn.h, m: endsIn.m} : null;
			let started = (tournamentPreview.get('startsIn') - newDate <= 0)
			let ended = (tournamentPreview.get('endsIn') - newDate <= 0)

			if(!isEqual(startsInDHS, this.state.startsIn) || !isEqual(endsInDHS, this.state.endsIn)) {
				this.setState({
					startsIn: started ? null :startsInDHS,
					endsIn: ended ? null : endsInDHS
				})
			}
		}
		this.animationFrameId = window.requestAnimationFrame(() =>{
			this.requestAnimationFrameHandler(tournamentPreview)
		})
	}

	render() {
			const headerName = this.props.link.get('name');
			const link = this.props.link.get('rel');
			const hasFreeRounds = this.props.link.get('rel') === 'free.rounds-claim.rewards';
			const isBigImageView = link.includes('tournament-games') || link.includes('jackpot-games');

			let LeftPanel = this.props.leftPanel;
			let RightPanel = this.props.rightPanel;
			let headerClass = '';
			if(this.props.leftPanel && this.props.rightPanel) {
				headerClass = 'with-left-right';
			} else if (this.props.leftPanel) {
				headerClass = 'with-left';
			} else if (this.props.rightPanel) {
				headerClass = 'with-right'
			}

			let tournamentNameWidth = '';
			if (this.state.startsIn) {
				tournamentNameWidth = '174px';
			} else if(this.state.endsIn && !this.state.startsIn){
				tournamentNameWidth = '215px';
			} else if(!this.state.endsIn && !this.state.startsIn){
				tournamentNameWidth = '238px';
			}

			return <div className="common-header row">
				{
					this.props.leftPanel && <div className="col-auto nopadding left-panel">
						<LeftPanel />
					</div>
				}
				<div className={`details-actions ${this.props.showCategoryLabel ? 'fade-in' : ''}`} ref={this.actionLabel}>
				{
					this.props.link && <span className={`action-name ${ this.props.isJackpotView || hasFreeRounds || isBigImageView || this.props.tournamentPreview || this.props.tournamentComponent ? 'rewards' : '' }`}>
						<div>{ this.props.link.get('name') }</div>
					</span>
				}
				</div>
				<div className="col d-flex align-items-center nopadding">
				{
					!hasFreeRounds && !this.props.tournamentPreview && this.props.link &&
						<span className={`label ${ headerClass } ${ link } ${ isBigImageView ? 'tournaments' : ''}`}>
						{ this.props.link.get('name') }
					</span>
				}
				{
					hasFreeRounds && <span className={`label rewards ${headerClass} ${hasFreeRounds ? link : ''}`}>
						{ Translations.claimYourRewards }
					</span>
				}
				{
						this.props.tournamentPreview && <Fragment>
								<span className={`tournament-name ${headerClass}`}>
										<div className="name-header">
											<div className="name" style={{ maxWidth: tournamentNameWidth }}>{ headerName }</div>
											{
												this.state.startsIn && <div className="status starts-in"> {Translations.comingSoon}</div>
											}
											{
												this.state.endsIn && !this.state.startsIn && <div className="status ends-in"> {Translations.ongoing}</div>
											}
											{
												!this.state.endsIn && !this.state.startsIn && <div className="status ended"> {Translations.ended}</div>
											}
										</div>
										<div className="row">
											<div className="col-auto tournament-info">
												<div>{`${Translations.prizePool} ${currencyFormatter( this.props.tournamentPreview.get('prizePool'), {currency: this.props.tournamentPreview.get('currency')})}`}</div>
												{
														this.state.startsIn && <div>{`${Translations.startsIn} ${this.state.startsIn.d}${Translations.dateD} : ${this.state.startsIn.h}${Translations.dateH}: ${this.state.startsIn.m}${Translations.dateM}`}</div>
												}
												{
														this.state.endsIn && !this.state.startsIn && <div>{`${Translations.endsIn}  ${this.state.endsIn.d}${Translations.dateD}: ${this.state.endsIn.h}${Translations.dateH}: ${this.state.endsIn.m}${Translations.dateM}`}</div>
												}
											</div>
											{
												this.props.detailsAvailable && <div className="col-auto d-flex align-items-center nopadding tournament-info">
													<InfoIcon className="info-icon" onClick={(event) => {
														event.stopPropagation();
														this.props.onInfoClick();
														}}/>
													</div>
											}
											{
												this.props.rightPanel && <div className={`col-auto nopadding right-panel ${this.props.leftPanel ? 'leftmargin' : 'rightmargin'}`}>
													<RightPanel />
												</div>
											}
										</div>
								</span>

						</Fragment>
				}
				</div>

				{
					!this.props.tournamentPreview && this.props.rightPanel &&
					<>
						<div className="col-auto nopadding right-panel">
							<RightPanel />
						</div>
					</>
				}
		</div>

	}
}

export default CommonHeader;