import React, {Component} from 'react';
import GameInfo2 from 'component/game/GameInfo2';
import { ReactComponent as ExpandIcon} from 'assets/icons/expand-icon.svg';
import { ReactComponent as CloseIcon} from 'assets/icons/x-icon.svg';

class GameDetails extends Component {

    render() {
        let {smScreenSize} = this.props;

        return (
        <div className={`game-content-details ${this.props.gameDetailsOpened ? 'details-opened': ''}`}>
            {
              !smScreenSize && <span className="game-content-details-btn" style={
                this.props.skin ?
                  { right : this.props.gameDetailsOpened ? '330px' : '5px'}
                  : { left : this.props.gameDetailsOpened ? '330px' : '5px'}
                }

              onClick={this.props.onGameDetailsClick}
              >
                { this.props.gameDetailsOpened ? <CloseIcon
                    width="35"
                    /> :
                    <ExpandIcon width="35" />
                }
            </span>
            }
            { (this.props.gameDetailsOpened || smScreenSize || window.w) && <div className={`game-content-details-modal
              ${!smScreenSize ? 'game-content-details-modal-default': 'game-content-details-modal-sm'}
              ${this.props.skin ? 'skin-right' : 'skin-left'}`}
              ref='GAME_FRAME'>
              <GameInfo2 className={` ${(this.props.params.playtype === "launch.real" ||
                  this.props.playType === "launch.demo") ? 'playing' : ''}`}
                  details={this.props.details}
                  params={this.props.match.params }
                  match={this.props.match}
                  fetchGameUrl={ this.props.fetchGameUrl }
                  onPlayClick={ this.props.onPlayClick }
                  playType={this.props.playType}
                  relatedLinks={this.props.relatedLinks}
                  location={this.props.location}
                  navigateTo={ this.props.navigateToUrl }
                  history={ this.props.history }
                />
              </div>
            }
          </div>
        );
    }
}

export default GameDetails