import {
    ServerSentEventTypes
} from 'actions/types';

export function initServerSent(route) {
    return (dispatch, getState) => {
        dispatch({
            type: ServerSentEventTypes.INIT_SERVER_SENT_EVENT,
            payload: {
                route
            }
        })
        let eSource = new EventSource(route.get('href'));
        
        let locale = getState().security.get('locale').substring(0, 2);
        let sessionLink = `${getState().security.get('sessionId')}-${locale}`
        
        eSource.onopen = (e) => console.log("open");

        eSource.addEventListener(sessionLink, function(e) {
            
            let payload = {
                type: JSON.parse(e.data).type,
                message: JSON.parse(JSON.parse(e.data).payload),
                language: JSON.parse(e.data).language
            }

            if(payload.type !== 'ping') {
                dispatch({
                    type: ServerSentEventTypes.MESSAGE_SERVER_SENT_EVENT,
                    payload
                })
            }
        });
    }
}