import React, { Component, Fragment } from 'react';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';
import {
	fromJS
} from "immutable";
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-2.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import Translations from 'translations';

import 'containers/LeaderBoard/styles.scss';

class LeaderBoard extends Component {
	constructor(props) {
		super(props)
		this.onExpandHandler = this._onExpandHandler.bind(this);
		this.state = {
			skeleton: [1,2,3],
			result: null,
			expanded: []
		}
	}

	_onExpandHandler(key) {
		let index = this.state.expanded.indexOf(key)
		let expanded = this.state.expanded;
		if(index > -1) {
			expanded.splice(index, 1)
		} else {
			expanded.push(key)
		}
		this.setState({
			expanded
		})

	}

	componentDidMount() {
		this.props.fetchTournamentResults(this.props.route)
		.then(resp => {
			let imResp = fromJS(resp)
			this.setState({
				result: imResp
			})

			return imResp
		})
		.then(resp => {
			this.props.setTournamentResults(resp)
			return resp
		})
	}

	render() {

		return <div className="leaderboard">
			<div className='row'>
			{
				!this.state.result && this.state.skeleton.map(key =>
				{
					return <div key={key} className='col col-table'>
						<table className='table table-bordered'>
							<thead className='table-dark header'>
								<tr>
									<th colSpan={4} className="text-center">
										<span className='dummy'>XXXXXXXX</span>
									</th>
								</tr>
							</thead>
							<tbody className='table-dark'>
								<tr>
									<td colSpan={4} className="date-status ">
										<span className='dummy'>XXXXXX</span>
									</td>
								</tr>
								<tr>
									<th><span className='dummy'>XXXX</span></th>
									<th><span className='dummy'>XXXXXX</span></th>
									<th><span className='dummy'>XXXXXX</span></th>
									<th><span className='dummy'>XXXXXXXX</span></th>
								</tr>
								<tr>
									<td className='text-left'><span className='dummy'>XX</span></td>
									<td className='text-center'><span className='dummy'>XXXXXX</span></td>
									<td className='text-right'><span className='dummy'>XXXXX</span></td>
									<td className='text-right'><span className='dummy'>XXXXXXXXX</span></td>
								</tr>
							</tbody>
						</table>
					</div>
				})
			}

			{
				this.state.result && this.state.result.get('leaderboards') && this.state.result.get('leaderboards').map((leaderboard, key) => {
					let rankings = leaderboard.get('rankings');
					let isCenterSingle = (key === 0);

				return <Fragment key={key}>
						{
							isCenterSingle && <div className='col col-spacer'>&nbsp;</div>
						}
						<div className={`col col-table`}>
							<table className='table table-bordered'>
								<thead className='table-dark header'>
									<tr>
										<th colSpan={4}>
											{
												(leaderboard.get('group') === 0) && <span>{leaderboard.get('prizeLabel')}</span>
											}
											{
												(leaderboard.get('group') > 0) && <span>{`${Translations.day} ${leaderboard.get('group')}`}</span>
											}
										</th>
									</tr>
								</thead>
								<tbody className={`table-dark ${(this.state.expanded.indexOf(key) > -1) ? '' : 'collapsed'} `}>
									<tr>
										<td colSpan={4} className="date-status">
											{ this.props.locale === 'ja-JP' ?
												<span>{`${leaderboard.get('from')} ${leaderboard.get('to')} ${Translations.to}`} </span>
												:
												<span>{`${leaderboard.get('from')} ${Translations.to} ${leaderboard.get('to')}`} </span>
											}
											{
												(leaderboard.get('status') === "OPEN") && <span className='status open'>
													{Translations.comingSoon}
												</span>
											}
											{
												(leaderboard.get('status') === "IN_PROGRESS") && <span className='status inprogress'>
													{Translations.ongoing}
												</span>
											}
											{
												(leaderboard.get('status') === "COMPLETED") && <span className='status completed'>
													{Translations.expired}
												</span>
											}
										</td>
									</tr>
									<tr>
										<th>{Translations.rank}</th>
										<th>{Translations.player}</th>
										<th>{Translations.points}</th>
										<th>{`${Translations.prize} (${this.props.symbol})`}</th>
									</tr>
									{
										leaderboard.get('rankings') && rankings.map((ranking, key2) => {
											return <tr className={`${ranking.get('currentPlayer') ? "current-player" : ""} ranking`} key={key2}>
												<td className='text-center'>{ranking.get('rank')}</td>
												<td className='text-center'>{ranking.get('playerId')}</td>
												<td className='text-center'>{ranking.get('points')}</td>
												<td className='text-right'>{ranking.get('prize')}</td>
											</tr>
										})
									}

									{
										leaderboard.get('rankings') && leaderboard.get('rankings').size === 0 && <tr className='d-none'>
											<td></td><td></td><td></td><td></td>
										</tr>
									}
								</tbody>
							</table>
							{
								rankings && (rankings.size > 5) && <div className="show-more row">
									<div className='col text-center'>
										<button className="btn btn-dark" onClick={() => {
											this.onExpandHandler(key)
										}}>
											{ (this.state.expanded.indexOf(key) > -1) ? Translations.showLess : Translations.showMore }
											{ (this.state.expanded.indexOf(key) > -1) ? <ArrowUp />  :  <ArrowDown />  }
										</button>
									</div>
								</div>
							}
						</div>
						{
							isCenterSingle && <div className='col col-spacer'>&nbsp;</div>
						}
					</Fragment>
				})
			}
			</div>
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	let symbol
	try {
		symbol = String.fromCharCode(parseInt(state.links.getIn(["symbol", 0]),16))
	} catch (err) {}
	return {
		displayName: state.user.get('displayName'),
		pages: state.urls.get('pages'),
        locale: state.config.getIn( [ 'locale' ] ),
		symbol
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			fetchTournamentResults: (route) => dispatchProps.fetchTournamentResults(route),
			setTournamentResults: (data) => dispatchProps.setTournamentResults(data)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LeaderBoard)