import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
		BetHistoryTypes
} from '../actions/types';

export const defaultValue = fromJS({
	history: null,
	loadedItems: {}
})

export const betHistory = createReducer(defaultValue,{
    [BetHistoryTypes.SET_BET_HISTORY](state, { payload }) {
			let newState = state.setIn(['history'], payload.get('data'))
			newState = newState.setIn(['loadedItems', payload.getIn(['data', 'page'])], payload.getIn(['data', 'items']))
			return newState
		},
		[BetHistoryTypes.CLEAR_BET_HISTORY](state, action) {
			let newState = defaultValue;
			return newState;
		}
})