import React, {
  Component
} from 'react';

class Play extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color,
      circleRadius: props.circleRadius ? props.circleRadius : "282.43"
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.props.className}>
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="-2.85 -218.458 714.944 713.01"
              style={{
                      "enableBackground" : "new 0 0 528 528"
                }} xmlns="http://www.w3.org/2000/svg">

              <path style={{
                    "fill":"#FFFFFF"
                }} d="M 353.891 490.777 C 549.024 490.777 707.781 332.256 707.781 137.391 C 707.781 -57.475 549.024 -215.996 353.891 -215.996 C 158.757 -215.996 0 -57.475 0 137.391 C 0 332.256 158.757 490.777 353.891 490.777 Z M 353.891 -194.548 C 537.206 -194.548 686.332 -45.635 686.332 137.391 C 686.332 320.416 537.206 469.328 353.891 469.328 C 170.574 469.328 21.449 320.416 21.449 137.391 C 21.449 -45.635 170.574 -194.548 353.891 -194.548 Z"/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <g/>
              <path d="M 687.912 138.173 A 335.038 335.038 0 0 1 352.874 473.211 A 335.038 335.038 0 0 1 17.836 138.173 A 335.038 335.038 0 0 1 352.874 -196.865 A 335.038 335.038 0 0 1 687.912 138.173 Z"
                style={{
                      "fill":"#b40000"
                  }}/>
              <polygon style={{
                    "fill":"#FFFFFF"
                }} points="270.236 12.98 270.236 286.304 526.224 149.647"></polygon>
            </svg>

            
            {
                this.props.label && <span
                    style={{
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default Play;