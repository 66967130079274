import axios from 'axios';
import {
  SearchTypes
} from 'actions/types';

export function search(params) {
	return (dispatch, getState) => {
		var host = getState().config.get( 'host' );
    var headers = getState().config.get( 'headers' ).toJSON();
    var href = '/services/gl/search-games';
		var url = `${host}${href}`
    var method = 'GET'

		dispatch( {
      type: SearchTypes.SEARCH,
      payload: {
        method: method,
        headers: headers,
        url: url,
        params: params
      }
    } )

		return axios( {
			method,
			headers,
			url,
			params
		} )
		.then(response => {
			return response.data
		})
	}
}

export function fetchSearchQuickLinks(route, params) {
	return (dispatch, getState) => {
		var host = getState().config.get( 'host' );
		var headers = getState().config.get( 'headers' ).toJSON();
		var href = route.get('href');
		var url = `${host}${href}`
		var method = 'GET'

		dispatch( {
			type: SearchTypes.FETCH_SEARCH_QUICK_LINKS,
			payload: {
				method: method,
				headers: headers,
				url: url
			}
		} )

		return axios( {
			method,
			headers,
			url,
		} )
		.then(response => {
			return response.data
		})
	}
}

export function setSearchQuickLinks(data) {
	return (dispatch, getState) => {
		dispatch( {
			type: SearchTypes.SET_SEARCH_QUICK_LINKS,
			payload: data
		})

		return data;
	}
}

export function fetchSearchQuick(route, params) {
	return (dispatch, getState) => {
		var host = getState().config.get( 'host' );
		var headers = getState().config.get( 'headers' ).toJSON();
		var href = route.get('href');
		var url = `${host}${href}`;
		var method = route.get('method');

		dispatch({
			type: SearchTypes.FETCH_SEARCH_QUICK,
			payload: {
				method,
				headers,
				url,
				params
			}
		})

		return axios( {
			method,
			headers,
			url,
			params
		} )
		.then(response => {
			return response.data
		})
	}
}

export function setSearchQuick(data) {
	return (dispatch, getState) => {
		dispatch( {
			type: SearchTypes.SET_SEARCH_QUICK,
			payload: data
		})

		return data;
	}
}
