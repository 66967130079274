import React, { Component } from 'react';
import iconDefault from 'assets/images/iconDefault.png';
import 'component/listItemSkeleton/SimilarTypeSkeleton/styles.scss';

class SimilarTypeSkeleton extends Component {
  render() {
      return <div
          className={`similar-type-skeleton ${this.props.placeholderType}`}>
          <div className='row'>
            <div className='col-5'>
              <img src={iconDefault} className="w-100" alt="preloading"></img>
            </div>
            <div className='col-7 details'>
              <div className="name"><div className="dummy"></div></div>
              <div className="provider"><div className="dummy"></div></div>
              <div className="game-type"><div className="dummy"></div></div>
            </div>
          </div>
      </div>
  }
}

export default SimilarTypeSkeleton;