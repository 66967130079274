import { Component } from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';
import * as queryString from 'query-string';

class Exit extends Component {
    
    componentWillReceiveProps( nextProps ) {
        if(nextProps.pages !== this.props.pages &&
            nextProps.pages
        ) {
            let { redirect } = queryString.parse(this.props.location.search);                
            this.props.onGameClose({
                location: this.props.location
            })
            if(redirect) {
                let decodeUrl = decodeURIComponent(atob(redirect));
                this.props.navigateTo(decodeUrl);
                return
            }
            window.close()
        }
    }

    render() {
        return null
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        sessionId: state.security.get( 'sessionId' ),
        pages: state.pages,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
        navigateTo: ( path ) => ownProps.history.push( path ),
        onGameClose: params => dispatchProps.onGameClose(params)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Exit);