import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class Discover extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
      this.setState( {
        width: nextProps.width,
        height: nextProps.height
      } )
  }

  render() {
    return <div className={this.props.className}>
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                    <linearGradient
                        id="categorySelected"
                        gradientUnits="userSpaceOnUse"
                        x1="0%" y1="100%" x2="0%" y2="0%"
                        >
                        <stop offset="0%" stopColor="#f9bc3c"
                            stopOpacity="1"/>
                        <stop offset="19%" stopColor="#fde635"
                            stopOpacity="1"/>
                        <stop offset="100" stopColor="#f9bc3c"
                            stopOpacity="1"/>
                    </linearGradient>
                    <linearGradient
                        id="categoryHover"
                        gradientUnits="userSpaceOnUse"
                        x1="0%" y1="100%" x2="0%" y2="0%"
                        >
                        <stop offset="0%" stopColor="#f9c24f"
                            stopOpacity="1"/>
                        <stop offset="19%" stopColor="#fde849"
                            stopOpacity="1"/>
                        <stop offset="100" stopColor="#f9c24f"
                            stopOpacity="1"/>
                    </linearGradient>
                    <linearGradient
                        id="categoryNormal"
                        gradientUnits="userSpaceOnUse"
                        x1="0%" y1="100%" x2="0%" y2="0%"
                        >
                        <stop offset="0%" stopColor="#634b18"
                            stopOpacity="1"/>
                        <stop offset="19%" stopColor="#655c15"
                            stopOpacity="1"/>
                        <stop offset="100" stopColor="#634b18"
                            stopOpacity="1"/>
                    </linearGradient>
                </defs>
              <path d="M7 4C7 5.38071 5.88071 6.5 4.5 6.5C3.11929 6.5 2 5.38071 2 4C2 2.61929 3.11929 1.5 4.5 1.5C5.88071 1.5 7 2.61929 7 4Z" fill="#000000"/>
              <path d="M14.5 4C14.5 5.38071 13.3807 6.5 12 6.5C10.6193 6.5 9.5 5.38071 9.5 4C9.5 2.61929 10.6193 1.5 12 1.5C13.3807 1.5 14.5 2.61929 14.5 4Z" fill="#000000"/>
              <path d="M19.5 6.5C20.8807 6.5 22 5.38071 22 4C22 2.61929 20.8807 1.5 19.5 1.5C18.1193 1.5 17 2.61929 17 4C17 5.38071 18.1193 6.5 19.5 6.5Z" fill="#000000"/>
              <path d="M7 12C7 13.3807 5.88071 14.5 4.5 14.5C3.11929 14.5 2 13.3807 2 12C2 10.6193 3.11929 9.5 4.5 9.5C5.88071 9.5 7 10.6193 7 12Z" fill="#000000"/>
              <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" fill="#000000"/>
              <path d="M22 12C22 13.3807 20.8807 14.5 19.5 14.5C18.1193 14.5 17 13.3807 17 12C17 10.6193 18.1193 9.5 19.5 9.5C20.8807 9.5 22 10.6193 22 12Z" fill="#000000"/>
              <path d="M4.5 22.5C5.88071 22.5 7 21.3807 7 20C7 18.6193 5.88071 17.5 4.5 17.5C3.11929 17.5 2 18.6193 2 20C2 21.3807 3.11929 22.5 4.5 22.5Z" fill="#000000"/>
              <path d="M14.5 20C14.5 21.3807 13.3807 22.5 12 22.5C10.6193 22.5 9.5 21.3807 9.5 20C9.5 18.6193 10.6193 17.5 12 17.5C13.3807 17.5 14.5 18.6193 14.5 20Z" fill="#000000"/>
              <path d="M19.5 22.5C20.8807 22.5 22 21.3807 22 20C22 18.6193 20.8807 17.5 19.5 17.5C18.1193 17.5 17 18.6193 17 20C17 21.3807 18.1193 22.5 19.5 22.5Z" fill="#000000"/>
            </svg>
            {
                this.props.label && <div
                    style={{
                        color: this.state.color
                    }}
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default Discover;