import createReducer from "../utils/createReducer";
import {
  SecurityTypes,
  LoginTypes,
  LocalStorageTypes,
  CategoryTypes,
  ApplicationTypes,
  GameTypes,
  WindowTypes,
  LinkTypes,
} from "../actions/types";

import { fromJS } from "immutable";

function getHost() {
  let host = window.location.origin
    ? window.location.origin
    : window.location.protocol + "//" + window.location.protocol;
  return host;
}

export const defaultValue = fromJS({
  operatorId: "keyops",
  host: getHost(),
  appId: null,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    dataType: "json",
    Authorization: null,
    "Accept-Language": "en-US",
    "device-type": null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  route: {
    href: "/services/gl/config",
    method: "GET",
    rel: "config",
    name: "Config",
  },
  currentDomainIndex: 0,
  currentLoginIndex: 0,
  currentCashierUrlIndex: 0,
  locale: null,
  selectedLocaleIndex: 0,
  filtersVisible: false,
  gameFullscreen: false,
  sorting: "popularity",
  hideSplash: false,
});

export const config = createReducer(defaultValue, {
  [SecurityTypes.TOKEN_VERIFIED](state, { payload }) {
    let newState = state.setIn(
      ["headers", "Authorization"],
      `Bearer ${payload.sessionId}`
    );
    return newState;
  },
  [LoginTypes.LOGOUT](state, { payload }) {
    let newState = state.merge(defaultValue);
    return newState;
  },
  [LocalStorageTypes.RESTORE_SECURITY](state, { payload }) {
    let newState = state.setIn(
      ["headers", "Authorization"],
      `Bearer ${payload.sessionId}`
    );
    return newState;
  },
  [SecurityTypes.TOKEN_REJECTED](state, { payload }) {
    let newState = state.merge(defaultValue);
    return newState;
  },
  [CategoryTypes.SHOW_FILTERS](state, { payload }) {
    let newState = state.setIn(["filtersVisible"], payload.filtersVisible);
    return newState;
  },
  [CategoryTypes.SET_SORTING](state, { payload }) {
    let newState = state.setIn(["sorting"], payload.sorting);
    return newState;
  },
  [ApplicationTypes.INITIALIZE_APPLICATION](state, { payload }) {
    let newState = state.setIn(["locale"], payload.locale);
    newState = newState.setIn(["headers", "Accept-Language"], payload.locale);
    newState = newState.setIn(
      ["headers", "Authorization"],
      `Bearer ${payload.sessionId}`
    );
    newState = newState.setIn(["headers", "uuid"], `${payload.uuid}`);
    newState = newState.setIn(["headers", "gs"], `${payload.gs}`);
    return newState;
  },
  [GameTypes.SET_GAME_FULSCREEN](state, { payload }) {
    let newState = state.setIn(["gameFullscreen"], payload.gameFullscreen);
    return newState;
  },
  [WindowTypes.SET_BROWSER](state, { payload }) {
    let appId =
      payload.appId === "null" || payload.appId === undefined
        ? null
        : payload.appId;
    let hideSplash =
      payload.hideSplash === "null" || payload.hideSplash === undefined
        ? false
        : payload.hideSplash;
    let newState = state.setIn(["hideSplash"], hideSplash);
    let deviceType = payload.isMobile ? "MOBILE" : "DESKTOP";
    newState = newState.setIn(["appId"], appId);
    newState = newState.setIn(["headers", "device-type"], deviceType);
    return newState;
  },
  [LinkTypes.FETCH_LINKS](state, { payload }) {
    return state;
  },
  [LinkTypes.SET_LINKS](state, { payload }) {
    let { gs, uuid } = payload;
    let newState = state.setIn(["headers", "gs"], gs);
    newState = newState.setIn(["headers", "uuid"], uuid);
    return newState;
  },
});
