import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    PagesTypes
} from '../actions/types';

export const defaultValue = null

export const pages = createReducer(defaultValue,{
    [PagesTypes.WL_SET_PAGES](state, { payload }) {
        let newState = fromJS(payload)
        //localStorage.setItem('pages', JSON.stringify(newState.toJSON()))
        return newState
    }
})