import React, { Component } from 'react';

import 'component/animation/Loading/styles.css';

class Loading extends Component {

    render() {
        return <div className="lds-ellipsis">
            <div style={this.props.style}></div>
            <div style={this.props.style}></div>
            <div style={this.props.style}></div>
            <div style={this.props.style}></div>
        </div>
    }
}

export default Loading;