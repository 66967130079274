import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import Translations from "translations";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down-2.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";

import { ShowMoreFilterComponent } from "component/filters";
import { is } from "immutable";

import "containers/ShowMoreFilters/styles.scss";

class ShowMoreFilters extends Component {
  constructor(props) {
    super(props);
    this.accordionRef = createRef();
    this.onFilterClickHandler = this._onFilterClickHandler.bind(this);
    this.onFilterClearHandler = this._onFilterClearHandler.bind(this);
    this.fetchFilters = this._fetchFilters.bind(this);
    this.state = {
      activeFilter: props.activeFilter,
    };
  }

  _fetchFilters(route, filters) {
    let params = new URLSearchParams();
    if (filters) {
      filters.forEach((filter) => {
        //if(filter.get('id') !== "providers") {
        filter.get("values").forEach((value) => {
          if (value.get("toggled") === true) {
            params.append(filter.get("id"), value.get("id"));
          }
        });
        //}
      });
    }
    this.props.fetchFilters(route, params);
  }

  componentWillMount() {
    if (this.props.filtersLink && !this.props.filters) {
      this.fetchFilters(this.props.filtersLink);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeFilter !== this.props.activeFilter) {
      if (nextProps.activeFilter === "sortBy") {
        this.accordionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
      this.setState({
        activeFilter: nextProps.activeFilter,
      });
    }

    if (
      nextProps.filtersIsLoading === true &&
      nextProps.filtersIsLoading !== this.props.filtersIsLoading
    ) {
      this.fetchFilters(nextProps.filtersLink, nextProps.filters);
    }

    if (
      !is(nextProps.filtersLink, this.props.filtersLink) &&
      nextProps.filtersLink &&
      !nextProps.filters
    ) {
      this.fetchFilters(nextProps.filtersLink, nextProps.filters);
    }
  }

  _onFilterClickHandler(subCategory, value) {
    this.props.setActiveFilter(this.props.filtersLink, subCategory, value);
  }

  _onFilterClearHandler(subCategory) {
    this.props.clearFilterByCategory(
      this.props.filtersLink.get("rel"),
      subCategory
    );
  }

  render() {
    return (
      <div>
        <div
          className={`show-more-filters-container ${
            this.props.activeFilter ? "visible" : ""
          } ${this.props.isMobile ? "mobile" : "desktop"}`}
          onClick={() => {
            this.setState({
              activeFilter: null,
            });
            this.props.onClose();
            this.props.showFilters(false);
          }}
        />
        <div
          className={`show-more-filters ${
            this.props.activeFilter ? "visible" : ""
          } ${this.props.isMobile ? "mobile" : "desktop"}`}
          onClick={() => {
            this.setState({
              activeFilter: null,
            });
            this.props.onClose();
            this.props.showFilters(false);
          }}
        >
          <div
            className={`content  ${this.props.activeFilter ? "fade-in" : ""}`}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="content-main">
              <div className="header row nopadding">
                {this.props.isMobile && (
                  <div className="horizontal-bar">&nbsp;</div>
                )}
                <div
                  className="col-3 clear-all d-flex align-items-center"
                  onClick={this.onFilterClearAllHandler}
                >
                  {this.props.enableClearAll && (
                    <span>{Translations.clearAll}</span>
                  )}
                </div>
                {this.props.filtersLink && (
                  <div className="col d-flex align-items-center">
                    <div className="w-100 text-center label">
                      {this.props.filtersLink.get("name")}
                    </div>
                  </div>
                )}
                <div className="col-3 d-flex align-items-center">
                  <div
                    className="close-btn w-100 text-right"
                    onClick={() => {
                      this.setState({
                        activeFilter: null,
                      });
                      this.props.showFilters(false);
                      this.props.onClose();
                    }}
                  >
                    <span className="label">{Translations.close}</span>
                  </div>
                </div>
              </div>

              <div className="body">
                {this.props.sorts && (
                  <div className="accordion sort">
                    <div className="accordion-item" ref={this.accordionRef}>
                      <div
                        className="accordion-header"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({
                            activeFilter:
                              "sortBy" !== this.state.activeFilter
                                ? "sortBy"
                                : "none",
                          });
                        }}
                      >
                        <div className="accordion-button w-100">
                          <div className="row">
                            <div className="col">{Translations.sortBy}</div>
                            <div className="col-auto">
                              {this.state.activeFilter !== "sortBy" && (
                                <ArrowDown />
                              )}
                              {this.state.activeFilter === "sortBy" && (
                                <ArrowUp />
                              )}
                            </div>
                          </div>
                          {this.state.activeFilter !== "sortBy" && (
                            <div className="sub">
                              {this.props.sorts.map((sort) => {
                                return this.props.sortBy === sort.get("id")
                                  ? sort.get("name")
                                  : null;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`accordion-collapse collapse ${
                          this.state.activeFilter === "sortBy" ? "show" : ""
                        }`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div
                            className="row no-padding sort"
                            role="group"
                            aria-label="Basic outlined example"
                          >
                            {this.props.sorts.map((sort, key) => {
                              return (
                                <div
                                  className={`col-4 sort-by text-center ${
                                    this.props.sortBy === sort.get("id")
                                      ? "toggled"
                                      : ""
                                  }`}
                                  key={key}
                                >
                                  <div
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      this.props.setCategorySorting(
                                        this.props.filtersLink,
                                        sort.get("id")
                                      );
                                    }}
                                  >
                                    {sort.get("name")}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.filters && (
                  <div className="accordion">
                    {this.props.filters.map((filter, key) => {
                      if (filter.get("values").size === 1) {
                        return null;
                      }
                      return (
                        <ShowMoreFilterComponent
                          filter={filter}
                          key={key}
                          activeFilter={this.state.activeFilter}
                          setActiveFilter={(value) => {
                            this.setState({
                              activeFilter: value,
                            });
                          }}
                          onFilterClickHandler={this.onFilterClickHandler}
                          isMultiSelectable={filter.get('isMultiSelectable')}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="footer">
                <div className="row">
                  <div className="col text-center">
                    <span
                      className="badge badge-secondary"
                      onClick={() => {
                        this.props.clearAllFilters(
                          this.props.filtersLink.get("rel")
                        );
                      }}
                    >
                      {Translations.clearAll}
                    </span>
                  </div>
                  <div className="col text-center">
                    <span
                      className="btn btn-danger"
                      onClick={() => {
                        this.setState({
                          activeFilter: null,
                        });
                        this.props.onClose();
                        this.props.showFilters(false);
                      }}
                    >
                      {Translations.view}
                      {this.props.games
                        ? ` (${this.props.games.get("totalCount")})`
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let filters;
  let sorts;
  let sortBy;
  let filtersIsLoading;
  try {
    filters = state.filters.getIn([
      ownProps.filtersLink.get("rel"),
      "data",
      "filters",
    ]);
    filtersIsLoading = state.filters.getIn([
      ownProps.filtersLink.get("rel"),
      "isLoading",
    ]);
    sorts = state.filters.getIn([
      ownProps.filtersLink.get("rel"),
      "data",
      "sorts",
    ]);
    sortBy = state.filters.getIn([
      ownProps.filtersLink.get("rel"),
      "data",
      "sortBy",
    ]);
  } catch (err) {}

  return {
    isMobile: state.window.get("isMobile"),
    filters,
    filtersIsLoading,
    sorts,
    sortBy,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    clearAllFilters: (category) => dispatchProps.clearAllFilters(category),
    fetchFilters: (route, params) => dispatchProps.fetchFilters(route, params),
    showFilters: (bol) => dispatchProps.showFilters(bol),
    setCategorySorting: (route, sorting) =>
      dispatchProps.setCategorySorting(route, sorting),
    setActiveFilter: (category, subCategory, filter) =>
      dispatchProps.setActiveFilter(category, subCategory, filter),
    logout: () => dispatchProps.logout(),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ShowMoreFilters);
