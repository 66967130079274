import React, {
  Component
} from 'react';

class Heart extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.props.width || this.state.width}px`}
                height={`${this.props.height*1 || this.state.height*1}px`}
                viewBox="0 0 530 530"
                style={{
                    "enableBackground" : "new 0 0 530 530"
                }}>
                <g>
                <path d="M255,489.6l-35.7-35.7C86.7,336.6,0,257.55,0,160.65C0,81.6,61.2,20.4,140.25,20.4c43.35,0,86.7,20.4,114.75,53.55
			            C283.05,40.8,326.4,20.4,369.75,20.4C448.8,20.4,510,81.6,510,160.65c0,96.9-86.7,175.95-219.3,293.25L255,489.6z" fill={this.state.color}></path>
                </g>
            </svg>
            {
                this.props.label && <span
                    style={{
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default Heart;