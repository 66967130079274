import CommonHeader from "component/common/Header";
import CommonHeaderDesktop from "component/common/HeaderDesktop";
import CommonMultiGameController from "component/common/MultiGameController";
import CommonGameController from "component/common/GameController";
export { CommonHeader };
export { CommonHeaderDesktop };
export { CommonMultiGameController };
export { CommonGameController };
export default Object.assign({
  CommonHeader,
  CommonHeaderDesktop,
  CommonMultiGameController,
  CommonGameController,
});
