import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class PlayNow extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(255,255,255)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 20,
      height: props.width ? props.width : 20,
      color: props.selected ? selectedColor : color,
      className: props.className ? props.className : ''
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.state.className} title={ this.props.title }>
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width={`${this.props.width || this.state.width}px`}
        height={`${this.props.height || this.state.height}px`}
        viewBox="0 0 200 200">
          <circle cx="100" cy="100" r="90" fill="none" strokeWidth="20" stroke={this.state.color }/>
          <polygon points="70, 55 70, 145 145, 100" fill={ this.state.color }/>
      </svg>

      {
          this.props.label && <span
              style={{
                  paddingBottom: "0.5em",
                  color: this.state.color,
                  top: 1,
                  position: "relative",
                  paddingLeft: "0.5em",
                  fontWeight: "bold",
                  textTransform: "uppercase"
              }}
              className="label"
              >
              { this.props.label }
          </span>
      }
  </span>
  }
}

export default PlayNow;