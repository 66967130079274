import React, { Component } from "react";
/* import {
	FlagIcon
} from 'component/icons'; */
import Translations from "translations";
import { ReactComponent as BackArrow } from "assets/icons/backArrow.svg";
import { ReactComponent as Checked } from "assets/icons/checked.svg";
import { ReactComponent as UnitedKingdomFlag } from "assets/flags/united-kingdom.svg";
import { ReactComponent as ChinaFlag } from "assets/flags/china.svg";
import { ReactComponent as JapanFlag } from "assets/flags/japan.svg";

import "component/menu/Language/styles.scss";

class Language extends Component {
  constructor(props) {
    super(props);
    this.setLocale = this._setLocale.bind(this);
    this.state = {
      show: false,
    };
  }

  getFlag() {
    let { locale } = this.props;

    let ActiveFlag;

    switch (locale) {
      case "zh-CN":
        ActiveFlag = ChinaFlag;
        break;
      case "ja-JP":
        ActiveFlag = JapanFlag;
        break;
      default:
        ActiveFlag = UnitedKingdomFlag;
        break;
    }

    return <ActiveFlag />;
  }

  _setLocale(locale) {
    if (this.props.setLocale) this.props.setLocale(locale);
  }

  render() {
    return (
      <div
        className={`language ${
          this.props.className ? this.props.className : ""
        } ${this.props.isMobile ? "mobile" : ""} ${
          this.props.show ? "show" : ""
        }`}
      >
        <div className="header">{Translations.changeLanguage}</div>
        <div className="content">
          <div>
            <div
              className="btn-group-vertical"
              role="group"
              aria-label="Vertical button group"
            >
              <button
                type="button"
                className="btn d-flex align-items-center"
                onClick={() => {
                  this.setLocale("en-US");
                }}
              >
                <label>{Translations.englishLanguage}</label>
                <span className="flag">
                  <UnitedKingdomFlag />
                </span>
                <span
                  className={`checked ${
                    this.props.locale === "en-US" ? "active" : ""
                  }`}
                >
                  <Checked />
                </span>
              </button>
              <button
                type="button"
                className="btn d-flex align-items-center"
                onClick={() => {
                  this.setLocale("zh-CN");
                }}
              >
                <label>{Translations.chineseLanguage}</label>
                <span className="flag">
                  <ChinaFlag />
                </span>
                <span
                  className={`checked ${
                    this.props.locale === "zh-CN" ? "active" : ""
                  }`}
                >
                  <Checked />
                </span>
              </button>

              <button
                type="button"
                className="btn d-flex align-items-center"
                onClick={() => {
                  this.setLocale("ja-JP");
                }}
              >
                <label>{Translations.japaneseLanguage}</label>
                <span className="flag">
                  <JapanFlag />
                </span>
                <span
                  className={`checked ${
                    this.props.locale === "ja-JP" ? "active" : ""
                  }`}
                >
                  <Checked />
                </span>
              </button>
            </div>
          </div>
        </div>

        <span
          className="backArrow"
          onClick={() => {
            this.props.onClose();
          }}
        >
          <BackArrow />
        </span>
      </div>
    );
  }
}

export default Language;
