import {
    UrlsTypes
} from 'actions/types';
import axios from 'axios';

export function fetchExit(route) {
    return (dispatch, getState) => {
        try {
            var headers = getState().config.get('headers');
            var host = getState().config.get('host');
            var method = route.get('method');
            var href = route.get('href');
            var url = `${host}${href}`;
        } catch (error) {
            throw (error)
        }

        dispatch({
            type: UrlsTypes.FETCH_EXIT,
            payload: Object.assign({
                headers,
                url,
                method
            })
        })

        return axios({
            headers: headers.toJSON(),
            method: method,
            url: url
        })
        .then(response => {
            return response.data
        })
        .catch(error => {
            let newError = {}
            if (!error.response) {
                newError = {
                    response: {
                        data: {
                            code: error.code,
                            message: error.message
                        }
                    }
                }
            }

            dispatch({
                type: UrlsTypes.ERROR_FETCH_EXIT,
                payload: !error.response ? newError : error
            })
            throw error
        })
    }
}

export function fetchUrls() {
    return (dispatch, getState) => {
        try {
            var headers = getState().config.get('headers');
            var host = getState().config.get('host');
            var route = getState().config.get('route');
            var href = route.get('href');
            var method = route.get('method');
            var url = `${host}${href}`;
        } catch (error) {
            throw (error)
        }

        dispatch({
            type: UrlsTypes.FETCH_URLS,
            payload: Object.assign({
                headers,
                url,
                method
            })
        })

        return axios({
            headers: headers,
            method: method,
            url: url
        })
        .then(response => {
            return dispatch(setUrls(response))
        })
        .catch(error => {
            let newError = {}
            if (!error.response) {
                newError = {
                    response: {
                        data: {
                            code: error.code,
                            message: error.message
                        }
                    }
                }
            }

            dispatch({
                type: UrlsTypes.ERROR_FETCH_URLS,
                payload: !error.response ? newError : error
            })
            throw error
        })
    }
}

export function setUrls(payload) {
    return {
        type: UrlsTypes.SET_URLS,
        payload: payload.data
    }
}