import HomeIcon from 'component/icons/Home';
import SlotIcon from 'component/icons/Slot';
import TableGameIcon from 'component/icons/TableGame';
import BingoIcon from 'component/icons/Bingo';
import CancelIcon from 'component/icons/Cancel';
import SliderIcon from 'component/icons/Slider';
import InstantWinIcon from 'component/icons/InstantWin';
import AlphabeticalIcon from 'component/icons/Alphabetical';
import ArrowSortIcon from 'component/icons/ArrowSort';
import PopularityIcon from 'component/icons/Popularity';
import DateAddedIcon from 'component/icons/DataAdded';
import LeftArrowIcon from 'component/icons/LeftArrow';
import RightArrowIcon from 'component/icons/RightArrow';
import VirtualSportsIcon from 'component/icons/VirtualSports';
import ScratchIcon from 'component/icons/Scratch';
import ShootingIcon from 'component/icons/Shooting';
import FullscreenIcon from 'component/icons/Fullscreen';
import MobileIcon from 'component/icons/Mobile';
import DesktopIcon from 'component/icons/Desktop';
import TabletIcon from 'component/icons/Tablet';
import ExitFullscreenIcon from 'component/icons/ExitFullscreen';
import PlayNow from 'component/icons/PlayNow';
import StarIcon from 'component/icons/Star';
import ClockIcon from 'component/icons/Clock';
import HeartIcon from 'component/icons/Heart';
import TrophyIcon from 'component/icons/Trophy';
import LiveCasinoIcon from 'component/icons/LiveCasino';
import EsportsIcon from 'component/icons/Esports';
import VirtualGamesIcon from 'component/icons/VirtualGames';
import PlayIcon from 'component/icons/Play';
import PlayDesktopIcon from 'component/icons/PlayDesktop';
import PlayHoveredIcon from 'component/icons/PlayHovered';
import HamburgerIcon from 'component/icons/Hamburger';
import RibbonIcon from 'component/icons/Ribbon';
import EditorsChoiceIcon from 'component/icons/EditorsChoice';
import LotteryIcon from 'component/icons/Lottery';
import SearchIcon from 'component/icons/Search';
import UserProfileIcon from 'component/icons/User';
import Menu from 'component/icons/Menu';
import DiscoverIcon from 'component/icons/Discover';



export {
  HomeIcon
}
export {
  SlotIcon
}
export {
  TableGameIcon
}
export {
  BingoIcon
}
export {
  CancelIcon
}
export {
  SliderIcon
}
export {
  InstantWinIcon
}
export {
  AlphabeticalIcon
}
export {
  PopularityIcon
}
export {
  DateAddedIcon
}
export {
  LeftArrowIcon
}
export {
  RightArrowIcon
}
export {
  VirtualSportsIcon
}
export {
  ScratchIcon
}
export {
  ShootingIcon
}
export {
  FullscreenIcon
}
export {
  MobileIcon
}
export {
  DesktopIcon
}
export {
  TabletIcon
}
export {
  ExitFullscreenIcon
}
export {
  PlayNow
}
export {
  StarIcon
}
export {
  ClockIcon
}
export {
  HeartIcon
}
export {
  TrophyIcon
}
export {
  LiveCasinoIcon
}
export {
  EsportsIcon
}
export {
  VirtualGamesIcon
}
export {
  PlayIcon
}
export {
  PlayDesktopIcon
}
export {
  PlayHoveredIcon
}
export {
  HamburgerIcon
}
export {
  RibbonIcon
}
export {
  EditorsChoiceIcon
}
export {
  LotteryIcon
}
export {
  SearchIcon
}
export {
  UserProfileIcon
}
export {
  Menu
}
export {
  ArrowSortIcon
}
export {
  DiscoverIcon
}

export default Object.assign( {
  HomeIcon,
  SlotIcon,
  TableGameIcon,
  BingoIcon,
  CancelIcon,
  SliderIcon,
  InstantWinIcon,
  AlphabeticalIcon,
  PopularityIcon,
  DateAddedIcon,
  LeftArrowIcon,
  RightArrowIcon,
  VirtualSportsIcon,
  ScratchIcon,
  ShootingIcon,
  FullscreenIcon,
  MobileIcon,
  DesktopIcon,
  TabletIcon,
  ExitFullscreenIcon,
  PlayNow,
  StarIcon,
  ClockIcon,
  HeartIcon,
  TrophyIcon,
  LiveCasinoIcon,
  EsportsIcon,
  VirtualGamesIcon,
  PlayIcon,
  PlayDesktopIcon,
  PlayHoveredIcon,
  HamburgerIcon,
  RibbonIcon,
  EditorsChoiceIcon,
  LotteryIcon,
  SearchIcon,
  UserProfileIcon,
  Menu,
  ArrowSortIcon,
  DiscoverIcon
} )