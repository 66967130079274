/**
 * THIS CONTAINER IS ONLY USED MY ESPORTS
 **/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    ActionCreator
} from 'actions';


import 'containers/GameCategory/styles.css';

class GameCategory extends Component {

    constructor(props) {
        super(props)
        this.getMessageFromIframe = this._getMessageFromIframe.bind(this);
        this.sendMessageToIframe = this._sendMessageToIframe.bind(this);
        this.state = {
            url: null,
            isLoading: true,
            windowName: "qtech",
            iframeMinHeight: 0,
            iframeHeight: 0,
            headerHeight: 0,
            footerHeight: 0,
        }
    }

    _sendMessageToIframe() {
        let iFrameElement = this.refs['GAME_IFRAME'];
        /* let scrollY = Math.abs(document.body.getBoundingClientRect().y) */
        if (iFrameElement) {
            this.refs['GAME_IFRAME'].contentWindow.postMessage({
                topic: "scroll",
                /* scroll: 0, */
                windowName: this.state.windowName,
                headerHeight: this.state.headerHeight,
                footerHeight: this.state.footerHeight,
                clientHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
                iframeHeight: this.state.iframeHeight,
                iframeMinHeight: this.state.iframeMinHeight
            }, "*")
        }
    }

    _getMessageFromIframe(event) {
        let iFrameElement = this.refs['GAME_IFRAME']
        if (iFrameElement) {
            if (
            event && typeof event.data === "object" && event.data.windowName && event.data.windowName === this.state.windowName && event.data.topic) {
                if (event.data.topic === "resize" && event.data.height) {
                    let iframeMinHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - this.state.headerHeight - this.state.footerHeight;
                    let iframeHeight = iframeMinHeight < event.data.height ? event.data.height : iframeMinHeight;
                    iFrameElement.height = `${iframeHeight} px`;
                    this.setState ({
                        iframeMinHeight,
                        iframeHeight
                    })
                    this.sendMessageToIframe();
                }

                /* if (event.data.topic === "url" && event.data.url) {
                    history.pushState({}, null, event.data.url);
                } */
            }
        }
    }

    componentDidMount() {
        window.addEventListener("message", this.getMessageFromIframe, false);
        window.addEventListener("scroll", this.sendMessageToIframe, false);
        window.addEventListener("resize", this.sendMessageToIframe, false);
        let { locale, sessionId, page } = this.props.match.params
        let params = null;

        if (this.props.isMobile) {
            params = {
                returnUrl: `${window.location.protocol}//${window.location.host}/${[locale, sessionId, 'games', page].join("/")}`
            }
        }

        this.props.fetchGameUrl(this.props.link, params)
        .then(response => {
            let { payload } = response;
            this.setState({
                isLoading: false,
                url: `${payload.data.url}${ this.props.hideSplash ? '&hideSplash=true' : ''}`
            })
            return null;
        })
    }

    componentWillUnmount() {
        try {
            window.removeEventListener("message", this.getMessageFromIframe, false);
            window.removeEventListener("scroll", this.sendMessageToIframe, false);
            window.removeEventListener("resize", this.sendMessageToIframe, false);
        } catch (err) {}
    }

    render() {
        return <div className="game-category-container">
            <iframe
                title="Game"
                className="game-frame"
                src={this.state.url}
                frameBorder="0"
                ref="GAME_IFRAME"
                style={{
                    height: `${this.state.iframeHeight}px`
                }}
                >
            </iframe>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        hideSplash: state.config.get('hideSplash'),
        isMobile: state.window.get('isMobile')
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
        fetchGameUrl: ( link, params ) => dispatchProps.fetchGameUrl( link, params )
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(GameCategory);