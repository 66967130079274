import {
    applyMiddleware,
    compose,
    createStore
} from 'redux';
import reducers from '../reducers';
import thunkMiddleware from 'redux-thunk';

export default function configureStore(initialState) {
    const enhancer = compose(
        applyMiddleware(
            thunkMiddleware
        )
    )
    return createStore(reducers, initialState, enhancer);
}