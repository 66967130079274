import createReducer from 'utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    SearchTypes
} from 'actions/types';

export const defaultValue = fromJS({
	quickLinks: []
})
export const search = createReducer(defaultValue, {
	[SearchTypes.SET_SEARCH_QUICK_LINKS](state, {payload}) {
		let newState = state.set('quickLinks', payload)
		return newState
	}
})