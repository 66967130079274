import React, {
    Component
  } from 'react';

class Trophy extends Component {
    constructor( props ) {
      super( props );

      let color = props.color ? props.color : "rgba(0,0,0,0.5)";
      let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

      this.state = {
        width: props.width ? props.width : 25,
        height: props.width ? props.width : 25,
        color: props.selected ? selectedColor : color
      }
    }

    componentWillReceiveProps( nextProps ) {
      if ( nextProps.selected !== this.props.selected ) {
        this.setState( {
          color: nextProps.selected ? nextProps.selectedColor : nextProps.color
        } )
      }
    }

    render() {
      return <span className={this.props.className}>
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                    width={`${this.props.width || this.state.width}px`}
                    height={`${this.props.height*1 || this.state.height*1}px`}
                    viewBox="0 0 475 475"
                    style={{
                        "enableBackground" : "new 0 0 475 475"
                    }}>
                    <g>
                    <path d="M467.087,99.355c-5.328-5.327-11.797-7.993-19.411-7.993h-82.228V63.955c0-12.562-4.466-23.313-13.415-32.261
                        c-8.945-8.945-19.704-13.417-32.264-13.417H155.318c-12.562,0-23.318,4.471-32.264,13.417
                        c-8.947,8.947-13.418,19.698-13.418,32.261v27.408H27.408c-7.612,0-14.083,2.663-19.414,7.993S0,111.158,0,118.772v36.544
                        c0,13.512,3.949,27.122,11.849,40.828c7.898,13.703,18.555,26.079,31.977,37.114c13.417,11.04,29.881,20.32,49.392,27.839
                        c19.512,7.519,40.019,11.755,61.529,12.703c7.996,10.284,17.034,19.321,27.122,27.121c7.235,6.471,12.228,13.366,14.989,20.704
                        c2.758,7.323,4.138,15.841,4.138,25.55c0,10.28-2.9,18.938-8.708,25.974c-5.802,7.05-15.082,10.568-27.834,10.568
                        c-14.277,0-26.98,4.332-38.116,12.99c-11.134,8.665-16.702,19.561-16.702,32.695v18.268c0,2.669,0.855,4.859,2.568,6.57
                        c1.714,1.711,3.901,2.566,6.567,2.566h237.544c2.67,0,4.853-0.855,6.571-2.566c1.704-1.711,2.56-3.901,2.56-6.57v-18.268
                        c0-13.135-5.564-24.027-16.707-32.695c-11.136-8.658-23.839-12.99-38.116-12.99c-12.748,0-22.032-3.519-27.829-10.568
                        c-5.804-7.036-8.699-15.693-8.699-25.974c0-9.713,1.376-18.227,4.134-25.55c2.766-7.338,7.762-14.229,14.989-20.704
                        c10.089-7.803,19.13-16.845,27.117-27.121c21.519-0.948,42.021-5.185,61.537-12.703c19.503-7.519,35.974-16.799,49.389-27.839
                        c13.422-11.039,24.079-23.411,31.977-37.114c7.898-13.706,11.848-27.315,11.848-40.828v-36.544
                        C475.082,111.162,472.416,104.688,467.087,99.355z M63.525,201.565c-17.987-15.987-26.979-31.405-26.979-46.252v-27.408h73.089
                        c0,39.784,7.04,75.09,21.126,105.924C103.927,228.311,81.514,217.552,63.525,201.565z M438.536,155.316
                        c0,14.847-8.986,30.269-26.973,46.255c-17.994,15.987-40.406,26.743-67.239,32.261c14.086-30.833,21.125-66.14,21.125-105.924
                        h73.087V155.316z"  fill={this.state.color}></path>
                    </g>
              </svg>
              {
                  this.props.label && <span
                      style={{
                          color: this.state.color,
                          top: 1,
                          position: "relative"
                      }}
                      className="label"
                      >
                      {this.props.label}
                  </span>
              }
          </span>
    }
  }

  export default Trophy;