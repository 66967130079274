import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class BingoIcon extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }

    if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
      this.setState( {
        width: nextProps.width,
        height: nextProps.height
      } )
  }

  render() {
    return <div className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height*1}px`}
                viewBox="0 0 850 850"
                style={{
                    "enableBackground" : "new 0 0 850 850"
                }}>
                <defs>
                    <mask id="bgmask" x="0" y="0">
                        <path d="M149.8,254.6c4-3.4,6-9,6-16.7,0-7.1-1.9-12.2-5.7-15.5s-10.4-4.8-19.7-4.8c-2,0-5.3.1-10.1.3V259q6.15.6,9.9.6C139.2,259.6,145.7,257.9,149.8,254.6Z"
                            fill="white"/>
                        <path d="M780.3,139q-.6-59.55-54.4-58.3h-608C82,79.9,63.8,99.3,63.5,139V719.9q.6,59.55,54.4,58.3h608c35.9.8,54.1-18.6,54.4-58.3ZM249.7,153.1h20.1V277.5H249.7ZM132.2,152c11.8,0,21,2.8,27.6,8.3s10,13.4,10,23.6c0,9.8-4.8,17.6-14.2,23.3,14.1,5.4,21.1,16.5,21.1,33.3q0,17-11.7,27c-7.8,6.7-17.9,10-30.2,10H100.1V153.2h0Q123.05,152,132.2,152ZM249.8,726.1h-101q-51.9,1-52.5-50.7V601.8H249.8Zm0-143.1H96.3V450.3H249.8Zm0-151H96.3V351.6q.6-51.75,52.5-50.7h101ZM411.9,726.1H268.7V601.8H411.9Zm0-143.1H268.7V450.3H411.9Zm0-151H268.7V300.9H411.9Zm11.4-152.9h-8.2L360,200.4v77.1H340.6V153.1h9.7l53.6,75v-75h19.4Zm149,447H429.1V601.8H572.3Zm0-143.1H429.1V450.3H572.3Zm0-151H429.1V300.9H572.3Zm10.5-166.1h-.1A50.43,50.43,0,0,1,564.2,276a74.57,74.57,0,0,1-22.6,3.6c-16.3,0-29.2-5.8-38.7-17.5s-14.3-27.1-14.3-46.3,5.2-34.8,15.6-46.8,24.3-18,41.9-18a45.34,45.34,0,0,1,31.8,12.2l-8.4,17.7a40.29,40.29,0,0,0-11-6.9c-5-2.3-9.2-3.4-12.8-3.4-11.2,0-20,4.1-26.4,12.4s-9.7,19.4-9.7,33.3c0,13.2,3.2,23.8,9.5,31.8S534,260,544.9,260c7.2,0,13.1-2.1,17.7-6.5V229H546.9V210.2h35.9ZM744.7,675.4q-.6,51.75-52.5,50.7h-101V601.8H744.7Zm0-92.4H591.2V450.3H744.7Zm0-151H591.2V300.9h101q51.9-1,52.5,50.7ZM732.1,261.9q-13.5,17.7-38.4,17.7c-15.2,0-26.9-5.8-35-17.5s-12.2-27.6-12.2-47.9c0-17.7,4.4-32.7,13.3-45s20.7-18.4,35.5-18.4c16.3,0,28.8,5.5,37.4,16.6s12.9,26.7,12.9,46.7S741.2,250.1,732.1,261.9Z"
                            fill="white"/>
                        <path d="M695.3,170.5c-9,0-15.9,3.9-20.7,11.8s-7.2,18.5-7.2,31.9c0,14.5,2.2,25.8,6.7,33.8s11,12,19.6,12c10.1,0,17.7-3.9,23-11.7s7.9-19.2,7.9-34C724.7,185,714.9,170.5,695.3,170.5Z"
                            fill="white"/>
                        <path d="M149.7,184.7c0-9.6-6-14.3-17.9-14.3-3.1,0-7,.1-11.5.4v29.8c3.1.2,6.4.3,9.8.3C143.1,201,149.7,195.5,149.7,184.7Z"
                            fill="white"/>
                        <polygon points="306.6 559.5 340.7 538.9 374.8 559.5 364 523.2 386 495.3 352.2 495.3 340.7 468.3 329.3 495.3 295.5 495.3 317.4 523.2 306.6 559.5"
                            fill="white"/>
                    </mask>
                </defs>
                <rect x="0" y="0" width="850" height="850"
                    mask="url('#bgmask')"
                    fill="url('#categoryNormal')"
                    />
            </svg>
            {
                this.props.label && <div
                    style={{
                        color: this.state.color
                    }}
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default BingoIcon;