import React, {
    Component
  } from 'react';

class EditorsChoice extends Component {
    constructor( props ) {
      super( props );

      let color = props.color ? props.color : "rgba(0,0,0,0.5)";
      let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

      this.state = {
        width: props.width ? props.width : 25,
        height: props.width ? props.width : 25,
        color: props.selected ? selectedColor : color
      }
    }

    componentWillReceiveProps( nextProps ) {
      if ( nextProps.selected !== this.props.selected ) {
        this.setState( {
          color: nextProps.selected ? nextProps.selectedColor : nextProps.color
        } )
      }
    }

    render() {
      return <span className={this.props.className}>
        <svg xmlns="http://www.w3.org/2000/svg"
          version="1.1" id="Layer_1" x="0px" y="0px"
          width={`${this.props.width || this.state.width}px`}
          height={`${this.props.height*1 || this.state.height*1}px`}
        viewBox="0 0 25.772 25.772"
        style={{
          "enableBackground" : "new 0 0 25.772 25.772",
          "fillRule": "evenodd",
          "clipRule": "evenodd",
          "fill": "rgb(255, 204, 0)"
        }}
        xmlSpace="preserve">
        <path xmlns="http://www.w3.org/2000/svg" style={{"fill":"rgb(255, 204, 0)"}} d="M25.646,13.74l-1.519-2.396l0.901-2.689c0.122-0.367-0.03-0.77-0.365-0.962l-2.458-1.417l-0.452-2.8   c-0.063-0.382-0.385-0.667-0.771-0.683l-2.835-0.111l-1.701-2.27c-0.232-0.31-0.652-0.413-0.999-0.246l-2.561,1.218l-2.562-1.219   C9.976,0,9.558,0.103,9.325,0.412l-1.701,2.27L4.789,2.793c-0.385,0.015-0.708,0.3-0.77,0.682l-0.452,2.8L1.109,7.692   C0.774,7.884,0.621,8.287,0.743,8.654l0.901,2.689L0.126,13.74c-0.207,0.327-0.154,0.754,0.125,1.022l2.047,1.963l-0.23,2.826   c-0.031,0.387,0.213,0.74,0.584,0.848l2.725,0.785l1.109,2.611c0.152,0.355,0.533,0.561,0.911,0.479l2.78-0.57l2.194,1.797   c0.149,0.121,0.332,0.184,0.515,0.184s0.365-0.063,0.515-0.184l2.194-1.797l2.78,0.57c0.377,0.08,0.76-0.123,0.911-0.479   l1.109-2.611l2.725-0.785c0.371-0.107,0.615-0.461,0.584-0.848l-0.23-2.826l2.047-1.963C25.8,14.494,25.853,14.067,25.646,13.74z    M18.763,9.829l-5.691,8.526c-0.215,0.318-0.548,0.531-0.879,0.531c-0.33,0-0.699-0.185-0.934-0.421L7.081,14.22   c-0.285-0.29-0.285-0.76,0-1.05l1.031-1.05c0.285-0.286,0.748-0.286,1.031,0l2.719,2.762l4.484-6.718   c0.225-0.339,0.682-0.425,1.014-0.196l1.209,0.831C18.902,9.029,18.988,9.492,18.763,9.829z"/>
        </svg>
        {
          this.props.label && <span
              style={{
                  color: this.state.color,
                  top: 1,
              }}
              className="label"
              >
              {this.props.label}
          </span>
        }
      </span>
    }
  }

  export default EditorsChoice;