import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class FullscreenIcon extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <div className={this.props.className} title={this.props.title}>
    
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
            width={`${this.state.width}px`}
            height={`${this.state.height*1}px`}
            viewBox="0 0 32 32"
            style={{
                "enableBackground" : "new 0 0 32 32"
            }}>
            <g  fill={this.state.color}>
              <polygon points="27.414 24.586 22.828 20 20 22.828 24.586 27.414 20 32 32 32 32 20"/>
              <polygon points="12 0 0 0 0 12 4.586 7.414 9.129 11.953 11.957 9.125 7.414 4.586"/>
              <polygon points="12 22.828 9.172 20 4.586 24.586 0 20 0 32 12 32 7.414 27.414"/>
              <polygon points="32 0 20 0 24.586 4.586 20.043 9.125 22.871 11.953 27.414 7.414 32 12"/>
            </g>
        </svg>
            {
                this.props.label && <div
                    style={{
                        color: this.state.color
                    }}
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default FullscreenIcon;