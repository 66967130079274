import React, {
  Component
} from 'react';

import 'component/modal/Error/styles.scss';

class ErrorModal extends Component {
  render() {
    return <div className="error modal" tabIndex="-1" role="dialog" style={{
            "display": "block"
        }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                {
                  this.props.header && <div className="col-auto">
                    { this.props.header }
                  </div>
                }
                <div className="col">
                  { this.props.message }
                </div>
              </div>
            </div>
              {
                this.props.buttons && <div className="modal-footer">
                  {
                    this.props.buttons
                  }
                </div>
              }
            </div>
        </div>
      </div>

  }
}

export default ErrorModal;