import React, { Component } from 'react';
import 'component/listItem/CategoryType2/styles.scss';
import bannerDefault from 'assets/images/bannerDefault.png';
import { TrophyIcon, RibbonIcon, EditorsChoiceIcon } from 'component/icons';

const ICON_DEFAULT = "ICON_DEFAULT";

  class CategoryType2 extends Component {
    constructor(props) {
        super(props)
        this.onMouseOverHandler = this._onMouseOverHandler.bind( this );
        this.state = {
            hovered: false,
        }
    }

    _onMouseOverHandler( bol ) {
        this.setState( {
          hovered: bol
        } )
      }

    render() {
        let {
            game,
        } = this.props
        const hasPromotionIcons = game.get('hasFreeRounds') || game.get('partOfOnGoingTournament') || game.get('isFeatured');

        return <div
            className={`${this.props.className} category-type-2`} onMouseOver={() => {
                    this.onMouseOverHandler(true)
                }}
                onMouseOut={() => {
                    this.onMouseOverHandler(false)
                }}
                onClick={(event) => {
                  this.props.onClick(this.props.linkDetails.get('rel'));
                  event.stopPropagation();
                }}
                >
            <div>
                <img src={`${game.getIn(['images', 'url'])}&width=450&theme=dark`} className="icon d-none" alt={game.get('name')}
                  onLoad={({currentTarget}) => {
                      currentTarget.classList.remove("d-none");
                      this.refs[ICON_DEFAULT].classList.add("d-none");
                  }}
                ></img>
                <img ref={ICON_DEFAULT} src={bannerDefault} className="icon" alt={game.get('name')}></img>
                <div className="details">
                    <div className="name">{game.get('name')}</div>
                    <div className="provider">{game.get('provider')}</div>
                    <div className="game-type">{game.get('gameType') ? game.get('gameType') : null}</div>
                </div>
                { hasPromotionIcons &&
                  <div className="game-feature">
                    {
                      game.get('hasFreeRounds') &&  <div className="game-feature-badge ribbonIcon">
                        <RibbonIcon
                          color="rgba(255, 204, 0, 1)"
                        />
                      </div>
                    }
                    {
                      game.get('partOfOnGoingTournament') && <div className="game-feature-badge trophyIcon">
                        <TrophyIcon
                            color="rgba(255, 204, 0, 1)"
                            />
                      </div>
                    }
                    {
                    game.get('isFeatured') && <div className="game-feature-badge editorsChoiceIcon">
                      <EditorsChoiceIcon
                      color="rgba(255, 204, 0, 1)"
                      />
                    </div>
                    }
                  </div>
                }
                <div className="game-type">{game.get('gameType') ? null : <>&nbsp;</>}</div>
            </div>

        </div>
    }
  }

  export default CategoryType2;