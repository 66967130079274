import React, { Component } from 'react';
import bannerDefault from 'assets/images/bannerDefault.png';
import 'component/listItemSkeleton/CategoryType4Skeleton/styles.scss';

class CategoryType4Skeleton extends Component {
  render() {

      /* if(!this.props.link) {

      return <div
          className={`category-type-4-skeleton ${this.props.placeholderType}`}>
          <div>
          <div className="img-holder">
            <img src={bannerDefault} className="icon" alt="preloading"></img>
          </div>
            <div className="details">
              <div className="name"><div className="dummy"></div></div>
              <div className="provider"><div className="dummy"></div></div>
              <div className="game-type"><div className="dummy"></div></div>
            </div>
          </div>
      </div>
      } */

      return <div className="category-type-4-skeleton row">
								<div className="col-5 icon">
                  <img src={bannerDefault} className="icon" alt="preloading"></img>
								</div>
                <div className="col-7 details">
                  <div className="h-100 d-flex align-items-center">
                    <div>
                      <div className="name"><div className="dummy"></div></div>
                      <div className="provider"><div className="dummy"></div></div>
                      <div className="game-type"><div className="dummy"></div></div>
                    </div>
                  </div>
                </div>
            </div>


  }
}

export default CategoryType4Skeleton;