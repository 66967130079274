import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import { fromJS } from "immutable";
import { ActionCreator } from "actions";
import "containers/ShowMore/Tournaments/styles.scss";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { CommonHeader } from "component/common";
import { JackpotTypeListItem } from "component/listItem";

const SEE_ALL_MODAL = "SEE_ALL_MODAL";
class ShowMoreJackpots extends Component {
  constructor(props) {
    super(props);
    this.seeMoreRef = createRef();
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.onSeeAllScrollHandler = this._onSeeAllScrollHandler.bind(this);
    this.getTournamentPreview = this._getTournamentPreview.bind(this);
    this.onClickPrevious = this._onClickPrevious.bind(this);
    this.onShowMoreHandler = this._onShowMoreHandler.bind(this);
    this.onShowTournamentDetailsHandler = this._onShowTournamentDetailsHandler.bind(
      this
    );
    this.onShowTournamentResultHandler = this._onShowTournamentResultHandler.bind(
      this
    );
    this.state = {
      skeleton: [1, 2, 3, 4, 5, 6],
      showCategoryLabel: false,
    };
  }

  _onShowTournamentDetailsHandler(rel, links, images, providers, winnerView) {
    let params = {
      showJackpotDetails: rel,
      routes: btoa(encodeURIComponent(JSON.stringify(links.toJSON()))),
      isWinnerView: winnerView,
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowTournamentResultHandler(rel, route, images, providers) {
    let params = {
      showTournamentResults: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowMoreHandler(
    rel,
    route,
    images,
    providers,
    status,
    subtitle,
    highestJpPoolAmount
  ) {
    let params = {
      showMore: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
      status: status,
      JpSubtitle: subtitle,
      JpAmount: highestJpPoolAmount,
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onClickPrevious() {
    this.props.goBack(
      `${this.props.location.pathname}${this.props.location.search}`
    );
    this.props.history.goBack();
  }

  _getTournamentPreview(links) {
    if (!links) return;

    let link = links.find((link) => {
      return link.get("rel").indexOf("tournament.preview") > -1;
    });
    if (link)
      this.props.fetchTournamentPreview(link, {
        parentRel: this.props.link.get("rel"),
      });
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    let params = {
      showMore: game.get("gameId"),
      sub: this.props.link.get("rel"),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onSeeAllScrollHandler(event) {
    const scrollDemo = document.querySelector(".show-more-jackpots-modal");
    if (scrollDemo.scrollTop !== 0) {
      this.setState({ showCategoryLabel: true });
    } else {
      this.setState({ showCategoryLabel: false });
    }

    if (!this.props.games) return;
    let { page } = this.props.games.toJSON();

    if (
      this.props.games &&
      this.props.games.get("totalPages") === this.props.games.get("page")
    ) {
      return;
    }

    let positionToload =
      event.target.scrollHeight - event.target.offsetHeight * 2;
    let scrollTop = event.target.scrollTop;

    if (
      scrollTop > positionToload &&
      !this.props.isLoading &&
      !this.state.isLoadingNext
    ) {
      let nextPage = page + 1;
      let params = {
        size: 25,
        page: nextPage,
      };
      this.setState({
        isLoadingNext: true,
      });
      this.props.fetchGames(this.props.link, params).then(() => {
        this.setState({
          isLoadingNext: false,
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showMoreRel !== this.props.showMoreRel) {
      if (!nextProps.showMoreRel) {
        this.props.hideModal(SEE_ALL_MODAL);
      } else {
        this.props.showModal(SEE_ALL_MODAL);
      }
    }
  }

  renderSeeAllListItem(item, key) {
    let ListItem = JackpotTypeListItem;
    let detailsLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("jackpot-details") > -1;
    });
    let images = item.get("images");
    let providers = item.get("providers");

    return (
      <ListItem
        className={`col template-${this.props.template.get("size")}`}
        key={key}
        game={item}
        isMobile={this.props.isMobile}
        fetchGameTypes={this.props.fetchGameTypes}
        onClick={(rel) => {
          let links = item.get("links");
          links.forEach((link, key) => {
            links = links.setIn([key, "name"], item.get("title"));
          });
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            links,
            images,
            providers,
            true
          );
        }}
        onShowActionClick={() => {
          //this.launchActions(item)
        }}
        onViewWinnersClick={() => {
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            true
          );
        }}
        onViewGamesClick={() => {
          let links = item.get("links");
          links.forEach((link, key) => {
            links = links.setIn([key, "name"], item.get("title"));
          });
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            links,
            images,
            providers,
            false
          );
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
      />
    );
  }

  resize2fit(elements) {
    elements.forEach((el) => {
      if (!el.parentElement) return;

      el.style.setProperty("--font-size", "1.5em");
      const {
        width: max_width,
        height: max_height,
      } = el.getBoundingClientRect();
      const { width, height } = el.children[0].getBoundingClientRect();
      el.style.setProperty(
        "--font-size",
        Math.min(max_width / width, max_height / height) + "em"
      );
    });

    window.requestAnimationFrame(() => {
      this.resize2fit(document.querySelectorAll(".prize-container"));
    });
  }

  render() {
    if (!this.props.showMoreRel) return null;

    //TODO: Move to common library
    this.resize2fit(document.querySelectorAll(".prize-container"));

    return (
      <Fragment>
        <div
          ref={this.seeMoreRef}
          className="show-more-jackpots-modal"
          onScroll={this.onSeeAllScrollHandler}
        >
          <div className="header">
            {this.props.link && (
              <CommonHeader
                link={this.props.link}
                tournamentComponent={true}
                showCategoryLabel={this.state.showCategoryLabel}
                tournamentPreview={this.props.tournamentPreview}
                detailsAvailable={this.props.detailsAvailable}
                onInfoClick={() => {
                  let params = this.props.qs;
                  params.tournamentDetails = this.props.link.get("rel");

                  let newLoc = `${
                    this.props.location.pathname
                  }?${queryString.stringify(params)}`;
                  this.props.navigateTo(newLoc);
                }}
                leftPanel={() => {
                  return (
                    <span
                      className="float-left prev-btn"
                      onClick={this.onClickPrevious}
                    >
                      <PreviousIcon width="34" />
                    </span>
                  );
                }}
                rightPanel={() => {
                  return (
                    <CloseIcon
                      width="34"
                      onClick={() => {
                        this.props.navigateTo(this.props.location.pathname);
                      }}
                    />
                  );
                }}
              />
            )}
          </div>

          <div className="content">
            {!this.props.tournamentPreview && (
              <span className={`label`}>
                {this.props.link && this.props.link.get("name")}
                {!this.props.link && <span className="dummy">XXXXXXXX</span>}
              </span>
            )}

            <div
              className="row"
              style={{
                maxHeight: `${this.props.height}px`,
              }}
            >
              {this.props.games &&
                this.props.games.getIn(["items"]).map((game, key) => {
                  return this.renderSeeAllListItem(game, key);
                })}
              {(!this.props.games ||
                this.props.isLoading ||
                this.state.isLoadingNext) && (
                <Fragment>
                  {this.state.skeleton.map((key2) => {
                    return (
                      <JackpotTypeListItem
                        key={key2}
                        className={
                          this.props.template
                            ? `template-${this.props.template.get("size")}`
                            : ""
                        }
                        currency={this.props.currency}
                      />
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let qs = queryString.parse(window.location.search);
  let { showMore, route } = qs;
  let links;
  let link;
  let games;
  let template;
  let tournamentPreview;
  let isLoading = true;
  let detailsAvailable = false;
  let currency = null;
  try {
    link = state.games.getIn([showMore, "route"]);
    games = state.games.getIn([showMore, "data"]);
    isLoading = state.games.getIn([showMore, "isLoading"]);
    tournamentPreview = state.tournaments.getIn([showMore]);
    detailsAvailable = state.games.getIn([link.get("rel"), "route", "links"])
      ? true
      : false;
  } catch (err) {}
  try {
    let qsroute = fromJS(JSON.parse(decodeURIComponent(atob(route))));
    template = qsroute.getIn(["templates"]).find((template) => {
      return template.get("id") === "myQT";
    });
  } catch (err) {}
  try {
    links = state.pages
      .getIn(["wl-pages", 0, "content"])
      .find((contentItem) => {
        return contentItem.get("rel") === "tournament.games";
      })
      .get("links")
      .find((link) => {
        return link.get("rel") === showMore;
      })
      .get("links");
  } catch (err) {}
  if (!isEmpty(state.links.get("currency"))) {
    currency = state.links.get("currency");
  }

  return {
    isMobile: state.window.get("isMobile"),
    qs,
    currency,
    detailsAvailable,
    showMoreRel: showMore,
    links,
    link,
    template,
    games,
    tournamentPreview,
    isLoading,
    isMultiGame: state.links.get("isMultiGame"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    navigateTo: (path) => ownProps.history.push(path),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchTournamentPreview: (link, params) =>
      dispatchProps.fetchTournamentPreview(link, params),
    fetchGameTypes: (route) => dispatchProps.fetchGameTypes(route),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ShowMoreJackpots);
