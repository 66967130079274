import createReducer from 'utils/createReducer';
import {
    LoginTypes,
    SecurityTypes,
    LocalStorageTypes,
    ServerSentEventTypes,
    UserTypes
} from 'actions/types';
import {
    isEmpty,
    isBoolean
} from 'lodash';

import {
    fromJS
} from 'immutable';

export const defaultValue = fromJS({
    username: null,
    password: null,
    displayName: null,
    showLanguage: false,
    //TODO: set value to null
    balance: null,
    currency: null,
    symbol: null
})

export const user = createReducer(defaultValue,{
    [LoginTypes.LOGIN](state, { payload }) {
        let newState = state.merge({
            username: payload.username,
            password: payload.password
        })
        //localStorage.setItem('user', JSON.stringify(newState.toJSON()))
        return newState
    },
    [LoginTypes.LOGOUT](state, { payload }) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('user')
        return newState
    },
    [SecurityTypes.TOKEN_VERIFIED](state, { payload }) {
        let newState = state.merge({
            displayName: payload.displayName
        })
        //localStorage.setItem('user', JSON.stringify(newState.toJSON()))
        return newState
    },
    [LocalStorageTypes.RESTORE_USER](state, { payload }) {
        let newState = state.merge(payload)
        return newState
    },
    [SecurityTypes.TOKEN_REJECTED](state, {payload}) {
        let newState = state.merge(defaultValue)
        //localStorage.removeItem('user')
        return newState
    },
    [UserTypes.SET_BALANCE](state, {payload}) {
        let newState = state.merge(payload.get('data'))
        return newState
    },
    [UserTypes.SET_PROFILE](state, {payload}) {
        let newState = state.merge({
            displayName: !isEmpty(payload.getIn(['data', 'displayName'])) ? payload.getIn(['data', 'displayName']) : null,
            showLanguage: isBoolean(payload.getIn(['data', 'showLanguage'])) ? payload.getIn(['data', 'showLanguage']) : false
        })
        return newState
    },
    [ServerSentEventTypes.MESSAGE_SERVER_SENT_EVENT](state, { payload }) {
        let newState = state;
        switch(payload.type) {
            case "player-balance-update":
                newState = newState.setIn(['balance'], payload.message.balance)
                return newState
            default:
                return newState;
        }
    }
})