import React, { Component, Fragment } from 'react';
import 'component/listItem/TournamentType/styles.scss';
import bannerDefault from 'assets/images/tournamentDefault.png';
import {
	convertMS
} from 'utils/date';
import {
	isEqual
} from 'lodash';
import Translations from 'translations';

const ICON_DEFAULT = "ICON_DEFAULT";

  class TournamentType extends Component {
    constructor(props) {
        super(props)

        this.onMouseOverHandler = this._onMouseOverHandler.bind( this );
        this.requestAnimationFrameHandler = this._requestAnimationFrameHandler.bind(this);
        this.animationFrameId = null;

        let tStartsIn = 0;
        let tEndsIn = 0;

        let games;
        let promotion;
        let leaderboards;
        let gameTypes;

        try {
          tStartsIn = (props.game.get('startsIn') * 1000) + new Date().getTime()
        } catch (err) {}
        try {
          tEndsIn = (props.game.get('endsIn') * 1000) + new Date().getTime()
        } catch (err) {}

        try {
          games = props.game.get('links').find(link => { return link.get('rel').indexOf("tournament-games") > -1 })
        } catch (err) {}
        try {
          promotion = props.game.get('links').find(link => { return link.get('rel').indexOf("tournament-promotions") > -1 })
        } catch (err) {}
        try {
          leaderboards = props.game.get('links').find(link => { return link.get('rel').indexOf("tournament-leaderboards") > -1 })
        } catch (err) {}
        try {
          gameTypes = props.game.get('links').find(link => { return link.get('rel').indexOf("game-types") > -1 })
        } catch (err) {}

        this.state = {
          hovered: false,
          startsIn: null,
          endsIn: null,
          gameTypes: null,
          tournamentPreview: {
            startsIn: tStartsIn,
            endsIn: tEndsIn
          },
          links: {
            games,
            promotion,
            leaderboards,
            gameTypes
          }
        }
    }

    componentDidMount() {

      if(this.props.game) {
        this.requestAnimationFrameHandler(this.props.game);
      }

      if(this.props.game && this.props.game.getIn(['gameTypes']) !== undefined) {
        this.setState({
          gameTypes: this.props.game.get('gameTypes')
        })
      } else {
        if(this.state.links.gameTypes && this.props.fetchGameTypes) {
          this.props.fetchGameTypes(this.state.links.gameTypes)
          .then(response => {
            this.setState({
              gameTypes: response.gameTypes ? response.gameTypes : []
            })
          })
        }
      }
    }

    componentWillUnmount() {
      if(this.props.game) {
        window.cancelAnimationFrame( this.animationFrameId );
      }
    }

    _onMouseOverHandler( bol ) {
      this.setState( {
        hovered: bol
      } )
    }

    _requestAnimationFrameHandler(game) {
      if(game) {
        let newDate = new Date().getTime()
        let startsIn = this.state.tournamentPreview.startsIn === 0 ? null : convertMS(this.state.tournamentPreview.startsIn - newDate)
        let endsIn = this.state.tournamentPreview.endsIn === 0 ? null : convertMS(this.state.tournamentPreview.endsIn - newDate);
        let startsInDHS = startsIn ? { d: startsIn.d, h: startsIn.h, m: startsIn.m } : null;
        let endsInDHS = endsIn ? {d: endsIn.d, h: endsIn.h, m: endsIn.m} : null;
        let started = (this.state.tournamentPreview.startsIn - newDate <= 0)
        let ended = (this.state.tournamentPreview.endsIn - newDate <= 0)

        if(!isEqual(startsInDHS, this.state.startsIn) || !isEqual(endsInDHS, this.state.endsIn)) {
          this.setState({
            startsIn: started ? null :startsInDHS,
            endsIn: ended ? null : endsInDHS
          })
        }


      }

      this.animationFrameId = window.requestAnimationFrame(() =>{
        this.requestAnimationFrameHandler(game)
      })
    }

    render() {
        const { game } = this.props;

        const { gameTypes } = this.state

        return <div
            className={`${this.props.className} tournament-type`} onMouseOver={() => {
                    this.onMouseOverHandler(true)
                }}
                onMouseOut={() => {
                    this.onMouseOverHandler(false)
                }}
                onClick={(event) => {
                  // this.props.onClick(this.props.linkDetails.get('rel'))
                  if(!this.props.categoryLink) {
                    this.props.onClick();
                  } else {
                    this.props.categoryLink.get('rel');
                  }
                  event.stopPropagation()
                }}
                >
            <div>
            <div>
              <div className="img-holder">
                  {
                    game && <Fragment>
                      <img src={`${game.getIn(['images', 'tournaments'])}&width=600&theme=dark`} className="icon d-none" alt={game.get('name')}
                      onLoad={({currentTarget}) => {
                          currentTarget.classList.remove("d-none");
                          this.refs[ICON_DEFAULT].classList.add("d-none");
                      }}
                      ></img>
                      <img ref={ICON_DEFAULT} src={bannerDefault} className="icon" alt={game.get('name')}></img>
                    </Fragment>
                  }
                  {
                    !game && <img ref={ICON_DEFAULT} src={bannerDefault} className="icon" alt=""></img>
                  }
                </div>
                {
                  game && <div className="details">
                    <div className="title">{game.get('title')}</div>
                    {
												this.state.startsIn && <div className="status starts-in"> {Translations.comingSoon}</div>
											}
											{
												this.state.endsIn && !this.state.startsIn && <div className="status ends-in"> {Translations.ongoing}</div>
											}
											{
												!this.state.endsIn && !this.state.startsIn && <div className="status ended"> {Translations.expired}</div>
											}
                    {
                      this.state.startsIn && <div className="secondary">
                        {`${Translations.startsIn} : ${this.state.startsIn.d}${Translations.dateD}:${this.state.startsIn.h}${Translations.dateH}:${this.state.startsIn.m}${Translations.dateM}`}
                      </div>
                    }
                    {
                      this.state.endsIn && !this.state.startsIn && <div className="secondary">
                        {`${Translations.endsIn}  : ${this.state.endsIn.d}${Translations.dateD}:${this.state.endsIn.h}${Translations.dateH}:${this.state.endsIn.m}${Translations.dateM}`}
                      </div>
                    }
                    {
                      !this.state.endsIn && !this.state.startsIn && <div className='expired'>
                        &nbsp;
                      </div>
                    }
                    {
                     gameTypes && ( gameTypes.size === 0 ) && <div>&nbsp;</div>
                    }
                    {
                     !gameTypes && this.state.links.gameTypes && <div>
                      {
                        <span className='dummy'>XXXXXXXXXXX</span>
                      }
                      </div>
                    }
                    <div className='row button-status'>
                      <div className='col nopadding'>
                        {
                          this.state.links.leaderboards && <div className='btn btn-dark'
                          onClick={event => {
                            this.props.onViewResultsClick();
                            event.stopPropagation()
                          }}
                          >{this.state.links.leaderboards.get('name')}</div>
                        }
                        {
                          this.state.links.games && <div className='btn btn-dark' onClick={event => {
                            this.props.onViewGamesClick();
                            event.stopPropagation();
                            }}
                          >{Translations.viewGames}</div>
                        }
                      </div>
                      { /* <div className='col-auto d-flex align-items-center nopadding'>
                        {
                          this.state.startsIn && <b className="starts-in">{Translations.comingSoon}</b>
                        }
                        {
                          this.state.endsIn && !this.state.startsIn && <b className="ends-in"> {Translations.ongoing}</b>
                        }
                        {
                          !this.state.endsIn && !this.state.startsIn && <b className="ended"> {Translations.ended}</b>
                        }
                      </div> */ }
                    </div>
                  </div>
                }

                {
                  !game && <div className='dummy'>
                    <div className='row'>
                      <div className='col'>
                        <span className='title'></span>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col'>
                        <span className='prize-pool'></span>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-auto'>
                        <span className='btn1'></span>
                      </div>
                      <div className='col-auto'>
                        <span className='btn2'></span>
                      </div>
                      <div className='col'></div>
                      <div className='col-auto'>
                        <span className='status'></span>
                      </div>
                    </div>
                  </div>

                }

              </div>
            </div>
        </div>
    }
  }

  export default TournamentType;