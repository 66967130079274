import React, { Component } from 'react';
import 'component/listItem/CategoryType5/styles.scss';
import iconDefault from 'assets/images/iconDefault.png';
import { PlayIcon } from 'component/icons'

const ICON_DEFAULT = "ICON_DEFAULT";

  class CategoryType5 extends Component {
    constructor(props) {
        super(props)
        this.onMouseOverHandler = this._onMouseOverHandler.bind( this );
        this.state = {
            hovered: false,
        }
    }

    _onMouseOverHandler( bol ) {
        this.setState( {
          hovered: bol
        } )
      }



    render() {
        let {
            game,
        } = this.props

        return <div
            className={`${this.props.className} category-type-5`} onMouseOver={() => {
                    this.onMouseOverHandler(true)
                }}
                onMouseOut={() => {
                    this.onMouseOverHandler(false)
                }}
                onClick={(event) => {
                  this.props.onClick(this.props.linkDetails.get('rel'));
									event.stopPropagation();
                }}
                >
            <div>
							<div className="img-holder">
								<img src={`${(game.getIn(['images', 'url']).replace("width=90", "width=200"))}&theme=dark`} className="icon d-none" alt={game.get('name')}
									onLoad={({currentTarget}) => {
											currentTarget.classList.remove("d-none");
											this.refs[ICON_DEFAULT].classList.add("d-none");
									}}
								></img>
								<img ref={ICON_DEFAULT} src={iconDefault} className="icon" alt={game.get('name')}></img>
								<div className={`actions w-100 h-100 justify-content-center align-items-center ${ this.state.hovered ? "d-flex" : "d-none"}`}>
									{
										this.props.linkReal &&
										<div
												className="play-button text-center"
												onClick={event => {
														this.props.onClick(this.props.linkReal.get('rel'))
														event.stopPropagation()
												}}
										>
												<PlayIcon />
										</div>
									}
									{
										this.props.linkDemo &&
										<button
												className="demo-button btn btn-secondary btn-xs"
												onClick={event => {
														this.props.onClick(this.props.linkDemo.get('rel'))
														event.stopPropagation()
												}}
										>
												{this.props.linkDemo.get('name')}
										</button>
									}
								</div>
							</div>
							<div className="details">
									<div className="name">{game.get('name')}</div>
									<div className="provider">{game.get('provider')}</div>
							</div>
            </div>

        </div>
    }
  }

  export default CategoryType5;