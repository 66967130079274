import createReducer from 'utils/createReducer';
import {
    ServerSentEventTypes
} from 'actions/types';
import {
    fromJS
} from 'immutable';

export const defaultValue = fromJS({
    events: fromJS([]),
    latestBroadcast: null
})

export const serverSentEvent = createReducer(defaultValue,{
    [ServerSentEventTypes.MESSAGE_SERVER_SENT_EVENT](state, { payload }) {
        let newState = state.setIn(['events'], state.getIn(['events']).push(fromJS(payload.message)))
        newState = newState.setIn(['latestBroadcast'], fromJS(payload.message))
        return newState
    },
})