import React, { Component } from "react";
import "component/game/GameItem/styles.scss";
import { isEqual } from "lodash";

class GameItem extends Component {
  constructor(props) {
    super(props);
    this.loadGame = this._loadGame.bind(this);
    this.state = {
      game: null,
      gameUrl: null,
      isLoading: true,
    };
  }

  _loadGame(game) {
    let newGame = game;

    if (newGame.get("playType") === "game.details") {
      newGame = newGame.set("playType", "launch.real");
    }

    this.props
      .fetchGame(newGame)
      .then((resp) => {
        this.setState({
          game: resp,
        });
        return resp;
      })
      .then((resp) => {
        let links = resp.payload.get("links");
        let playRoute = links.find((link) => {
          return link.get("rel") === newGame.get("playType");
        });

        if (playRoute) {
          return this.props.fetchGameUrl(playRoute).then((resp) => {
            return resp.payload.data.url;
          });
        } else {
          return;
        }
      })
      .then((resp) => {
        this.setState({
          gameUrl: resp,
          isLoading: false,
        });
      })
      .catch((err) => {
        throw err;
      });
  }

  componentDidMount() {
    if (this.props.game) {
      this.loadGame(this.props.game);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.game, this.props.game) && nextProps.game) {
      this.loadGame(nextProps.game);
    }
  }
  render() {
    return (
      <div className="game-item">
        <iframe
          title="Game"
          className="gameFrame"
          src={this.state.gameUrl}
          frameBorder="0"
          height="1200"
          scrolling="yes"
        />
      </div>
    );
  }
}

export default GameItem;
