import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fromJS } from "immutable";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import ShowMoreGames from "containers/ShowMoreDesktop/Games";
import ShowMoreCategories from "containers/ShowMoreDesktop/Categories";
import ShowMoreTournaments from "containers/ShowMoreDesktop/Tournaments";
import ShowMoreJackpots from "containers/ShowMoreDesktop/Jackpots";
// import TournamentViewDetailsDesktop from 'containers/TournamentViewDetailsDesktop';
// import TournamentResultDesktop from 'containers/TournamentResultDesktop';

const SEE_ALL_MODAL = "SEE_ALL_MODAL";
class ShowMore extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.showMoreRel !== this.props.showMoreRel) {
      if (!nextProps.showMoreRel) {
        this.props.hideModal(SEE_ALL_MODAL);
      } else {
        this.props.showModal(SEE_ALL_MODAL);
      }
    }
  }

  render() {
    const { showMoreRel } = this.props;

    if (!showMoreRel) return null;

    let categoryName;

    if (showMoreRel.includes("tournament-games")) {
      categoryName = "tournament.games";
    } else {
      categoryName = showMoreRel;
    }

    switch (categoryName) {
      case "jackpots":
      case "show.more.jackpots":
        return <ShowMoreJackpots {...this.props} />;
      case "tournaments":
      case "show.more.tournaments":
        return <ShowMoreTournaments {...this.props} />;
      case "new-providers":
      case "providers":
      case "popular.providers":
      case "show.more.providers":
        return <ShowMoreCategories {...this.props} />;
      default:
        return <ShowMoreGames {...this.props} />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  if (!state.security.get("sessionId")) {
    return {};
  }

  let qs = queryString.parse(window.location.search);
  let { showMore, route } = qs;
  return {
    showMoreRel: showMore,
    route: route
      ? fromJS(JSON.parse(decodeURIComponent(decodeURIComponent(atob(route)))))
      : undefined,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    goBack: (url) => dispatchProps.goBack(url),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ShowMore);
