import React, {
    Component
  } from 'react';

  import 'component/animation/Loading/styles.css';

  class LivaCasino extends Component {
    constructor( props ) {
      super( props );

      this.state = {
        width: props.width ? props.width : 25,
        height: props.width ? props.width : 25,
      }
    }

    componentWillReceiveProps( nextProps ) {
      if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
        this.setState( {
          width: nextProps.width,
          height: nextProps.height
        } )

    }

    render() {
      return <div className={this.props.className}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0"
                  width={`${this.state.width}px`}
                  height={`${this.state.height}px`}
                  viewBox="0 0 850 850"
                  style={{
                      "enableBackground" : "new 0 0 850 850"
                  }}>
                        <path d="M687,165.5C612.5,93.1,522.6,56.9,417.3,56.9S222,93.1,147.5,165.5C73.1,238,35.8,325.5,35.8,428S73,617.9,147.5,690.3
                            C222,762.8,311.9,799,417.3,799S612.5,762.8,687,690.3c74.5-72.4,111.7-159.9,111.7-262.3S761.5,238,687,165.5 M417.3,75
                            c100.2,0,185.7,34.4,256.6,103.3c70.9,69,106.3,152.2,106.3,249.6c0,97.5-35.4,180.7-106.3,249.5c-70.8,69-156.3,103.4-256.6,103.4
                            c-100.1,0-185.6-34.5-256.5-103.4C90,608.6,54.6,525.5,54.6,428S90,247.3,160.8,178.4S317.1,75,417.3,75 M723.4,428
                            c0-82.2-29.9-152.4-89.7-210.6c-59.7-58.1-131.9-87.1-216.4-87.1s-156.6,29-216.4,87.1c-59.7,58.2-89.6,128.4-89.6,210.6
                            s29.9,152.4,89.6,210.5c59.8,58.2,131.9,87.3,216.4,87.3s156.7-29.1,216.4-87.3C693.5,580.3,723.4,510.2,723.4,428 M417.2,253.6
                            c49.6,0,91.9,17.2,126.8,51.5c35,34.3,52.6,75.7,52.6,124.2s-17.5,89.9-52.6,124.2c-35,34.3-77.2,51.5-126.8,51.5
                            c-49.5,0-91.7-17.2-126.8-51.5c-35-34.3-52.5-75.7-52.5-124.2s17.5-89.9,52.5-124.2C325.5,270.7,367.8,253.6,417.2,253.6
                            M421.4,144.8c25.7,0.3,50.1,3.5,73.3,9.4L468.8,251c-15-4-30.8-6.2-47.4-6.5L421.4,144.8 M550.7,298.7l-0.1-0.1
                            c-11.7-11.5-24.3-21.1-37.6-29l50.4-87.4c20.9,12.1,40.6,27,59,44.8L550.7,298.7 M605.8,429.3c0-0.3,0-0.7,0-1h102.6
                            c0,26.1-3.2,50.9-9.6,74.5L600,476.3C603.9,461.3,605.8,445.7,605.8,429.3 M599.6,380.5c-4.2-15.6-10.5-30.3-18.9-44.2l88.7-51.3
                            c13.3,21.6,23.1,44.6,29.6,68.9L599.6,380.5 M347.4,152.4l25.9,96.7c-16.1,3.6-31.3,9.3-45.7,17l-50.4-87.3
                            C299.4,166.9,322.7,158.1,347.4,152.4 M287.9,294.8c-1.3,1.3-2.6,2.5-4,3.8c-10.9,10.7-20.3,22.1-27.9,34.2l-88.6-51.1
                            c12.1-19,26.8-37,44.1-53.9c1.6-1.5,3.2-3.1,4.7-4.6L287.9,294.8 M126.3,428.2c0-0.1,0-0.2,0-0.3c0-26.7,3.3-52,9.9-76l99.3,26.6
                            c-4.4,15.7-6.6,32.3-6.7,49.7L126.3,428.2 M235,478.2c4.4,16.3,11.1,31.6,20.1,46l-87.4,50.5c-13.9-21.9-24.3-45.2-31.3-70
                            L235,478.2 M286.9,562.7c12.5,11.8,25.8,21.5,40,29.2l-49.2,85.4c-21.7-11.7-42-26.5-61.1-44.3L286.9,562.7 M551.8,558.7
                            c11.9-11.8,21.8-24.4,29.8-37.9l87.5,50.6c-12.4,20.1-27.7,39-46,56.8c-0.3,0.3-0.6,0.6-0.9,0.9L551.8,558.7 M417.2,614
                            c1.4,0,2.8,0,4.2-0.1v97.2c-1.4,0-2.8,0.1-4.2,0.1c-24.3,0-47.5-2.5-69.6-7.6l25.3-94.4C387,612.4,401.8,614,417.2,614
                            M469.3,607.3c15.7-4.3,30.5-10.6,44.5-18.9l49.3,85.5c-21.5,12.3-44.4,21.6-68.5,27.8L469.3,607.3 M473.7,522.2
                            c5.4-3.1,10.4-6.5,15.1-10.3l-70.1-47.5l-67.8,49.2c11.2,8.5,24,14.8,38.5,18.9C419.2,541,447.3,537.6,473.7,522.2 M334.8,357.9
                            c-3.8,4.6-7.2,9.6-10.3,15c-15.2,26.5-18.7,54.6-10.3,84.3c4.1,14.6,10.4,27.4,18.9,38.5l49.2-67.8L334.8,357.9 M507.1,357.5
                            l-49.3,67.8l47.6,70.2c3.8-4.6,7.2-9.7,10.3-15.1c15.3-26.4,18.8-54.6,10.4-84.4C522,381.5,515.7,368.6,507.1,357.5 M366.3,331
                            c-5.4,3.1-10.4,6.5-15,10.3l70,47.6l67.9-49.3c-11.2-8.5-24-14.8-38.5-18.9C421,312.3,392.8,315.8,366.3,331 M341.8,331.5
                            c0-4.6-1.7-8.5-5-11.8c-3.2-3.2-7.2-4.9-11.8-4.9c-4.7,0-8.7,1.6-12,4.9c-3.2,3.3-4.9,7.3-4.9,12s1.6,8.6,4.9,11.9s7.3,4.9,12,4.9
                            c3.7,0,6.9-1,9.6-3.1l56.5,83.4l-57.3,78.9c-2.6-1.7-5.5-2.6-8.8-2.6c-4.7,0-8.7,1.6-12,4.9c-3.2,3.3-4.9,7.3-4.8,11.9
                            c0,4.7,1.6,8.7,4.8,12c3.3,3.2,7.3,4.9,12,4.9s8.6-1.6,11.9-4.9c3.3-3.3,4.9-7.3,4.9-12c0-3.3-0.8-6.3-2.7-9l78.7-57.3l83.6,56.6
                            c-2.1,2.8-3.1,6.1-3.1,9.7c0,4.7,1.6,8.7,4.9,12c3.2,3.2,7.2,4.9,11.9,4.9c4.7,0,8.7-1.6,12-4.9c3.2-3.3,4.9-7.3,4.9-12
                            c0-4.6-1.7-8.6-5-11.8c-3.2-3.3-7.2-5-11.8-5c-3.6,0-6.8,1.1-9.6,3.1l-56.7-83.7l57.3-78.6c2.7,1.7,5.7,2.6,9,2.6
                            c4.7,0,8.7-1.6,12-4.9c3.2-3.2,4.9-7.2,4.9-11.9c0-4.6-1.7-8.6-5-11.9c-3.2-3.2-7.2-4.9-11.8-4.9c-4.7,0-8.7,1.6-12,4.9
                            s-4.9,7.3-4.9,12c0,3.3,0.8,6.3,2.5,8.9l-78.7,57.2l-83.5-56.5C340.8,338.4,341.8,335.2,341.8,331.5 M420.1,409.9
                            c4.6,0,8.6,1.7,11.8,5c3.3,3.2,5,7.2,5,11.8c0,4.7-1.6,8.7-4.9,12c-3.2,3.3-7.2,5-11.9,4.9c-4.7,0-8.6-1.6-11.9-4.9
                            c-3.3-3.2-4.9-7.2-4.9-11.9c0-4.6,1.6-8.6,4.9-11.9C411.4,411.5,415.4,409.9,420.1,409.9z"
                            fill="url('#categoryNormal')"/>
              </svg>
              {
                  this.props.label && <div
                      className="label"
                      >
                      {this.props.label}
                  </div>
              }
          </div>
    }
  }

  export default LivaCasino;