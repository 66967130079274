import axios from 'axios';
import {
    TournamentTypes
} from 'actions/types';

export function fetchTournamentResults(route) {
	return (dispatch, getState) => {
		try {
			var headers = getState().config.get( 'headers' ).toJSON();
      var url = route.get( 'href' );
      var method = route.get( 'method' )
		} catch (error) {
			throw error
		}

		dispatch({
			type: TournamentTypes.FETCH_TOURNAMENT_RESULTS,
			payload: {
        route: route,
        method: method,
        headers: headers,
        url: url
      }
		})

		return axios({
			url: url,
			method: method,
			headers: headers
		})
		.then(response => {
			return response.data
		})
		.catch(error => {
			throw error
		})
	}
}

export function setTournamentResults(data) {
	return (dispatch, getState) => {
		dispatch({
			type: TournamentTypes.SET_TOURNAMENT_RESULTS,
			payload: {
				data: data
			}
		})
	}
}

export function fetchTournamentDetails(route) {
	return (dispatch, getState) => {
		try {
      var host = getState().config.get( 'host' );
      var headers = getState().config.get( 'headers' ).toJSON();
      var href = route.get( 'href' );
      var url = `${host}${href}`
      var method = route.get( 'method' )
    } catch ( error ) {
			throw error
		}

		dispatch({
			type: TournamentTypes.FETCH_TOURNAMENT_DETAILS,
			payload: {
        route: route,
        method: method,
        headers: headers,
        url: url
      }
		})

		return axios({
			url: url,
			method: method,
			headers: headers
		})
		.then(response => {
			//dispatch(setTournamentPreview(response.data, params));
			return response.data
		})
		.catch(error => {
			throw error
		})
	}
}

export function setTournamentDetails(data) {
	return (dispatch, getState) => {
		dispatch({
			type: TournamentTypes.SET_TOURNAMENT_DETAILS,
			payload: {
				data: data
			}
		})
	}
}

export function fetchTournamentPreview(route, params) {
	return (dispatch, getState) => {
		try {
      var host = getState().config.get( 'host' );
      var headers = getState().config.get( 'headers' ).toJSON();
      var href = route.get( 'href' );
      var url = `${host}${href}`
      var method = route.get( 'method' )
    } catch ( error ) {
      throw error
		}

		dispatch({
			type: TournamentTypes.FETCH_TOURNAMENT_PREVIEW,
			payload: {
        route: route,
        method: method,
        headers: headers,
        url: url,
				params
      }
		})
		return axios({
			url: url,
			method: method,
			headers: headers
		})
		.then(response => {
			dispatch(setTournamentPreview(response.data, params));
			return response.data
		})
		.catch(error => {
			throw error
		})
	}
}

export function setTournamentPreview(data, params) {
	return (dispatch, getState) => {
		dispatch({
			type: TournamentTypes.SET_TOURNAMENT_PREVIEW,
			payload: {
				data,
				params
			}
		})
	}
}

export function fetchTournamentUrl(route) {
	return (dispatch, getState) => {
		try {
      var host = getState().config.get( 'host' );
      var headers = getState().config.get( 'headers' ).toJSON();
      var href = route.get( 'href' );
      var url = `${host}${href}`
      var method = route.get( 'method' )
    } catch ( error ) {
      throw error
		}

		dispatch({
			type: TournamentTypes.FETCH_TOURNAMENT_URL,
			payload: {
        route: route,
        method: method,
        headers: headers,
        url: url
      }
		})

		/* return axios({
			url: `${host}/test.json`,
			method: 'GET',
		}) */
		return axios({
			url: url,
			method: method,
			headers: headers
		})
		.then(response => {
			dispatch(setTournamentUrl(response.data));
			return response.data
		})
		.catch(error => {
			throw error
		})
	}
}

export function setTournamentUrl(data) {
	return (dispatch, getState) => {
		dispatch({
			type: TournamentTypes.SET_TOURNAMENT_URL,
			payload: {
				data: data
			}
		})
	}
}