import React, { Component } from 'react';
import blankImage from 'assets/images/blankImage.jpeg';
import 'component/listItem/NoImageType/styles.scss';


class NoImageType extends Component {
  constructor(props) {
      super(props)
      this.onMouseOverHandler = this._onMouseOverHandler.bind( this );

      let randomColors = 'rgba(255,255,255,0.1),rgba(255,255,255,0.1)'
      let randomBorderColor = 'rgba(255,255,255,0.05)'

      try {
        randomColors = props.game.get('randomColors').join(",")
        randomBorderColor = props.game.get('randomColors')[props.game.get('randomColors').length-1]
      } catch (err) {}

      this.state = {
          hovered: false,
          randomColors,
          randomBorderColor
      }
  }

  _onMouseOverHandler( bol ) {
      this.setState( {
        hovered: bol
      } )
  }

  render() {
      let {
          game,
      } = this.props

      return <div
            className={`${this.props.className} no-image-type ${!game ? 'dummy' : ''} ${ this.props.isMobile ? '' : 'has-filter'}` }
              style={{
                borderColor: this.state.randomBorderColor,
                backgroundImage: `linear-gradient(${this.state.randomColors})`
              }}
              onMouseOver={() => {
                    this.onMouseOverHandler(true)
                }}
                onMouseOut={() => {
                    this.onMouseOverHandler(false)
                }}
              onClick={(event) => {
                if (this.props.onClick) {
                  this.props.onClick();
                }
                event.stopPropagation();
              }}
              >
          <img src={blankImage} alt=""/>
          <div className='name-holder-shadow'>
            <div className="row h-100 justify-content-center align-items-center">
            {
              game && <div className='col-auto text-center name'>{game.get('name')}</div>
            }
            </div>
          </div>
          <div className='name-holder'>
            <div className="row h-100 justify-content-center align-items-center">
            {
              game && <div className='col-auto text-center name'>{game.get('name')}</div>
            }
            </div>
          </div>

      </div>
  }
}

export default NoImageType;