import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { fromJS } from "immutable";
import { TrophyIcon, RibbonIcon, EditorsChoiceIcon } from "component/icons";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as PlayDemo } from "assets/icons/play-demo.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import { PlayNow } from "component/icons";
import ReactGA from "react-ga4";

import * as queryString from "query-string";

import Translations from "translations";

import "containers/GameActionCollapse/styles.scss";

const ACTION_COLLPSE_MODAL = "ACTION_COLLPSE_MODAL";

class GameActionCollapse extends Component {
  constructor(props) {
    super(props);

    this.modalRef = createRef();
    this.modalActionRef = createRef();
    this.modalCollpaseContentRef = createRef();
    this.modalCollpaseRootRef = createRef();

    this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
    this.onExpandDetails = this._onExpandDetails.bind(this);
    this.onPlayClick = this._onPlayClick.bind(this);
    this.getDetails = this._getDetails.bind(this);
    this.fetchGameUrl = this._fetchGameUrl.bind(this);
    this.handleScroll = this._handleScroll.bind(this);

    this.state = {
      isLoading: true,
      isLoadingRelated: true,
      details: null,
      relatedLinks: null,
      similarLink: null,
      launchRealLink: null,
      launchDemoLink: null,
      realUrl: null,
      demoUrl: null,
      expand: false,
      hasPromotionIcons: null,
      showCategoryLabel: false,
    };
  }

  _getDetails(gameId) {
    this.setState({
      isLoading: true,
      isLoadingRelated: true,
      details: null,
      relatedLinks: null,
      similarLink: null,
      launchRealLink: null,
      launchDemoLink: null,
      realUrl: null,
      demoUrl: null,
      hasPromotionIcons: null,
    });

    this.props
      .fetchGameDetailsUrl(gameId)
      .then((response) => {
        let link = fromJS(response);
        return this.props.fetchGameDetails(link);
      })
      .then((response) => {
        let details = fromJS(response.payload.data);
        let hasPromotionIcons =
          details.get("hasFreeRounds") ||
          details.get("partOfOnGoingTournament") ||
          details.get("isFeatured") ||
          details.get("isJackpot");
        this.setState({
          details,
          hasPromotionIcons,
        });
        return;
      })
      .then(() => {
        let realLink;

        try {
          realLink = this.state.details.get("links").find((link) => {
            return link.get("rel") === "launch.real";
          });
          return this.fetchGameUrl(realLink);
        } catch (err) {
          return;
        }
      })
      .then(() => {
        let demoLink;
        try {
          demoLink = this.state.details.get("links").find((link) => {
            return link.get("rel") === "launch.demo";
          });
          return this.fetchGameUrl(demoLink);
        } catch (err) {
          return;
        }
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
        let relatedLink;
        try {
          relatedLink = this.state.details.get("links").find((link) => {
            return link.get("rel") === "related.links";
          });
          let params = {
            nav: this.props.nav,
          };

          return this.props
            .fetchRelatedLinks(relatedLink, params)
            .then((response) => {
              this.setState({
                relatedLinks: fromJS(response),
              });
            });
        } catch (err) {
          return;
        }
      })
      .then(() => {
        this.setState({
          isLoadingRelated: false,
        });
      });
  }

  _fetchGameUrl(link) {
    let params;
    let returnUrl;
    switch (this.props.gameLaunchTarget) {
      case "_top":
      case "_parent":
      case "_self":
        let urlParams = new URLSearchParams(this.props.location.search);
        let redirectParams = new URLSearchParams();
        let url = `${this.props.location.pathname}?${urlParams.toString()}`;
        redirectParams.set("redirect", btoa(encodeURIComponent(url)));
        returnUrl = `${window.location.origin}/${
          this.props.match.params.locale
        }/${
          this.props.match.params.sessionId
        }/exit?${redirectParams.toString()}`;
        break;
      default:
        returnUrl = `${window.location.origin}/${
          this.props.match.params.locale
        }/${this.props.match.params.sessionId}/exit`;
        break;
    }
    params = {
      returnUrl,
    };

    if (this.props.appId) {
      params = {
        returnUrl: `${this.props.appId}://lobby`,
      };
    }

    return this.props.fetchGameUrl(link, params).then((response) => {
      if (link.get("rel") === "launch.real") {
        this.setState({
          launchRealLink: link,
          realUrl: response.payload.data.url,
        });
      } else if (link.get("rel") === "launch.demo") {
        this.setState({
          launchDemoLink: link,
          demoUrl: response.payload.data.url,
        });
      }
      return response;
    });
  }

  _onPlayClick(playType) {
    if (this.props.gameLaunchTarget === "_self") {
      let params = {
        gameId: this.props.gameId,
        playType: playType,
        nav: this.props.nav,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
      this.props.clearGameAction();
      return;
    }

    let params = {
      location: this.props.location,
      hideSplash: this.props.hideSplash,
      isMobile: this.props.isMobile,
      appId: this.props.appId,
      target: this.props.gameLaunchTarget,
    };

    switch (playType) {
      case "launch.demo":
        params.url = this.state.demoUrl;
        params.playType = this.state.launchDemoLink.get("rel");
        break;
      default:
        params.url = this.state.realUrl;
        params.playType = this.state.launchRealLink.get("rel");
        break;
    }
    this.props.onGameLaunch(params);
  }

  _onExpandDetails() {
    this.setState({
      expand: !this.state.expand,
    });
  }

  _onCloseClickHandler() {
    this.setState({
      expand: false,
    });
  }

  componentDidMount() {
    this.initObeserver();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.gameAction !== nextProps.gameAction) {
      if (nextProps.gameAction === null) {
        this.props.hideModal(ACTION_COLLPSE_MODAL);
        this.setState({
          expand: false,
        });
      } else {
        this.setState({
          complete: false,
        });
        this.getDetails(nextProps.gameAction.get("gameId"));
        this.props.showModal(ACTION_COLLPSE_MODAL);
      }
    }
    if (
      nextProps.gameActionDetails &&
      this.props.gameActionDetails !== nextProps.gameActionDetails
    ) {
    }
  }

  _handleScroll(event) {
    const { scrollTop } = event.currentTarget;
    if (scrollTop !== 0) {
      this.setState({ showCategoryLabel: true });
    } else {
      this.setState({ showCategoryLabel: false });
    }
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting && this.props.gameAction) {
              let newLoc = `${
                this.props.location.pathname
              }?${queryString.stringify(this.props.gameAction.toJSON())}`;
              this.props.setGameActionDetails(this.state);
              this.props.navigateTo(newLoc);
              this.props.clearGameAction();
              this.setState({
                expand: false,
              });
            }
          });
        },
        {
          //root: this.modalCollpaseRootRef.current,
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.modalCollpaseContentRef.current);
    } else {
      this.getGames(this.props.link);
      this.getTournamentPreview(this.props.links);
    }
  }

  render() {
    return (
      <div
        className={`game-action-collapse modal ${
          this.props.gameAction ? "show" : ""
        } ${this.state.expand ? "expand" : ""} ${
          this.state.complete ? "complete" : ""
        }`}
        onClick={this.props.clearGameAction}
      >
        <div
          className="modal-dialog nopadding"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="modal-content">
            <div
              className="modal-observer"
              ref={this.modalCollpaseContentRef}
            />
            <div
              className="modal-body"
              ref={this.modalActionRef}
              onScroll={this.handleScroll}
            >
              <div className="header-game-action">
                {!this.state.expand &&
                  !this.state.isLoading &&
                  !this.state.isLoadingRelated && (
                    <span className="more" onClick={this.onExpandDetails}>
                      More
                    </span>
                  )}
                {!this.state.expand &&
                  (this.state.isLoading || this.state.isLoadingRelated) && (
                    <span className="more">
                      <span className="dummy">XXXX</span>
                    </span>
                  )}
                {this.state.expand && (
                  <PreviousIcon onClick={this.props.history.goBack} />
                )}
                {!this.state.expand &&
                  !this.state.isLoading &&
                  !this.state.isLoadingRelated && (
                    <div
                      className="horizontal-bar"
                      onClick={this.onExpandDetails}
                    >
                      &nbsp;
                    </div>
                  )}
                {!this.state.expand && (
                  <span
                    className="close-btn"
                    onClick={this.props.clearGameAction}
                  >
                    Close
                  </span>
                )}
                {this.state.details && this.state.expand && (
                  <span
                    className={`details-actions ${
                      this.state.showCategoryLabel ? "" : ""
                    }`}
                    ref={this.actionLabel}
                  >
                    {this.state.details.get("name")}
                  </span>
                )}
                {this.state.expand && (
                  <CloseIcon onClick={this.onCloseClickHandler} />
                )}
              </div>
              <div className="image-spacer" />
              <div className="image-row-expand">
                {this.state.details && (
                  <img
                    className="img-fluid w-100"
                    src={`${this.state.details.getIn([
                      "images",
                      "bannerUrl",
                    ])}&theme=dark`}
                    alt={this.state.details.get("name")}
                  />
                )}
              </div>
              <div className="row">
                <div className="col">
                  <div className="info">
                    <div className="title">
                      {!this.state.details && (
                        <span className="dummy">XXXX XXXXXX</span>
                      )}

                      {this.state.details && this.state.details.get("name") && (
                        <Fragment>
                          {this.state.hasPromotionIcons && (
                            <span className="game-feature">
                              {this.state.details.get("hasFreeRounds") && (
                                <span className="game-feature-badge ribbonIcon">
                                  <RibbonIcon
                                    color="rgba(255, 204, 0, 1)"
                                    selectedColor=""
                                  />
                                </span>
                              )}
                              {this.state.details.get(
                                "partOfOnGoingTournament"
                              ) && (
                                <span className="game-feature-badge trophyIcon">
                                  <TrophyIcon
                                    color="rgba(255, 204, 0, 1)"
                                    selectedColor=""
                                  />
                                </span>
                              )}
                              {this.state.details.get("isJackpot") && (
                                <span className="game-feature-badge jackpotIcon">
                                  <CrownIcon color="rgba(255, 204, 0, 1)" />
                                </span>
                              )}
                              {this.state.details.get("isFeatured") && (
                                <span className="game-feature-badge editorsChoiceIcon">
                                  <EditorsChoiceIcon color="rgba(255, 204, 0, 1)" />
                                </span>
                              )}
                            </span>
                          )}
                          <span>{this.state.details.get("name")}</span>
                        </Fragment>
                      )}
                    </div>
                    <div className="provider">
                      {!this.state.details && (
                        <span className="dummy">XXXXXXXX</span>
                      )}
                      {this.state.details &&
                        this.state.details.get("provider") && (
                          <span>{this.state.details.get("provider")}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.state.isLoading && (
                    <div className="btn btn-secondary w-100 dummy">&nbsp;</div>
                  )}
                  {!this.state.isLoading &&
                    this.state.details &&
                    this.state.realUrl && (
                      <div
                        className="btn btn-danger w-100"
                        onClick={() => {
                          this.onPlayClick("launch.real");

                          let search = queryString.parse(
                            this.props.location.search
                          );
                          if (search.nav && search.playType) {
                            if (search.gameId) {
                              ReactGA.event({
                                category: search.nav,
                                action: "launch.real",
                                label: search.gameId,
                              });
                            }
                            if (search.gameActionId) {
                              ReactGA.event({
                                category: search.nav,
                                action: "launch.real",
                                label: search.gameActionId,
                              });
                            }
                          }
                        }}
                      >
                        <span className="play-now-icon">
                          <PlayNow />
                        </span>
                        &nbsp;
                        {Translations.playNow}
                      </div>
                    )}

                  {this.state.isLoading && (
                    <div className="btn btn-secondary w-100 dummy">&nbsp;</div>
                  )}

                  {!this.state.isLoading &&
                    this.state.details &&
                    this.state.demoUrl && (
                      <div
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.onPlayClick("launch.demo");

                          let search = queryString.parse(
                            this.props.location.search
                          );
                          if (search.nav && search.playType) {
                            if (search.gameId) {
                              ReactGA.event({
                                category: search.nav,
                                action: "launch.demo",
                                label: search.gameId,
                              });
                            }
                            if (search.gameActionId) {
                              ReactGA.event({
                                category: search.nav,
                                action: "launch.demo",
                                label: search.gameActionId,
                              });
                            }
                          }
                        }}
                      >
                        <span className="play-now-icon">
                          <PlayDemo />
                        </span>
                        &nbsp;{Translations.demoPlay}
                      </div>
                    )}
                  {!this.state.isLoading &&
                    this.state.details &&
                    !this.state.realUrl &&
                    !this.state.demoUrl && (
                      <div className="no-gameplay">
                        {Translations.noAvailableGameplay}
                      </div>
                    )}
                </div>
              </div>

              <table className={`tinfo`}>
                <tbody>
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.type}: </th>
                      <td>
                        <span className="dummy">XXXXXX XX XXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("gameType") && (
                    <tr className="trinfo">
                      <th>{Translations.type}: </th>
                      <td>{this.state.details.get("gameType")}</td>
                    </tr>
                  )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.features}: </th>
                      <td>
                        <span className="dummy">XXX XX XXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details &&
                    this.state.details.get("specialFeatures") && (
                      <tr className="trinfo">
                        <th>{Translations.features}: </th>
                        <td>
                          <span>
                            {this.state.details
                              .get("specialFeatures")
                              .join(", ")}
                          </span>
                        </td>
                      </tr>
                    )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.themes}: </th>
                      <td>
                        <span className="dummy">XXXX XXX XXXXXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("themes") && (
                    <tr className="trinfo">
                      <th>{Translations.themes}: </th>
                      <td>
                        <span>
                          {this.state.details.get("themes").join(", ")}
                        </span>
                      </td>
                    </tr>
                  )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.volatility}: </th>
                      <td>
                        <span className="dummy">XXX XX XXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("volatility") && (
                    <tr className="trinfo">
                      <th>{Translations.volatility}: </th>
                      <td>
                        <span>{this.state.details.get("volatility")}</span>
                      </td>
                    </tr>
                  )}
                  {!this.state.details && (
                    <tr className="trinfo">
                      <th>{Translations.releaseDate}: </th>
                      <td>
                        <span className="dummy">XXX XX XXXXX</span>
                      </td>
                    </tr>
                  )}
                  {this.state.details && this.state.details.get("releaseDate") && (
                    <tr className="trinfo">
                      <th>{Translations.released}: </th>
                      <td>
                        <span>{this.state.details.get("releaseDate")}</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let params;
  let gameId;
  let nav;
  let playType;
  let gameLaunchTarget;

  try {
    params = state.game.get("params");
  } catch (err) {}

  try {
    nav = params.get("nav");
  } catch (err) {}

  try {
    playType = params.get("playType");
  } catch (err) {}

  try {
    gameId = params.get("gameId");
  } catch (err) {}

  try {
    gameLaunchTarget = state.links.get("gameLaunchTarget");
  } catch (err) {}

  return {
    playType,
    gameLaunchTarget,
    nav,
    gameId,
    gameAction: state.game.get("params"),
    gameActionDetails: state.game.get("stateDetails"),
    isMobile: state.window.get("isMobile"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    fetchGameDetailsUrl: (gameId) => dispatchProps.fetchGameDetailsUrl(gameId),
    fetchGameDetails: (link, params) =>
      dispatchProps.fetchGameDetails(link, params),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    fetchRelatedLinks: (route, params) =>
      dispatchProps.fetchRelatedLinks(route, params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    navigateTo: (path) => ownProps.history.push(`${path}`),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchBalance: () => dispatchProps.fetchBalance(),
    clearGameAction: () => dispatchProps.clearGameAction(),
    setGameActionDetails: (payload) =>
      dispatchProps.setGameActionDetails(payload),
    onGameLaunch: (params) => dispatchProps.onGameLaunch(params),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(GameActionCollapse);
