import React, {
  Component
} from 'react';

import 'component/game/GameInfo2/styles.scss';
import {
  PlayNow
} from 'component/icons';
import isEmpty from 'lodash/isEmpty';
import RelatedGames from 'containers/RelatedGames';
import Translations from 'translations';
import { Info } from 'component/details';

class GameInfo2 extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      real: false,
      hasDescription: props.details && !isEmpty(props.details),
      showDescription: false,
			activeDetails: false,

    }

  }

  componentDidMount() {
    if (this.props.details) {
      let {
        details
      } = this.props,
        links = details.get( 'links' );

      let realLink = links.find( link => {
        return link.get( 'rel' ) === 'launch.real';
      } );

      let real = false;
      if ( typeof realLink === 'undefined' )
        real = false;
      else
        real = true;

      this.setState( {
        real: real
      } )
    }
  }

  componentWillReceiveProps( nextProps ) {

    if ( this.props.details !== nextProps.details ) {

      let details = nextProps.details,
        links = details.get( 'links' );

      let realLink = links.find( link => {
        return link.get( 'rel' ) === 'launch.real';
      } );

      let real = false;
      if ( typeof realLink === 'undefined' )
        real = false;
      else
        real = true;

      this.setState( {
        real: real,
        showDescription: false,
        hasDescription: !isEmpty(details)
      } )

    }

  }

  render() {
      return <div className={`${this.props.className} gameInfo2`}>
        <div className="content">
          <div className={`titleDescription ${( this.state.real === true && this.props.params.playtype === 'launch.demo' ) ? 'demo' : ''} `}>
              <Info
                  details={this.props.details}
                  />
              <RelatedGames
                details={this.props.details}
                relatedLinks={this.props.relatedLinks}
                location={this.props.location}
                match={this.props.match}
                className={this.props.gameFullscreen ? 'd-none': ''}
                navigateTo={ this.props.navigateToUrl }
                history={ this.props.history }
              />
          </div>
          {
            ( this.state.real === true && this.props.playType === 'launch.demo' ) && <div className="playForReal">
            <div className="center" onClick={()=> {this.props.onPlayClick('launch.real') } }>
                <PlayNow label={ Translations.playForReal }  className={'selected'} />
            </div>
            </div>
          }


        </div>
      </div>
  }
}

export default GameInfo2;