import React, { Component } from 'react';

import 'component/animation/Loading/styles.css';

class DesktopIcon extends Component {
    constructor(props) {
        super(props);

        let color = props.color ? props.color : "rgba(0,0,0,0.5)";
        let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

        this.state = {
            width: props.width ? props.width : 25,
            height: props.width ? props.width : 25,
            color: props.selected ? selectedColor : color
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.selected !== this.props.selected) {
            this.setState({
                color: nextProps.selected ? nextProps.selectedColor : nextProps.color
            })
        }
    }

    render() {
        return <span>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height}px`}
                viewBox="0 0 777.46 695"
                style={{
                    "enableBackground" : "new 0 0 777.46 695"
                }}
                >
                <path d="M795.74,156H63.35q-22.16.28-22.54,23.3V617.17q.38,23,22.54,23.3H372.82a88.72,88.72,0,0,1,7.29,36.17,90,90,0,0,1-85,89.59v3.5c.14,7.44,2.75,11.21,7.86,11.27H555.2c5.12,0,7.73-3.79,7.86-11.27v-3.5a90,90,0,0,1-85-89.68,88.6,88.6,0,0,1,7.29-36.18H795.74q22.15-.27,22.53-23.29V179.3h0Q817.91,156.27,795.74,156Zm-9,425.66H72.53V189.14H786.74Z"
                    transform="translate(-40.81 -86)"
                    fill={this.state.color}/>
            </svg>
        </span>
    }
}

export default DesktopIcon;

