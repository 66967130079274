import CategoryListItem from "component/listItem/Category";
import IconCategoryListItem from "component/listItem/IconCategory";
import BannerCategoryListItem from "component/listItem/BannerCategory";
import SimilarListItem from "component/listItem/Similar";
import FilterListItem from "component/listItem/Filter";
import CategoryType1ListItem from "component/listItem/CategoryType1";
import CategoryType2ListItem from "component/listItem/CategoryType2";
import CategoryType3ListItem from "component/listItem/CategoryType3";
import CategoryType4ListItem from "component/listItem/CategoryType4";
import CategoryType5ListItem from "component/listItem/CategoryType5";
import CategoryType6ListItem from "component/listItem/CategoryType6";
import CategoryType7ListItem from "component/listItem/CategoryType7";
import CategoryType8ListItem from "component/listItem/CategoryType8";
import ProviderTypeListItem from "component/listItem/ProviderType";
import NoImageTypeListItem from "component/listItem/NoImageType";
import TournamentTypeListItem from "component/listItem/TournamentType";
import JackpotTypeListItem from "component/listItem/JackpotType";
import RelatedCategoryListItem from "component/listItem//RelatedCategory";

export { IconCategoryListItem };
export { BannerCategoryListItem };
export { SimilarListItem };
export { FilterListItem };
export { CategoryListItem };
export { CategoryType1ListItem };
export { CategoryType2ListItem };
export { CategoryType3ListItem };
export { CategoryType4ListItem };
export { CategoryType5ListItem };
export { CategoryType6ListItem };
export { CategoryType7ListItem };
export { CategoryType8ListItem };
export { ProviderTypeListItem };
export { NoImageTypeListItem };
export { TournamentTypeListItem };
export { JackpotTypeListItem };
export { RelatedCategoryListItem };

export default Object.assign({
  CategoryListItem,
  IconCategoryListItem,
  BannerCategoryListItem,
  SimilarListItem,
  FilterListItem,
  CategoryType1ListItem,
  CategoryType2ListItem,
  CategoryType3ListItem,
  CategoryType4ListItem,
  CategoryType5ListItem,
  CategoryType6ListItem,
  CategoryType7ListItem,
  CategoryType8ListItem,
  ProviderTypeListItem,
  NoImageTypeListItem,
  TournamentTypeListItem,
  JackpotTypeListItem,
  RelatedCategoryListItem,
});
