import React, { Component} from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
	fromJS
} from "immutable";
import {
    ActionCreator
} from 'actions';
import * as queryString from 'query-string';
import ShowMoreGames from 'containers/ShowMore/Games';
import ShowMoreCategories from 'containers/ShowMore/Categories';
import ShowMoreTournaments from 'containers/ShowMore/Tournaments';
import ShowMoreJackpots from 'containers/ShowMore/Jackpots';

const SEE_ALL_MODAL = "SEE_ALL_MODAL";
class ShowMore extends Component {

	componentWillReceiveProps(nextProps) {
		if(nextProps.showMoreRel !== this.props.showMoreRel) {
			if(!nextProps.showMoreRel) {
				this.props.hideModal(SEE_ALL_MODAL);
			} else {
				this.props.showModal(SEE_ALL_MODAL);
			}
		}
	}

	render() {
		if (!this.props.showMoreRel)
			return null

		switch(this.props.showMoreRel) {
			case "jackpots":
				return <ShowMoreJackpots {...this.props}/>
			case "tournaments":
				return <ShowMoreTournaments {...this.props}/>;
			case "providers":
			case "popular.providers":
			case "show.more.providers":
				return <ShowMoreCategories {...this.props}/>;
			default:
				return <ShowMoreGames {...this.props}/>
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	if(!state.security.get('sessionId')) {
		return {}
	}

	let qs = queryString.parse(window.location.search)
	let { showMore, route } = qs;
	return {
		showMoreRel: showMore,
		route: route ? fromJS(JSON.parse(decodeURIComponent(atob(route)))) : undefined
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			showModal: (modal) => dispatchProps.showModal(modal),
			hideModal: (modal) => dispatchProps.hideModal(modal),
			goBack: url => dispatchProps.goBack(url)
		})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ShowMore);