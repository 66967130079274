import React, {
	Component,
} from 'react';

import {
	PlayDesktopIcon
} from 'component/icons';

class GameButtons extends Component {

  render() {
		return <div className={this.props.className}>
				{ this.props.realLink && (<div className="play-button"
					onClick={() => {this.props.onPlayClick(this.props.realLink.get('rel'))}}>
					<PlayDesktopIcon
						/>
				</div> )}
				{ this.props.demoLink && ( <span className="demo-button">
					<button className="btn btn-secondary btn-sm"
						onClick={() => {this.props.onPlayClick(this.props.demoLink.get('rel'))}}
						>{this.props.demoLink.get('name')}</button>
				</span> )}
			</div>
  }
}

export default GameButtons;