import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    LocalStorageTypes
} from '../actions/types';

export const defaultValue = fromJS({
    status: null
})

export const localStorage = createReducer(defaultValue,{
    [LocalStorageTypes.RESTORE_STATE](state, { payload }) {
        return state.merge({
            status: LocalStorageTypes.RESTORE_STATE
        });
    },
    [LocalStorageTypes.RESTORE_STATE_COMPLETE](state, { payload }) {
        return state.merge({
            status: LocalStorageTypes.RESTORE_STATE_COMPLETE
        })
    },
    [LocalStorageTypes.ERROR_RESTORE](state, { payload }) {
        return state.merge({
            status: LocalStorageTypes.ERROR_RESTORE
        })
    },
})