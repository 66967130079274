import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class DateAddedIcon extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.props.width || this.state.width}px`}
                height={`${this.props.height*1 || this.state.height*1}px`}
                viewBox="0 0 850 850"
                style={{
                    "enableBackground" : "new 0 0 850 850"
                }}>
                <g>
                <path d="M798.06,348.63q9-13.5-5.9-17.53l-252-21.48L433.91,70.29q-9.72-12.89-18.33,0l-102,237.93L57.81,330.09q-14.91,4-5.82,17.53L242.35,523.2,191.73,774.4q-.24,15.51,15.07,10.55L425,652.1,643.17,785q15.39,5,15.15-10.55L607.94,524.13Z" fill={this.state.color}></path>
                </g>
            </svg>
            {
                this.props.label && <span
                    style={{
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default DateAddedIcon;