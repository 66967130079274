import React, { Component } from 'react';
import iconDefault from 'assets/images/iconDefault.png';
import 'component/listItemSkeleton/ProviderTypeSkeleton/styles.scss';

class ProviderTypeSkeleton extends Component {
  render() {

      return <div className="provider-type-skeleton row">
								<div className="col icon">
                  <img src={iconDefault} className="icon" alt="preloading"></img>
								</div>
            </div>


  }
}

export default ProviderTypeSkeleton;