import axios from 'axios';
import {
    JackpotTypes
} from 'actions/types';

export function fetchJackpotWinners(route, params) {
	return (dispatch, getState) => {
		try {
      var host = getState().config.get( 'host' );
      var headers = getState().config.get( 'headers' ).toJSON();
      var href = route.get( 'href' );
      var url = `${host}${href}`
      var method = route.get( 'method' )
		} catch ( error ) {
			throw error
		}

		dispatch({
			type: JackpotTypes.FETCH_JACKPOT_WINNERS,
			payload: {
				route,
				method,
				headers,
				params,
				url,
			}
		})

		return axios({
			url,
			method,
			headers,
			params
		})
		.then(response => {
			//dispatch(setTournamentPreview(response.data, params));
			return response.data
		})
		.catch(error => {
			throw error
		})
	}
}

export function setJackpotWinners(data) {
	return (dispatch, getState) => {
		dispatch({
			type: JackpotTypes.SET_JACKPOT_WINNERS,
			payload: {
				data: data
			}
		})
	}
}

export function fetchJackpotDetails(route) {
	return (dispatch, getState) => {
		try {
      var host = getState().config.get( 'host' );
      var headers = getState().config.get( 'headers' ).toJSON();
      var href = route.get( 'href' );
      var url = `${host}${href}`
      var method = route.get( 'method' )
		} catch ( error ) {
			throw error
		}

		dispatch({
			type: JackpotTypes.FETCH_JACKPOT_DETAILS,
			payload: {
				route: route,
				method: method,
				headers: headers,
				url: url
			}
		})

		return axios({
			url: url,
			method: method,
			headers: headers
		})
		.then(response => {
			//dispatch(setTournamentPreview(response.data, params));
			return response.data
		})
		.catch(error => {
			throw error
		})
	}
}

export function setJackpotDetails(data) {
	return (dispatch, getState) => {
		dispatch({
			type: JackpotTypes.SET_JACKPOT_DETAILS,
			payload: {
				data: data
			}
		})
	}
}