import React, { Component } from 'react';
import bannerDefault from 'assets/images/iconDefault.png';
import 'component/listItemSkeleton/CategoryType3Skeleton/styles.scss';

class CategoryType3Skeleton extends Component {
  render() {

      return <div className="category-type-3-skeleton row">
								<div className="col-3 icon">
                  <img src={bannerDefault} className="icon" alt="preloading"></img>
								</div>
                <div className="col-9 details">
                  <div className="h-100 d-flex align-items-center">
                    <div>
                      <div className="name"><div className="dummy"></div></div>
                      <div className="provider"><div className="dummy"></div></div>
                      <div className="game-type"><div className="dummy"></div></div>
                    </div>
                  </div>
                </div>
            </div>


  }
}

export default CategoryType3Skeleton;