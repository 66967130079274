import createReducer from 'utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    CategoryTypes,
} from 'actions/types';

export const defaultValue = fromJS([])
export const recommendedRoutes = createReducer(defaultValue, {
	[CategoryTypes.SET_RECOMMENDED_LINKS](state, {payload}) {
		let newState = fromJS(payload.get('data'))
		return newState
	}
})