import React, {
  Component,
  Fragment
} from 'react';
import {
  Link
} from 'react-router-dom';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import {
  ActionCreator
} from 'actions';
import 'containers/GameNavigation/styles.scss';
import { ReactComponent as crashIcon } from 'assets/icons/instant win.svg';
import { ReactComponent as VirtualSportsIcon } from 'assets/icons/virtual sports.svg';
import { ReactComponent as TableGamesIcon } from 'assets/icons/table games.svg';
import { ReactComponent as ScratchCardsIcon } from 'assets/icons/scratch cards.svg';
import { ReactComponent as EsportsIcon } from 'assets/icons/Esports.svg';
import { ReactComponent as LiveIcon } from 'assets/icons/live.svg';
import { ReactComponent as LotteryIcon } from 'assets/icons/lottery.svg';
import { ReactComponent as ShootingIcon } from 'assets/icons/shooting games.svg';
import { ReactComponent as SlotIcon } from 'assets/icons/slots.svg';
import {
  HomeIcon,
  BingoIcon,
  VirtualGamesIcon,
  DiscoverIcon,
  InstantWinIcon,
  LiveCasinoIcon
} from 'component/icons';
import  MainNavigationSkeleton  from 'component/listItemSkeleton/MainNavigationSkeleton'

import BingoGamesBanner from 'assets/images/banner/BingoGames.jpg';
import InstantWinBanner from 'assets/images/banner/InstantWin.jpg';
import ScratchGamesBanner from 'assets/images/banner/ScratchGames.jpg'
import SlotGamesBanner from 'assets/images/banner/SlotGames.jpg';
import TableGamesBanner from 'assets/images/banner/TableGames.jpg';
import VirtualSportsBanner from 'assets/images/banner/VirtualSports.jpg';
import VirtualGamesBanner from 'assets/images/banner/VirtualGames.png';
import ShootingGamesBanner from 'assets/images/banner/ShootingGames.jpg';
import LiveCasinoBanner from 'assets/images/banner/LiveCasino.png';
import MyQTBanner from 'assets/images/banner/CityLights1920x360.FINAL.png';
import EsportsBanner from 'assets/images/banner/QTLobbyESports.jpg';
import LotteryBanner from 'assets/images/banner/QTLobbyBanners.Lottery.jpg';
import translations from 'translations';

class GameNavigation extends Component {
  constructor( props ) {
    super( props );
    this.setBannerSrc = this._setBannerSrc.bind( this );
    this.getIcon = this._getIcon.bind( this );
    this.state = {
      showMenu: false,
      iconSize: {
        width: 0,
        height: 0}
    }
  }

  _setBannerSrc( match ) {
    let bannerUrl
    switch (match.params.page) {
      case "wl-slots":
        bannerUrl = () => {
          try {
            return this.props.gameLobbyBannerUrls.getIn(['SLOT_GAMES'])
          } catch (err) {
            return SlotGamesBanner
          }
        }
        break;
        case "wl-tableGames":
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['TABLE_GAMES'])
            } catch (err) {
              return TableGamesBanner
            }
          }
          break;
        case "wl-instantWinGames":
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['INSTANT_WIN'])
            } catch (err) {
              return InstantWinBanner
            }
          }
          break;
        case "wl-virtualSportsGames":
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['VIRTUAL_SPORTS'])
            } catch (err) {
              return VirtualSportsBanner
            }
          }
          break;
        case 'wl-virtualGameGames':
          bannerUrl = () => {
            return VirtualGamesBanner
          }
          break;
        case "wl-scratchCardGames":
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['SCRATCH_CARDS'])
            } catch (err) {
              return ScratchGamesBanner
            }
          }
          break;
        case "wl-bingoGames":
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['BINGO_GAMES'])
            } catch (err) {
              return BingoGamesBanner
            }
          }
          break;
        case "wl-shootingGames":
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['SHOOTING_GAMES'])
            } catch (err) {
              return ShootingGamesBanner
            }
          }
          break;
        case "wl-liveCasinoGames":
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['LIVE_CASINO'])
            } catch (err) {
              return LiveCasinoBanner
            }
          }
          break;
        case 'wl-esportsGames':
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['ESPORTS'])
            } catch (err) {
              return EsportsBanner
            }
          }
          break;
        case 'wl-lotteryGames':
          bannerUrl = () => {
            try {
              return this.props.gameLobbyBannerUrls.getIn(['LOTTERY'])
            } catch (err) {
              return LotteryBanner
            }
          }
          break;
          case 'wl-CRASH':
            bannerUrl = () => {
              try {
                return this.props.gameLobbyBannerUrls.getIn(['CRASH'])
              } catch (err) {
                return LotteryBanner
              }
            }
            break;
            case 'wl-GAME_SHOW':
              bannerUrl = () => {
                try {
                  return this.props.gameLobbyBannerUrls.getIn(['GAME_SHOW'])
                } catch (err) {
                  return LotteryBanner
                }
              }
              break;
        default:
            bannerUrl = () => {
              try {
                return this.props.gameLobbyBannerUrls.getIn(['MY_CASINO'])
              } catch (err) {
                return MyQTBanner
              }
            }
            break;
    }
    return bannerUrl();
  }

  _getIcon( content, key ) {
    let Icon
    switch ( content.get( 'rel' ) ) {
      case 'wl-slots':
        Icon = SlotIcon;
        break;
      case 'wl-tableGames':
        Icon = TableGamesIcon;
        break;
      case 'wl-virtualSportsGames':
        Icon = VirtualSportsIcon;
        break;
      case 'wl-virtualGameGames':
        Icon = VirtualGamesIcon;
        break;
      case 'wl-instantWinGames':
        Icon = InstantWinIcon;
        break;
      case 'wl-bingoGames':
        Icon = BingoIcon;
        break;
      case 'wl-shootingGames':
        Icon = ShootingIcon;
        break;
      case 'wl-scratchCardGames':
        Icon = ScratchCardsIcon;
        break;
      case 'wl-liveCasinoGames':
        Icon = LiveIcon;
        break;
      case 'wl-esportsGames':
        Icon = EsportsIcon;
        break;
      case 'wl-CRASH':
        Icon = crashIcon;
        break;
      case 'wl-GAME_SHOW':
        Icon = LiveCasinoIcon;
        break;
      case 'wl-lotteryGames':
        Icon = LotteryIcon;
        break;
      default:
        Icon = HomeIcon;
        break;
    }
    let {
      locale,
      sessionId,
      page
    } = this.props.match.params;
    let {
      pathname
    } = this.props.location;

    return <li
          key={key}
          className={`nav-item ${pathname === '/home'} ${page === content.get('rel') ? "selected" : ""}`}>
          <Link className="nav-link text-center" to={`/${locale}/${sessionId}/games/${content.get('rel')}/${content.getIn(['links', 1, 'rel'])}`}>
            <Icon
              color="rgba(255,204,0,1)"
              selectedcolor="rgba(255,204,0,1)"
              />
              <div className="category-name">{content.get('name')}</div>

          </Link>
        </li>
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.gameLobbyBannerUrls&&
      nextProps.gameLobbyBannerUrls !== this.props.gameLobbyBannerUrls ) {
        this.setState({
          bannerUrl: this.setBannerSrc(this.props.match)
        })
      }
    if ( nextProps.match &&
      nextProps.match !== this.props.match ) {
      this.setState({
        bannerUrl: this.setBannerSrc(nextProps.match)
      })
    }
  }

  render() {
    let {
      locale,
      sessionId
    } = this.props.match.params;
    let bannerUrl = this.setBannerSrc(this.props.match);
    let isMyCasino = this.props.match.params.page === 'myQT';

return <Fragment>
      {
        this.props.getPages && this.props.showBanner ?
        <div className={`header-background-holder`}>
          <div className={`header-background ${bannerUrl !== undefined ? 'withBanner': ''}  ${(this.props.filtersVisible && !isMyCasino) ? "filter-visible" : ""}`} style={{ backgroundImage: `url(${bannerUrl})`,}}>
            &nbsp;
          </div>
        </div>
        :
        <div className="header-background no-banner">
          &nbsp;
        </div>
      }
        <div className={`maingamenavigation ${bannerUrl !== undefined ? 'withBanner': ''} ${this.props.filtersVisible && !isMyCasino ? 'gamenavigation-filter-visible': ''}`}>
          <span className={`gamenavigation ${this.props.isMobile ? "mobile" : "desktop"}`}>
              <ul className="nav justify-content-center">
                 {
                   !this.props.myQT &&
                   [...Array(10)].map((e, key) => <li className={`nav-item`} key={key}><span className={'nav-link text-center'}><MainNavigationSkeleton/></span></li>)

                 }
                  {
                      this.props.myQT && <li
                        className={`nav-item ${ isMyCasino ? "selected" : ""}`}>
                        <Link className="nav-link text-center discover" to={`/${locale}/${sessionId}/categories/${this.props.myQT.get('rel')}`}>
                          <DiscoverIcon />
                          <div className="category-name">{translations.discover}</div>
                        </Link>
                      </li>
                  }
                  {
                      this.props.contents && this.props.contents.map((content, key) => {
                          return this.getIcon(content, key)
                      })
                  }
              </ul>
          </span>
        </div>
      </Fragment>
  }
}

const mapStateToProps = ( state, ownProps ) => {
  let contents;
  let myQT;
  let gameLobbyBannerUrls;
  let getPages;
  let showBanner;

  try {
    contents = state.pages.get( 'wl-pages' ).find( page => {
      return page.get( 'rel' ) === 'webLobbyMain'
    } ).get( 'content' );
    gameLobbyBannerUrls = state.links.getIn(['gameLobbyBannerUrls']);
    getPages = state.links.getIn(['getPages']);
    showBanner = state.links.getIn(['showBanner'])
  } catch ( err ) {}

  try {
    myQT = state.pages.get( 'wl-pages' ).find( page => {
      return page.get( 'rel' ) === 'myQT'
    } );
  } catch ( err ) {}

  return {
    isMobile: state.window.get('isMobile'),
    filtersVisible: state.config.get( 'filtersVisible' ),
    contents: contents,
    myQT: myQT,
    gameLobbyBannerUrls,
    width: state.window.get( 'width' ),
    getPages,
    showBanner
  }
}

const mapDispatchToProps = ( dispatch, ownProps ) => {
  return bindActionCreators( ActionCreator, dispatch );
}

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
  return Object.assign( {}, stateProps, ownProps, {
    logout: () => dispatchProps.logout()
  } )
}

export default connect( mapStateToProps, mapDispatchToProps, mergeProps )( GameNavigation )