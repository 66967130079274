import createReducer from 'utils/createReducer';
import {
	TournamentTypes
} from 'actions/types'
import {
	fromJS
} from 'immutable'

export const defaultValue  = fromJS({})

/*
{
  type: 'SET_TOURNAMENT_PREVIEW',
  payload: {
    data: {
      prizePool: '300.00',
			currency: 'CNY',
			startsIn: 0,
			endsIn: 10
		},
		params: {
			parentRel: '41ef5ad94ae743c5a563d03a04bc4ae7'
		}
  }
}
*/

export const tournaments = createReducer(defaultValue,{
	[TournamentTypes.SET_TOURNAMENT_PREVIEW](state, {payload}) {
		let newState = state;
		let tPreview = {
			prizePool: payload.data.prizePool,
			currency:  payload.data.currency,
			startsIn: payload.data.startsIn === 0 ? 0 : (payload.data.startsIn * 1000) + new Date().getTime(),
			endsIn: payload.data.endsIn === 0 ? 0 : (payload.data.endsIn * 1000) + new Date().getTime()
		}
		newState = newState.mergeIn([payload.params.parentRel], tPreview);
		return newState
	}
})