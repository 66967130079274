import React, { Component } from 'react';
import 'component/listItemSkeleton/FilterSkeleton/styles.scss';

class FilterSkeleton extends Component {
  render() {
      return <div className={`filter-placeholder`}>
        <div className="filter">
            <div className="position"></div>
            <div className="label">Game Providers</div>
            <div className="list show-more">
                <span className="badge badge-secondary">All</span>
                <span className="badge badge-secondary">Habanero</span>
                <span className="badge badge-secondary">Evoplay</span>
                <span className="badge badge-secondary">Relax Gaming</span>
                <span className="badge badge-secondary">Triple PG</span>
                <span className="badge badge-secondary">GameArt</span>
                <span className="badge badge-secondary">Iron Dog Studio</span>
                <span className="badge badge-secondary">NetEnt</span>
                <span className="badge badge-secondary">Skywind Casino</span>
                <span className="badge badge-secondary">Wazdan</span>
                <span className="badge badge-secondary">1x2 Gaming</span>
                <span className="badge badge-secondary">Blueprint Gaming</span>
                <span className="badge badge-secondary">Evolution Gaming</span>
                <span className="badge badge-secondary">OneTouch</span>
                <span className="badge badge-secondary">Play’n GO</span>
                <span className="badge badge-secondary">Pragmatic Play Casino</span>
                <span className="badge badge-secondary">Red Tiger Gaming</span>
                <span className="badge badge-secondary">Spearhead Studios</span>
                <span className="badge badge-secondary">Spribe</span>
                <span className="badge badge-secondary">Woohoo Games</span>
                <span className="badge badge-secondary">Yggdrasil</span>
                <div className="see-more text-center">
                    <span className="badge badge-secondary">
                        SHOW MORE
                    </span>
                </div>
            </div>
        </div>
    </div>
  }
}

export default FilterSkeleton;