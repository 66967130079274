import {
    WindowTypes
} from 'actions/types';
import {
  setCookie,
  getCookie
} from 'utils/cookie';


export function toggleFullScreen(elem) {

    if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }

        return {
            type: WindowTypes.FULLSCREEN,
            payload: {
                elem : elem
            }
        }

      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }

        return {
            type: WindowTypes.EXIT_FULLSCREEN,
            payload: {
                elem : elem
            }
        }
    }
}

export function showModal(modal) {
  return {
    type: WindowTypes.SHOW_MODAL,
    payload: {
      modal: modal
    }
  }
}

export function hideModal(modal) {
  return {
    type: WindowTypes.HIDE_MODAL,
    payload: {
      modal: modal
    }
  }
}

export function setWindowSize(size) {
  return {
      type: WindowTypes.SET_WINDOW_SIZE,
      payload: size
  }
}

export function setWindowScroll(props) {
    return {
      type: WindowTypes.SET_WINDOW_SCROLL,
      payload: props
    }
}

export function setBrowser(props) {
  let cookieHideSplash = getCookie('hideSplash');
  if (!props.hideSplash) {
    props.hideSplash = cookieHideSplash;
  } else {
    setCookie('hideSplash', props.hideSplash)
  }
  return {
    type: WindowTypes.SET_BROWSER,
    payload: props
  }
}