import React, {
    Component
  } from 'react';
  
  class User extends Component {
  
      render() {
          return <div className={`${this.props.className} m-nav m-nav d-flex align-items-center`}
              onClick={() => {
                  if(this.props.onClick) {
                      this.props.onClick()
                  }
              }}>
              <span id="user-icon" className={`${this.props.active ? 'active' : 'inactive'} m-nav`}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="128.000000pt" height="128.000000pt" viewBox="0 0 128.000000 128.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M538 1270 c-240 -41 -434 -209 -511 -443 -29 -88 -29 -286 0 -374 89
                    -269 337 -453 613 -453 276 0 524 184 613 453 29 88 29 286 0 374 -101 306
                    -405 495 -715 443z m217 -95 c208 -44 370 -207 421 -421 28 -121 3 -279 -63
                    -393 l-26 -44 -22 61 c-12 34 -34 77 -48 95 -48 63 -135 86 -184 47 -42 -33
                    -129 -60 -193 -60 -64 0 -151 27 -193 60 -49 39 -136 16 -184 -47 -14 -18 -36
                    -61 -48 -95 l-22 -61 -26 44 c-126 217 -91 485 88 665 136 136 311 189 500
                    149z"/>
                    <path d="M550 1035 c-149 -46 -227 -238 -154 -383 48 -96 131 -146 244 -146
                    80 0 126 17 188 69 82 69 108 219 56 323 -61 123 -198 179 -334 137z"/>
                    </g>
                    </svg>
              </span>
          </div>
      }
  }
  
  export default User;