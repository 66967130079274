import React, {
	Component
} from 'react';

class BannerImage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loaded: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.src && nextProps.src !== this.props.src) {
			this.setState({
				loaded: false
			})
		}
	}

  render() {
		return <div className={this.props.className}>
			{
				!this.state.loaded && <img className={`default ${this.state.loaded ? ' d-none' : ''}`}
					src={this.props.defaultSrc}
					alt={this.props.alt}/>
			}
			<img className={`${this.state.loaded ? '' : ' d-none'}`}
				src={this.props.src}
				alt={this.props.alt}
				onLoad={() => {
					this.setState({
						loaded: true
					})
				}}/>
		</div>
  }
}

export default BannerImage;