import React, {
  Component
} from 'react';

class PlayHovered extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.props.width || this.state.width}px`}
                height={`${this.props.height*1 || this.state.height*1}px`}
                viewBox="0 0 800 800"
                style={{
                    "enableBackground" : "new 0 0 800 800"
                }}>
                <g>
									<circle
										cx="400" cy="400" r="357.76"
										fill="#c00"/>
									<path
										d="M577.13,373.17l-222-168.58c-26.48-19.26-71-6-71,25.33V570.33c0,29.34,51.4,44.92,71,25.33l222-168.58C596.73,412.19,596.73,388.06,577.13,373.17Z"
										fill="#fff"
										/>
                </g>
            </svg>
            {
                this.props.label && <span
                    style={{
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default PlayHovered;