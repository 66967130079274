import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import { isEqual } from "lodash";
import { fromJS } from "immutable";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { currencyFormatter } from "utils/currencyHelper";
import "containers/JackpotViewDetails/styles.scss";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import ShowMoreTournaments from "containers/ShowMoreDesktop/Tournaments";
import { CommonHeaderDesktop } from "component/common";
import { VericalList } from "component/list";
import { CategoryType8ListItem, JackpotTypeListItem } from "component/listItem";
import { CategoryType8Skeleton } from "component/listItemSkeleton";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import Translations from "translations";
import jackpotDefault from "assets/images/jackpotDefault.png";
import CategoryListDesktop from "containers/CategoryListDesktop";

const VIEW_JACKPOT_MODAL = "VIEW_JACKPOT_MODAL";

class JackpotViewDetails extends Component {
  constructor(props) {
    super(props);
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.onRowItemClickHandler = this._onRowItemClickHandler.bind(this);
    this.onClickPrevious = this._onClickPrevious.bind(this);
    this.onChangeTabHandler = this._onChangeTabHandler.bind(this);
    this.handleScroll = this._handleScroll.bind(this);
    this.handleStringCurrency = this._handleStringCurrency.bind(this);
    this.onReachEnd = this._onReachEnd.bind(this);
    this.state = {
      skeleton: [1, 2, 3, 4, 5, 6],
      games: null,
      gamesRoute: null,
      jackpots: null,
      winnersRoute: null,
      winners: null,
      tab: "winners",
      showCategoryLabel: false,
      bannerLoaded: false,
      isWinnerView: false,
      latestBroadcast: null,
      loadPrizePool: true,
    };
  }

  _handleScroll(event) {
    const { scrollTop } = event.currentTarget;
    if (scrollTop !== 0) {
      this.setState({ showCategoryLabel: true });
    } else {
      this.setState({ showCategoryLabel: false });
    }
  }

  _onClickPrevious() {
    this.props.goBack(
      `${this.props.location.pathname}${this.props.location.search}`
    );
    this.props.history.goBack();
  }

  _onChangeTabHandler(tab) {
    this.setState({
      tab,
    });
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.state.gamesRoute.get("rel"),
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    let params = queryString.parse(this.props.location.search);
    params.playType = rel;
    params.nav = this.state.gamesRoute.get("rel");

    if (!this.props.isMobile) {
      params.gameId = game.get("gameId");
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
    } else {
      params.gameId = game.get("gameId");
      this.props.setGameAction(params);
    }
  }

  _onRowItemClickHandler(item) {
    let linkDetails = item.get("links").find((link) => {
      return link.get("rel") === "game.details";
    });
    let gameId = `${linkDetails
      .get("href")
      .split("games/")
      .pop()}`;

    let params = {
      playType: linkDetails.get("rel"),
      nav: this.state.gamesRoute.get("rel"),
    };

    if (!this.props.isMobile) {
      params.gameId = gameId;
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
    } else {
      params.gameId = gameId;
      this.props.setGameAction(params);
    }
  }

  _handleStringCurrency(string, currencySymbol) {
    return string.replace(
      "[currencySymbol]",
      String.fromCharCode(parseInt(currencySymbol, 16))
    );
  }

  _onReachEnd(type) {
    switch (type) {
      case "games":
        break;
      case "winners":
        if (
          this.state.winners.get("page") ===
            this.state.winners.get("totalPages") ||
          this.state.winners.get("isLoading")
        ) {
          return;
        }

        let params = {
          size: 25,
          page: this.state.winners.get("page") + 1,
        };

        let winners = this.state.winners ? this.state.winners : fromJS({});
        winners = winners.setIn(["isLoading"], true);

        this.setState({
          winners,
        });

        this.props
          .fetchJackpotWinners(this.props.winnersRoute, params)
          .then((resp) => {
            let newWinners = fromJS(resp);
            newWinners = newWinners.setIn(["isLoading"], false);
            newWinners = newWinners.set(
              "itemsCollection",
              this.state.winners
                .get("itemsCollection")
                .push(newWinners.get("items"))
            );
            this.setState({
              winners: newWinners,
            });
            return newWinners;
          })
          .then((resp) => {
            this.props.setJackpotWinners(resp);
          });
        break;
      default:
        break;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showJackpotDetailsRel !== this.props.showJackpotDetailsRel) {
      if (!nextProps.showJackpotDetailsRel) {
        this.setState({
          games: null,
          gamesRoute: null,
          jackpots: null,
          winnersRoute: null,
          winners: null,
          isWinnerView: false,
          latestBroadcast: null,
        });
        this.props.hideModal(VIEW_JACKPOT_MODAL);
      } else {
        this.props.showModal(VIEW_JACKPOT_MODAL);
      }
    }

    if (
      !isEqual(nextProps.jackpotDetails, this.props.jackpotDetails) &&
      nextProps.jackpotDetails
    ) {
      this.setState(
        {
          loadPrizePool: false,
        },
        () => {
          this.setState({
            loadPrizePool: true,
          });
        }
      );
    } else {
      this.setState({
        loadPrizePool: false,
      });
    }

    if (
      !isEqual(nextProps.promotionRoute, this.props.promotionRoute) &&
      nextProps.promotionRoute
    ) {
      this.props
        .fetchJackpotDetails(nextProps.promotionRoute)
        .then((resp) => {
          this.props.setJackpotDetails(resp);
          return fromJS(resp);
        })
        //winners list
        .then((resp) => {
          let winnersRoute;
          try {
            winnersRoute = resp.getIn(["links"]).find((link) => {
              return link.get("rel").indexOf("jackpot-winners") > -1;
            });
            let params = {
              size: 25,
            };
            let winners = this.state.winners ? this.state.winners : fromJS({});
            winners = winners.setIn(["isLoading"], true);
            winners = winners.setIn(["itemsCollection"], fromJS([]));

            this.setState({
              winnersRoute,
              winners,
            });

            this.props
              .fetchJackpotWinners(winnersRoute, params)
              .then((resp) => {
                winners = winners.merge(fromJS(resp));
                winners = winners.setIn(["isLoading"], false);
                winners = winners.update(
                  "itemsCollection",
                  (itemsCollection) => {
                    return itemsCollection.push(winners.get("items"));
                  }
                );
                this.setState({
                  winners,
                });
                return resp;
              })
              .then((resp) => {
                this.props.setJackpotWinners(resp);
              });
          } catch (err) {}

          if (nextProps.qs && nextProps.qs.isWinnerView === "true") {
            this.setState({
              isWinnerView: true,
              tab: "winners",
            });
          } else {
            this.setState({
              isWinnerView: false,
              tab: "games",
            });
          }
          return resp;
        })
        .then((resp) => {
          let route;
          try {
            route = resp.getIn(["links"]).find((link) => {
              return link.get("rel").indexOf("jackpot-games") > -1;
            });
            let params = {
              size: 25,
            };
            let games = this.state.games ? this.state.games : fromJS({});
            games = games.setIn(["isLoading"], true);

            this.setState({
              games,
              gamesRoute: route,
            });

            this.props.fetchGames(route, params).then((resp) => {
              games = fromJS(resp);
              games = games.setIn(["isLoading"], false);
              this.setState({
                games,
              });
            });

            let jackpotsRoute;
            jackpotsRoute = resp.getIn(["links"]).find((link) => {
              return link.get("rel").indexOf("jackpots") > -1;
            });
            this.setState({
              jackpots: jackpotsRoute,
            });
          } catch (err) {}
        });
    }

    if (!isEqual(nextProps.route, this.props.route) && nextProps.route) {
      let games = this.state.games ? this.state.games : fromJS({});
      games = games.setIn([this.props.route.get("rel"), "isLoading"], true);

      this.setState({
        games,
      });

      let params = {
        size: 25,
      };

      this.props.fetchGames(this.props.route, params).then((resp) => {
        games = games.setIn([this.props.route.get("rel"), "isLoading"], false);
        games = games.mergeIn([this.props.route.get("rel")], fromJS(resp));
        this.setState({
          games,
        });
      });
    }
  }

  renderSeeAllListItem(game, key) {
    let linkDemo = game.get("links").find((link) => {
      return link.get("rel") === "launch.demo";
    });
    let linkReal = game.get("links").find((link) => {
      return link.get("rel") === "launch.real";
    });
    let linkDetails = game.get("links").find((link) => {
      return link.get("rel") === "game.details";
    });

    let ListItem = CategoryType8ListItem;

    return (
      <ListItem
        key={key}
        game={game}
        linkDemo={linkDemo}
        linkReal={linkReal}
        linkDetails={linkDetails}
        host={this.props.host}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          this.onGameItemClickHandler(game, rel);
        }}
        onShowActionClick={() => {
          this.launchActions(game);
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
      />
    );
  }

  renderDetails() {
    return (
      <Fragment>
        <div className={`jackpot-view-details-desktop-content-title`}>
          <div className="row">
            <div className="col">
              {this.props.jackpotDetails &&
                this.props.jackpotDetails.get("title") && (
                  <span>{this.props.jackpotDetails.get("title")}</span>
                )}
              {!this.props.jackpotDetails && (
                <span className="dummy">XXXXXXXXXXXXXXXX</span>
              )}
            </div>
          </div>
        </div>

        <div className="row nopadding description">
          <div className="col">
            <div className={`jackpot-view-details-desktop-content-description`}>
              <div className="subtitle pre-wrap">
                {this.props.jackpotDetails &&
                  this.props.jackpotDetails.get("subtitle") && (
                    <span>{this.props.jackpotDetails.get("subtitle")}</span>
                  )}
                {!this.props.jackpotDetails && (
                  <span className="dummy">XXXXXXXXXX</span>
                )}
              </div>

              {this.props.jackpotDetails && (
                <div className="info">
                  <div className="row">
                    <div className="col col-label text-right jp-pools">
                      <Fragment>{Translations.jackpotPools} :</Fragment>
                    </div>
                    <div className="col jp-sum">
                      {this.props.jackpotDetails.get("poolSummary")}
                    </div>
                  </div>
                  {this.props.jackpotDetails.get("jpPools") &&
                    this.props.jackpotDetails
                      .get("jpPools")
                      .map((pool, key) => {
                        return (
                          <div className="row jp-pools-row" key={key}>
                            <div className="col col-label text-right" />
                            <div className="col">
                              {pool.get("type") === "GRAND" && (
                                <span className="jp-label">
                                  {Translations.grand} :{" "}
                                </span>
                              )}
                              {pool.get("type") === "MAJOR" && (
                                <span className="jp-label">
                                  {Translations.major} :{" "}
                                </span>
                              )}
                              {pool.get("type") === "MINOR" && (
                                <span className="jp-label">
                                  {Translations.minor} :{" "}
                                </span>
                              )}
                              {pool.get("type") === "MINI" && (
                                <span className="jp-label">
                                  {Translations.mini} :{" "}
                                </span>
                              )}
                              <span className="jp-value">
                                {currencyFormatter(pool.get("amount"), {
                                  currency: this.props.currency,
                                })}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  <div className="row jp-pools-row">
                    <div className="col col-label text-right jp-pools">
                      <Fragment>{Translations.category} :</Fragment>
                    </div>
                    <div className="col jp-sum">
                      {this.props.jackpotDetails.getIn(["gameTypes"]) &&
                        this.props.jackpotDetails
                          .getIn(["gameTypes"])
                          .map(
                            (gameType, i) =>
                              `${gameType} ${
                                this.props.jackpotDetails.getIn(["gameTypes"])
                                  .size !==
                                  i + 1 &&
                                this.props.jackpotDetails.getIn(["gameTypes"])
                                  .length !==
                                  i + 1
                                  ? " ・ "
                                  : ""
                              } `
                          )}
                    </div>
                  </div>
                  <div className="row jp-pools-row">
                    <div className="col col-label text-right jp-pools">
                      <Fragment>{Translations.numberOfGames} :</Fragment>
                    </div>
                    <div className="col jp-sum">
                      {this.props.jackpotDetails.get("totalGamesCount")}
                    </div>
                  </div>
                  <div className="row jp-pools-row">
                    <div className="col col-label text-right jp-pools">
                      <Fragment>{Translations.numberOfWinners} :</Fragment>
                    </div>
                    <div className="col jp-sum">
                      {this.props.jackpotDetails.get("totalWinnersCount")}
                    </div>
                  </div>
                </div>
              )}
              {!this.props.jackpotDetails && (
                <div className="info">
                  <div className="row">
                    <div className="col col-label text-right">
                      <span className="dummy">XXXXXXX</span>
                    </div>
                    <div className="col">
                      <span className="dummy">XXXXXXXXXXXXX</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-label text-right">
                      <span className="dummy">XXXXXXX</span>
                    </div>
                    <div className="col">
                      <span className="dummy">XXXXXXXXXXXXXXXX</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-label text-right">
                      <span className="dummy">XXXXXXX</span>
                    </div>
                    <div className="col">
                      <span className="dummy">XXXXXXXXXX</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  resize2fit(elements) {
    elements.forEach((el) => {
      if (!el.parentElement) return;

      el.style.setProperty("--font-size", "1.5em");
      const {
        width: max_width,
        height: max_height,
      } = el.getBoundingClientRect();
      const { width, height } = el.children[0].getBoundingClientRect();
      el.style.setProperty(
        "--font-size",
        Math.min(max_width / width, max_height / height) + "em"
      );
    });
  }

  render() {
    const { promotionRoute, isMobile } = this.props;
    let { match } = this.props;
    if (!this.props.showJackpotDetailsRel) return null;

    this.resize2fit(document.querySelectorAll(".prize-container"));

    return (
      <Fragment>
        <div
          className={`jackpot-view-details-desktop show-more-games-desktop-modal
				${isMobile ? "mobile" : "desktop"}`}
          onScroll={this.handleScroll}
        >
          {promotionRoute && (
            <div className="header">
              <CommonHeaderDesktop
                link={promotionRoute}
                title={this.state.jackpotDetails}
                showCategoryLabel={
                  !this.props.isMobile ? this.state.showCategoryLabel : false
                }
                detailsAvailable={this.props.detailsAvailable}
                isJackpotView={true}
                leftPanel={() => {
                  return (
                    <span
                      className="float-left prev-btn"
                      onClick={() => {
                        this.setState({
                          skeleton: [1, 2, 3, 4, 5, 6],
                          games: null,
                          gamesRoute: null,
                          jackpotDetails: null,
                          winnersRoute: null,
                          winners: null,
                          latestBroadcast: null,
                          tab: "games",
                          showCategoryLabel: false,
                          bannerLoaded: false,
                        });
                        this.onClickPrevious();
                      }}
                    >
                      <PreviousIcon width="34" />
                    </span>
                  );
                }}
                rightPanel={() => {
                  return (
                    <CloseIcon
                      width="34"
                      onClick={() => {
                        this.setState({
                          skeleton: [1, 2, 3, 4, 5, 6],
                          games: null,
                          gamesRoute: null,
                          jackpotDetails: null,
                          winnersRoute: null,
                          winners: null,
                          latestBroadcast: null,
                          tab: "games",
                          showCategoryLabel: false,
                          bannerLoaded: false,
                        });
                        this.props.navigateTo(this.props.location.pathname);
                      }}
                    />
                  );
                }}
              />
            </div>
          )}
          <div className="row jackpot-view-details-desktop-row-panel">
            <div className={`col col-left-panel`}>
              <div className="left-panel-content" onScroll={this.handleScroll}>
                {!this.props.isMobile && this.renderDetails()}
                {!this.props.isMobile && this.state.jackpots && (
                  <CategoryListDesktop
                    category={this.state.jackpots.getIn(["rel"])}
                    link={this.state.jackpots}
                    match={match}
                    navigateTo={(path) => {
                      this.setState({
                        skeleton: [1, 2, 3, 4, 5, 6],
                        games: null,
                        gamesRoute: null,
                        jackpots: null,
                        jackpotDetails: null,
                        winnersRoute: null,
                        winners: null,
                        latestBroadcast: null,
                        tab: "games",
                        showCategoryLabel: false,
                        bannerLoaded: false,
                      });
                      this.props.navigateTo(path);
                    }}
                    maxCount={25}
                    listItemComponent={JackpotTypeListItem}
                    location={this.props.location}
                  />
                )}
                <ShowMoreTournaments />
              </div>
            </div>
            <div
              className={`col col-main-panel  nopadding ${
                isMobile ? "panel-mobile" : ""
              }`}
            >
              <div className="banner-holder">
                <img
                  className="banner-image"
                  alt={jackpotDefault}
                  src={jackpotDefault}
                  onLoad={() => {
                    this.setState({
                      bannerLoaded: true,
                    });
                  }}
                />
                {this.props.jackpotDetails &&
                  this.props.jackpotDetails.get("jpPools") && (
                    <Fragment>
                      <div
                        className={`amount-shadow ${
                          this.state.loadPrizePool ? "jp-update" : ""
                        }`}
                      >
                        <div className="row h-100 justify-content-center align-items-center prize-container">
                          <div className="col-auto text-center name">
                            {currencyFormatter(
                              this.props.jackpotDetails.get(
                                "highestJpPoolAmount"
                              )
                                ? this.props.jackpotDetails.get(
                                    "highestJpPoolAmount"
                                  )
                                : "",
                              { currency: this.props.currency }
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`amount ${
                          this.state.loadPrizePool ? "jp-update" : ""
                        }`}
                      >
                        <div className="row h-100 justify-content-center align-items-center prize-container">
                          <div className="col-auto text-center name">
                            {currencyFormatter(
                              this.props.jackpotDetails.get(
                                "highestJpPoolAmount"
                              )
                                ? this.props.jackpotDetails.get(
                                    "highestJpPoolAmount"
                                  )
                                : "",
                              { currency: this.props.currency }
                            )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
              </div>

              <div
                className={`jackpot-view-details-desktop-content ${
                  !isMobile ? "desktop" : ""
                }`}
              >
                {this.renderDetails()}
                {
                  <div className="jackpot-view-details-desktop-content-navigation ">
                    {!this.props.jackpotDetails && (
                      <ul className="nav mx-auto justify-content-center">
                        <li className="nav-item">
                          <span className="nav-link">
                            <span className="dummy">XXXXXX</span>&nbsp;&nbsp;
                          </span>
                        </li>
                        <li className="nav-item">
                          <span className="nav-link">
                            <span className="dummy">XXXXXXX</span>&nbsp;&nbsp;
                          </span>
                        </li>
                      </ul>
                    )}
                    {this.props.jackpotDetails && (
                      <ul className="nav mx-auto justify-content-center">
                        <li
                          className="nav-item"
                          onClick={() => this._onChangeTabHandler("winners")}
                        >
                          <span
                            className={`nav-link ${
                              this.state.tab === "winners" || !this.state.tab
                                ? "active"
                                : ""
                            } text-center`}
                          >
                            {Translations.winners}
                          </span>
                        </li>
                        {this.props.winnersRoute && (
                          <li
                            className="nav-item"
                            onClick={() => this._onChangeTabHandler("games")}
                          >
                            <span
                              className={`nav-link ${
                                this.state.tab === "games" ? "active" : ""
                              } text-center`}
                            >
                              {Translations.games}
                            </span>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                }

                <div className="jackpot-view-details-desktop-content-section">
                  {this.state.tab === "games" &&
                    this.state.games &&
                    this.state.games.getIn(["supportingText"]) !==
                      undefined && (
                      <div className={"game-count"}>
                        {this.state.games.getIn(["supportingText"])}
                      </div>
                    )}
                  {(this.state.tab === "games" ||
                    this.state.tab === undefined) && (
                    <VericalList
                      onReachEnd={(event) => {
                        this.onReachEnd("games");
                      }}
                    >
                      {this.state.games &&
                        this.state.games.getIn(["items"]) &&
                        this.state.games.getIn(["items"]).map((game, key) => {
                          return (
                            <div className={`col`} key={key}>
                              {this.renderSeeAllListItem(game, key)}
                            </div>
                          );
                        })}
                      {(!this.state.games ||
                        (this.state.games &&
                          this.state.games.getIn(["isLoading"]))) && (
                        <Fragment>
                          {this.state.skeleton.map((key2) => {
                            return (
                              <div className={`col`} key={key2}>
                                <CategoryType8Skeleton />
                              </div>
                            );
                          })}
                        </Fragment>
                      )}
                    </VericalList>
                  )}
                  {this.state.winners && this.state.tab === "winners" && (
                    <VericalList
                      onReachEnd={(event) => {
                        this.onReachEnd("winners");
                      }}
                    >
                      <div className="col-12 nopadding">
                        <table className="table">
                          <tbody>
                            <tr>
                              <th>{Translations.date}</th>
                              <th>{Translations.player}</th>
                              <th>{Translations.game}</th>
                              <th className="d-none-sm">{Translations.pool}</th>
                              <th className="text-right">
                                {Translations.payout}
                              </th>
                            </tr>
                            {this.state.winners &&
                              this.state.winners.getIn(["itemsCollection"]) &&
                              this.state.winners
                                .get("itemsCollection")
                                .map((items) => {
                                  return items.map((item, key) => {
                                    return (
                                      <tr
                                        className={`${
                                          item.get("currentPlayer")
                                            ? "current-player"
                                            : ""
                                        } game-row`}
                                        key={key}
                                        onClick={
                                          item.get("links")
                                            ? (event) => {
                                                this.onRowItemClickHandler(
                                                  item
                                                );
                                                event.stopPropagation();
                                              }
                                            : null
                                        }
                                      >
                                        <td>{item.get("dateTime")}</td>
                                        <td>{item.get("playerId")}</td>
                                        <td>{item.get("gameName")}</td>
                                        <td className="d-none-sm">
                                          {item.get("jpType")}
                                        </td>
                                        <td className="text-right prize-won">
                                          {item.getIn(["symbol"])
                                            ? String.fromCodePoint(
                                                item.getIn(["symbol", 0])
                                              )
                                            : ""}{" "}
                                          {currencyFormatter(item.get("prize"))}
                                        </td>
                                      </tr>
                                    );
                                  });
                                })}
                            {this.state.winners.get("isLoading") &&
                              fromJS([1, 2, 3, 4, 5]).map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <span className="dummy">XXXXXXXXXX</span>
                                    </td>
                                    <td className="d-none-sm">
                                      <span className="dummy">XXXXXXX</span>
                                    </td>
                                    <td>
                                      <span className="dummy">
                                        XXXXXXXXXXXXXX
                                      </span>
                                    </td>
                                    <td>
                                      <span className="dummy">XXXXXXXXXX</span>
                                    </td>
                                    <td className="text-right prize-won">
                                      <span className="dummy">XXXXXXX</span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </VericalList>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let qs = queryString.parse(window.location.search);
  let { showJackpotDetails, routes, tab, providers } = qs;

  if (!state.security.get("sessionId") || !showJackpotDetails) {
    return {};
  }

  let link;
  let games;
  let isLoading = true;
  let detailsAvailable = false;
  // let image;
  let winnersRoute;
  let promotionRoute;
  let providersArr;
  let latestBroadcast;
  let jackpotDetails;

  try {
    providersArr = JSON.parse(decodeURIComponent(atob(providers)));
  } catch (err) {}

  try {
    let imRoutes = fromJS(JSON.parse(decodeURIComponent(atob(routes))));
    promotionRoute = imRoutes.find((route) => {
      return route.get("rel") === showJackpotDetails;
    });
  } catch (err) {}

  try {
    let imRoutes = fromJS(JSON.parse(decodeURIComponent(atob(routes))));
    winnersRoute = imRoutes.find((route) => {
      return route.get("rel").indexOf("jackpot-winners") > -1;
    });
  } catch (err) {}

  try {
    let id = JSON.parse(decodeURIComponent(atob(routes)))[0]["rel"].substring(
      JSON.parse(decodeURIComponent(atob(routes)))[0]["rel"].indexOf(".") + 1,
      JSON.parse(decodeURIComponent(atob(routes)))[0]["rel"].length
    );
    jackpotDetails = state.games
      .getIn(["jackpots", "data", "items"])
      .find((item) => {
        return item.get("id") === id;
      });
  } catch (err) {}

  try {
    if (
      showJackpotDetails.indexOf(
        state.serverSentEvent.getIn(["latestBroadcast", "jackpotGroupId"])
      ) > -1
    ) {
      latestBroadcast = state.serverSentEvent.getIn(["latestBroadcast"])
        .substring;
    }
  } catch (err) {}

  try {
    games = state.games.getIn([showJackpotDetails, "data"]);
    isLoading = state.games.getIn([showJackpotDetails, "isLoading"]);
    detailsAvailable = state.games.getIn([link.get("rel"), "route", "links"])
      ? true
      : false;
  } catch (err) {}

  /* try {
		let qsimages =  fromJS(JSON.parse(images));
		image = qsimages.get("tournaments")
	} catch(err) {}; */

  return {
    gameDefaultAction: state.links.get("gameDefaultAction")
      ? state.links.get("gameDefaultAction")
      : null,
    currency: state.links.get("currency"),
    isMobile: state.window.get("isMobile"),
    qs,
    detailsAvailable,
    showJackpotDetailsRel: showJackpotDetails,
    providers: providersArr,
    promotionRoute,
    winnersRoute,
    tab,
    link,
    latestBroadcast,
    //image,
    games,
    isLoading,
    jackpotDetails,
    isMultiGame: state.links.get("isMultiGame"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    setGameAction: (params) => dispatchProps.setGameAction(params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    navigateTo: (path) => ownProps.history.push(path),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchJackpotDetails: (link) => dispatchProps.fetchJackpotDetails(link),
    setJackpotDetails: (data) => dispatchProps.setJackpotDetails(data),
    fetchJackpotWinners: (link, params) =>
      dispatchProps.fetchJackpotWinners(link, params),
    setJackpotWinners: (data) => dispatchProps.setJackpotWinners(data),
    goBack: (url) => dispatchProps.goBack(url),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(JackpotViewDetails);
