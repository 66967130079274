import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    ApplicationTypes,
    BetHistoryTypes
} from '../actions/types';

export const defaultValue = fromJS({
    showMenu: false,
    showSearch: false,
    showGameHistory: false,
    showBetHistory: false,
    version: "$INSERT_VERSION",
    buildno: "$INSERT_BUILD_NO"
})

export const application = createReducer(defaultValue,{
    [ApplicationTypes.SET_MENU_VISIBILITY](state, { payload }) {
        let newState = state.setIn(['showMenu'], payload)
        return newState
    },
    [ApplicationTypes.SET_SEARCH_VISIBILITY](state, { payload }) {
        let newState = state.setIn(['showSearch'], payload)
        return newState
    },
    [ApplicationTypes.SET_GAME_HISTORY_VISIBILITY](state, { payload }) {
        let newState = state.setIn(['showGameHistory'], payload)
        return newState
    },
    [BetHistoryTypes.SHOW_BET_HISTORY](state, { payload }) {
        let newState = state.setIn(['showBetHistory'], payload)
        return newState;
    },
    [BetHistoryTypes.HIDE_BET_HISTORY](state, {payload}) {
        let newState = state.setIn(['showBetHistory'], payload)
        return newState;
    },
    [ApplicationTypes.GET_MENU](state, {payload}) {
        let newState = state.setIn(['showMenu'], payload.get('expand'))
        return newState;
    }
})