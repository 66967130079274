import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    CategoryTypes,
    ServerSentEventTypes,
    JackpotTypes
} from '../actions/types';

//export const defaultValue = fromJS({})
export const defaultValue = fromJS({})

export const games = createReducer(defaultValue,{
    [CategoryTypes.CLEAR_GAMES](state, {payload}) {
        let newState = state.delete(payload.get('rel'));
        return newState;
    },
    [CategoryTypes.FETCH_GAMES](state, {payload}) {
        let newState = state;
        let initialPage = !payload.getIn(['params']) || payload.getIn(['params']).get('page') === "1"

        if (initialPage) {
            newState = newState.setIn([payload.getIn(['route', 'rel']), 'data'], null);
            newState = newState.setIn([payload.getIn(['route', 'rel']), 'isLoading'], true);
        } else {
            newState = newState.setIn([payload.getIn(['route', 'rel']), 'isLoading'], false);
            newState = newState.setIn([payload.getIn(['route', 'rel']), 'isLoadingNext'], true);
        }
        return newState
    },
    [CategoryTypes.SET_GAMES](state, { payload }) {
        let newState = state;
        if (payload.getIn(['data', 'page']) > 1) {
            let mergedPayload = payload.setIn(['data', 'items'], newState.getIn([payload.getIn(['route','rel']), 'data', 'items']).concat(payload.getIn(['data', 'items'])))
            newState = newState.mergeIn([payload.getIn(['route', 'rel'])], mergedPayload);
        } else {
            newState = newState.mergeIn([payload.getIn(['route', 'rel'])], payload);
        }
        newState = newState.mergeIn([payload.getIn(['route', 'rel']), 'isLoading'], false);
        newState = newState.setIn([payload.getIn(['route', 'rel']), 'isLoadingNext'], false);
        
        return newState
    },
    [CategoryTypes.SHOW_SEE_ALL_CATEGORY](state, {payload}) {
        let newState = state.setIn([payload, 'seeAll'], true);
        return newState
    },
    [CategoryTypes.HIDE_SEE_ALL_CATEGORY](state, {payload}) {
        let newState = state.setIn([payload, 'seeAll'], false);
        return newState
    },
    [ServerSentEventTypes.MESSAGE_SERVER_SENT_EVENT](state, {payload}) {
        //state.getIn(['jackpots', 'data', 'items']).toJSON()

        if(payload.type !== 'jp-pool-balance-update') return state;
        
        let newState = state;
        
        try {
            newState.forEach((games, key) => 
                {
                    let gameKey;
                    let jackpotKey;
                    let poolKey;
                    let sortedPool;
                    let newPool;
                    
                    try {
                        gameKey = key;
                        jackpotKey = games.getIn(['data', 'items']).findKey(item => { return item.get('id') === payload.message.jackpotGroupId })
                        poolKey = games.getIn(['data', 'items', jackpotKey, 'jpPools']).findKey(pool => {return pool.get('type') === payload.message.type})
                    } catch (err) {
                        return newState;
                    }
                    

                    
                    newPool = {
                        type: payload.message.type,
                        amount: payload.message.amount
                    }

                    if(gameKey !== null && jackpotKey !== null) {
                        if(poolKey > -1) {
                            newState = newState.setIn([gameKey, 'data', 'items', jackpotKey, 'jpPools', poolKey], fromJS(newPool))
                        } else {
                            newState = newState.setIn([gameKey, 'data', 'items', jackpotKey, 'jpPools'], 
                                newState.getIn([gameKey, 'data', 'items', jackpotKey, 'jpPools']).push(fromJS(newPool)))
                        }

                        sortedPool = newState.getIn([gameKey, 'data', 'items', jackpotKey, 'jpPools']).sort((a, b) => Number(b.get("amount")) - Number(a.get("amount")));
                        newState = newState.setIn([gameKey, 'data', 'items', jackpotKey , 'highestJpPoolAmount'], Number(sortedPool.getIn([0, 'amount'])))
                    }   
                }

            )
        } catch (err) {}

        return newState
    },
    [JackpotTypes.SET_JACKPOT_DETAILS](state, {payload}) {
        let newState = state;
        let jackpotKey = null

        try {
            jackpotKey = newState.getIn(['jackpots', 'data', 'items']).findKey(item => { return item.get('id') === payload.data.id })
        } catch(err) {}

        try {
            let oldLinks = newState.getIn(['jackpots', 'data', 'items', jackpotKey , 'links'])
            let newLinks = fromJS(payload.data.links);
            newLinks = newLinks.toSet().union(oldLinks.toSet()).toList();
            payload.data.links = newLinks;
        } catch (err) {}

        try {
            newState = newState.setIn(['jackpots', 'data', 'items', jackpotKey ], fromJS(payload.data));
        
            let sortedPool = newState.getIn(['jackpots', 'data', 'items', jackpotKey, 'jpPools']).sort((a, b) => Number(b.get("amount")) - Number(a.get("amount")));
            newState = newState.setIn(['jackpots', 'data', 'items', jackpotKey , 'highestJpPoolAmount'], Number(sortedPool.getIn([0, 'amount'])))
        } catch (err) {}
        
        
        return newState
    }
})