import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class HomeIcon extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.width !== this.props.width || nextProps.height !== this.props.height )
      this.setState( {
        width: nextProps.width,
        height: nextProps.height
      } )
  }

  render() {
    return <div className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.state.width}px`}
                height={`${this.state.height*1}px`}
                viewBox="0 0 750 750"
                style={{
                    "enableBackground" : "new 0 0 700 700"
                }}>
                <defs>
                    <mask id="homemask" x="0" y="0">
                        <g id="QTPlay">
                          <g id="Layer0_0_FILL" data-name="Layer0 0 FILL">
                            <path d="M74.12,476Q72.88,516.1,114,555q34.17,23.89,63.69,33.68,9.78,1.61,20.31-8.21,28.47-26.87,50.13-69.59,22.74-52.47-14.57-77-51.26-57.66-62.92-48.93Q133.3,352.69,111.77,390L74.12,476m41.13-255.32Q84,281.5,94.08,306.87q6.08,18.67,18.17,21.37,23.92.35,24.41-30.07-1.77-52-19.65-54l-1.76-23.56m358.67-52.41q-85.08,55.32-65.21,174.7,9.57,57,83,62.83,98.59-56.34,69.21-171-34.15-81.87-87-66.56M220.8,305.58l-1.31,66.26Q218.9,408.1,279.87,421q43.32-1.65,85.1-25.27,36.5-17.49,19.82-71.47-15.12-64.62-20-123.94-13.31-24.5-33.48-25.85Q256.65,164.29,220.8,305.58m49.71-197.34q-14.83,31.7,21.19,47.7,18.89,2.52,24.22-22.74,3.87-26.31-19.64-35.38-10.61-8.23.8-27.68-11.94,6.52-26.57,38.1m370,529.81q18-29.28,10-73.6A918,918,0,0,1,505.72,442.94q-17.18-16.67-34.78-13.54-45-31.47-119.76,3.43-38.07,7.85-55.44,35.48-9.42,10.47-5.73,21.46.62,22.37-9.45,45.42-19.6,53.19-63.8,98.44-25.44,20.49-19.32,44.14t50.36,41.48q113.85,36.84,271.39-6.7,88.24-25.81,121.35-74.5m-42.25-62.26q6.09,33.21-7.54,55.47-24.88,36.48-91.09,55.88-118.41,32.76-204.07,5.07-33.18-13.46-37.91-31.16-4.5-17.71,14.4-33.25,33.26-33.93,48.1-74a79.75,79.75,0,0,0,7.12-34.09q-2.74-8.19,4.32-16,13-20.94,41.66-26.69,56.24-26.31,90.08-2.6,13.31-2.48,26,10.16a704.55,704.55,0,0,0,108.89,91.27M723.06,359.67q-10.71-82.25-27.67-95.85-33.39-20.55-45.2,1.89-1.49,13-83.38,125-26.84,33.12,5.06,67.59,18.42,16.37,31,28,18,25.11,31.53,32.48a1,1,0,0,0,.43.18l2.86,1.27q32.12,11.83,73-52.08,19.07-32,12.39-108.41M636.58,113.58q12.45,21.69,8.53,26-23,10.11-21.9,31-6.69,22.8,23.63,32,26.31,2.28,29.52-29.93-5.48-39.21-39.78-59m-153-2.79q4.13-3,4.15-9.87.39-54.4-31.89-72.7l.49,6.55q5.55,37.19-9.66,56.8-4.86,4.71-1.43,10.72Q457.14,127,483.54,110.79Z" transform="translate(-33.1 -28.22)"
                              fill="white"/>
                          </g>
                        </g>
                    </mask>
                </defs>
                <rect x="0" y="0" width="750" height="750"
                    mask="url('#homemask')"
                    fill="url('#categoryNormal')"
                    />
                <polyline points="447.96 552.19 360.5 493.31 370.23 623.33 447.96 552.19"
                  fill="#b22228"
                  />

            </svg>
            {
                this.props.label && <div
                    style={{
                        color: this.state.color
                    }}
                    className="label"
                    >
                    {this.props.label}
                </div>
            }
        </div>
  }
}

export default HomeIcon;