import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class ArrowSort extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className="arrow-sort">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width={`${this.props.width || this.state.width}px`}
                height={`${this.props.height || this.state.height}px`}
                viewBox="0 0 525 525"
                style={{
                    "enableBackground" : "new 0 0 525 525"
                }}
                >
                <g>
                    <path d="M215.232,401.991h-54.818V9.136c0-2.663-0.854-4.856-2.568-6.567C156.133,0.859,153.946,0,151.279,0H96.461
                        c-2.663,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.905-2.568,6.567v392.855H32.507c-4.184,0-7.039,1.902-8.563,5.708
                        c-1.525,3.621-0.856,6.95,1.997,9.996l91.361,91.365c2.096,1.707,4.281,2.562,6.567,2.562c2.474,0,4.664-0.855,6.567-2.562
                        l91.076-91.078c1.906-2.279,2.856-4.571,2.856-6.844c0-2.676-0.854-4.859-2.568-6.584
                        C220.086,402.847,217.9,401.991,215.232,401.991z" fill={this.state.color}></path>
                </g>
            </svg>
            {
                this.props.label && <span
                    style={{
                        paddingBottom: "0.5em",
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default ArrowSort;