import createReducer from '../utils/createReducer';
import {
    fromJS
} from 'immutable';
import {
    GameTypes
} from '../actions/types';

//export const defaultValue = fromJS({})
export const defaultValue = fromJS({
    params: null,
    stateDetails: null
})

export const game = createReducer(defaultValue,{
    [GameTypes.SET_GAME_ACTION](state, {payload}) {
        let newState = state.setIn(['params'], fromJS(payload));
        return newState;
    },
    [GameTypes.SET_GAME_ACTION_DETAILS](state, {payload}) {
        let newState = state.setIn(['stateDetails'], payload)
        return newState
    },
    [GameTypes.CLEAR_GAME_ACTION](state, {payload}) {
        return defaultValue;
    },
})