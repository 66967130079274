import React, {
    Fragment,
    Component
} from 'react';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import {
  ActionCreator
} from 'actions';
import {
  fromJS
} from 'immutable';
import { includes } from 'lodash';
import CategoryList from 'containers/CategoryList';

import CategoryListDesktop from 'containers/CategoryListDesktop';

import {
  CategoryListItem,
  CategoryType1ListItem,
  CategoryType2ListItem,
  CategoryType5ListItem,
  CategoryType6ListItem,
  CategoryType7ListItem,
  ProviderTypeListItem,
  TournamentTypeListItem,
  JackpotTypeListItem
} from 'component/listItem';
import 'containers/Categories/styles.scss';

  class Categories extends Component {
    constructor(props) {
      super(props)
      this.getCategoryItem = this._getCategoryItem.bind(this);
      this.state = {
        recommendedLinks: null
      }
    }

    componentDidMount() {
      if (this.props.recommendedLinkRoutes) {
        this.props.recommendedLinkRoutes.forEach((route => {
          this.props.fetchRecommendedLinks(route)
        }))
      }
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.recommendedLinkRoutes &&
        nextProps.recommendedLinkRoutes !== this.props.recommendedLinkRoutes) {
          nextProps.recommendedLinkRoutes.forEach((route => {
            this.props.fetchRecommendedLinks(route)
          }))
        }
    }

    _getCategoryItem(template) {
      let temp =  template ? template : fromJS({size: 'm', type: 'banner'})

      //TODO: insert provider quick fix
      switch(temp.get('type')) {
        case 'jackpots':
          return JackpotTypeListItem;
        case 'tournaments':
          return TournamentTypeListItem;
        case 'providers':
          return ProviderTypeListItem;
        default:
          break;
      }

      if(this.props.isMobile) {


        switch(temp.get('size')) {
          case 'l':
            return CategoryType2ListItem;
          case 'm':
            return CategoryListItem;
          default:
            return CategoryType1ListItem;
        }
      } else {
        switch(temp.get('size')) {
          case 'l':
            return CategoryType6ListItem;
          case 'm':
            return CategoryType7ListItem;
          default:
            return CategoryType5ListItem;
        }
      }
    }

    render() {
      let {
        match
      } = this.props
      let CList = this.props.isMobile ? CategoryList : CategoryListDesktop

      return (
        <div className="categories justify-content-center h-100">
            {
              this.props.contents && this.props.contents.map((content, key) => {
                let links
                let isTournament;

                try {
                  links = content.get('links');
                  isTournament = content.get('rel') === "tournament.games"
                } catch (err) {}
                return <div key={key}>
                  {
                    links && links.map((link, key) => {
                      return <Fragment key={key}>
                          <CList
                            category={content.get('rel')}
                            link={link}
                            match={match}
                            navigateTo={this.props.navigateTo}
                            maxCount={isTournament ? null : 50}
                            isTournament={isTournament}
                            listItemComponent={this.getCategoryItem(link.get('template'))}
                            location={this.props.location}
                            />
                            {
                            }
                          {
                              link.get('rel') === 'categories-home.recentlyplayed' && this.props.firstRecommendedRoutes &&
                              <CList
                                category={content.get('rel')}
                                link={this.props.firstRecommendedRoutes}
                                match={match}
                                navigateTo={this.props.navigateTo}
                                maxCount={isTournament ? null : 50}
                                isTournament={isTournament}
                                listItemComponent={this.getCategoryItem(this.props.firstRecommendedRoutes.get('template'))}
                                location={this.props.location}
                                />
                          }
                        </Fragment>
                    })
                  }
                </div>
              })
            }
            {
              this.props.recommendedRoutes && this.props.recommendedRoutes.map((link, key) => {
                if(key === 0 && link.get('rel').indexOf('recommendation') > -1) {
                  return null
                }
               const recommendedCategory =  includes(link.get('rel'), 'popular.recommendation-game.played') ? undefined : link.get('template');
                return <CList
                  category={this.props.recommendedRoutes.getIn([0, 'rel'])}
                  recommendedCategory={recommendedCategory}
                  key={key}
                  link={link}
                  match={match}
                  navigateTo={this.props.navigateTo}
                  listItemComponent={this.getCategoryItem(link.get('template'))}
                  location={this.props.location}/>
              })
            }
        </div>
      );
    }
  }

  const mapStateToProps = ( state, ownProps ) => {
    let contents;
    let recommendedLinkRoutes;
    let firstRecommendedRoutes;
    try {
      contents = state.pages.get('wl-pages').find(page => {return page.get('rel') === ownProps.match.params.page}).get('content');
      recommendedLinkRoutes = contents.filter(content => { return content.get('rel') === "popular.recommendations" }).getIn([0, 'links']);
      contents = contents.filter(content => { return content.get('rel') !== "popular.recommendations" });
      let firstRecommendedPopularRoutes = state.recommendedRoutes.getIn([0])
      firstRecommendedRoutes = firstRecommendedPopularRoutes.get('rel').indexOf("popular.recommendation-game.played.recommendation") > -1 ? firstRecommendedPopularRoutes : null;
    } catch (err) {}

    return {
      isMobile: state.window.get('isMobile'),
      contents: contents,
      recommendedLinkRoutes: recommendedLinkRoutes,
      recommendedRoutes: state.recommendedRoutes,
      firstRecommendedRoutes: firstRecommendedRoutes
    }
  }

  const mapDispatchToProps = ( dispatch, ownProps ) => {
    return bindActionCreators( ActionCreator, dispatch );
  }

  const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
    return Object.assign( {}, stateProps, ownProps, {
        navigateTo: ( path ) => ownProps.history.push( path ),
        fetchRecommendedLinks: ( route ) => dispatchProps.fetchRecommendedLinks( route),
    })
  }

  export default connect( mapStateToProps, mapDispatchToProps, mergeProps )( Categories )