import React, {
  Component
} from 'react';

import 'component/animation/Loading/styles.css';

class SliderIcon extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.props.width || this.state.width}px`}
                height={`${this.props.height*1 || this.state.height*1 }px`}
                viewBox="0 0 123 123"
                style={{
                    "enableBackground" : "new 0 0 123 123"
                }}>
                <path className="st0" d="M114,18.7v9.1c0,1.1-1,2-2.3,2H75.5c-2.5,5.1-7.7,8.7-13.8,8.7c-6.1,0-11.3-3.5-13.8-8.7H10.3
		c-1.3,0-2.3-0.9-2.3-2v-9.1c0-1.1,1-2,2.3-2h37.6C50.4,11.5,55.7,8,61.7,8s11.3,3.5,13.8,8.7h36.2C113,16.7,114,17.6,114,18.7
		L114,18.7L114,18.7z M51,105.9c-2.5,5.1-7.7,8.7-13.8,8.7c-6.1,0-11.3-3.5-13.8-8.7H10.3c-1.3,0-2.3-0.9-2.3-2v-9.1
		c0-1.1,1-2,2.3-2h13.1c2.5-5.1,7.7-8.7,13.8-8.7s11.3,3.5,13.8,8.7h60.7c1.3,0,2.3,0.9,2.3,2v9.1c0,1.1-1.1,2-2.3,2H51L51,105.9z
		 M98.3,68.5c-2.5,5.1-7.7,8.7-13.8,8.7c-6.1,0-11.3-3.5-13.8-8.7H10.3c-1.3,0-2.3-0.9-2.3-2v-9.1c0-1.1,1-2,2.3-2h60.4
		c2.5-5.1,7.7-8.7,13.8-8.7c6.1,0,11.3,3.5,13.8,8.7h13.4c1.3,0,2.3,0.9,2.3,2v9.1c0,1.1-1,2-2.3,2L98.3,68.5L98.3,68.5z"
                fill={this.state.color}/>
            </svg>
            {
                this.props.label && <span
                    style={{
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default SliderIcon;