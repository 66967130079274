import React, {
  Component
} from 'react';

class Clock extends Component {
  constructor( props ) {
    super( props );

    let color = props.color ? props.color : "rgba(0,0,0,0.5)";
    let selectedColor = props.selectedColor ? props.selectedColor : "rgba(0,0,0,0)";

    this.state = {
      width: props.width ? props.width : 25,
      height: props.width ? props.width : 25,
      color: props.selected ? selectedColor : color
    }
  }

  componentWillReceiveProps( nextProps ) {
    if ( nextProps.selected !== this.props.selected ) {
      this.setState( {
        color: nextProps.selected ? nextProps.selectedColor : nextProps.color
      } )
    }
  }

  render() {
    return <span className={this.props.className}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={`${this.props.width || this.state.width}px`}
                height={`${this.props.height*1 || this.state.height*1}px`}
                viewBox="0 0 100 100"
                style={{
                    "enableBackground" : "new 0 0 100 100"
                }}>
                <g>
                <path d="M48.58,0C21.793,0,0,21.793,0,48.58s21.793,48.58,48.58,48.58s48.58-21.793,48.58-48.58S75.367,0,48.58,0z M48.58,86.823
                  c-21.087,0-38.244-17.155-38.244-38.243S27.493,10.337,48.58,10.337S86.824,27.492,86.824,48.58S69.667,86.823,48.58,86.823z" fill={this.state.color}></path>
                <path d="M73.898,47.08H52.066V20.83c0-2.209-1.791-4-4-4c-2.209,0-4,1.791-4,4v30.25c0,2.209,1.791,4,4,4h25.832
                  c2.209,0,4-1.791,4-4S76.107,47.08,73.898,47.08z" fill={this.state.color}></path>
                </g>
            </svg>
            {
                this.props.label && <span
                    style={{
                        color: this.state.color,
                        top: 1,
                        position: "relative"
                    }}
                    className="label"
                    >
                    {this.props.label}
                </span>
            }
        </span>
  }
}

export default Clock;